import axios from 'axios';
const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

// add  dependencyId, later
//Dependencies: [dependencyId], 
export async function postTasks(taskName, projectId, deadline, assignee, deliverableId, hours, prev, startDate, eindDate, typeDeadline, prio,type) {
  const url = `https://api.airtable.com/v0/appairPVZTnXeJaJG/Tasks/`;
  
  const taskData = {
    fields: {
      NaamTaak: taskName,
      Project: [projectId],
      Deadline: deadline,
      Assignee: assignee,
      Deliverable: [deliverableId],
      UrenWerk: hours,
      Dependencies: prev,
      StartDateUitvoerder: startDate,
      EndDateUitvoerder: eindDate,
      TypeDeadline: typeDeadline,
      PrioNr: prio,
      Type:type

    },
  };
  try {
    const updateResponse = await axios.post(url, taskData, {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(updateResponse)
    return updateResponse.data;
  } catch (error) {
    console.error('Error updating StandardTasks:', error);
    throw error;
  }
}
