import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Importeer het CloseIcon
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const NietBeschikbaarDialog = ({ open, onClose, reservations }) => {
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleEventSelect = (event) => {
        setSelectedEvent(event);
    };

    const handleCloseDetails = () => {
        setSelectedEvent(null);
    };

    const events = (reservations || []).map(reservation => ({
        title: reservation.draaidagNaam,
        start: reservation.startDatum,
        end: reservation.eindDatum,
        allDay: true,
    }));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth align="center">
            <DialogTitle>
                Dit materiaal is al gereserveerd voor de volgende draaidagperiodes:
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {events.length > 0 ? (
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 400 }}
                        onSelectEvent={handleEventSelect}
                    />
                ) : (
                    <Typography variant="body1">No reservations found for this material.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Sluiten</Button>
            </DialogActions>

            {selectedEvent && (
                <Dialog open={Boolean(selectedEvent)} onClose={handleCloseDetails}>
                    <DialogTitle>Reserveringsdetails</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`Naam: ${selectedEvent.title}`}</DialogContentText>
                        <DialogContentText>{`Start: ${selectedEvent.start.toLocaleDateString()}`}</DialogContentText>
                        <DialogContentText>{`Einde: ${selectedEvent.end.toLocaleDateString()}`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDetails}>Sluiten</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Dialog>
    );
};

export default NietBeschikbaarDialog;
