import axios from 'axios';


const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
//console.log(API_KEY);
const HEADERS = {
  Authorization: `Bearer ${API_KEY}`,
  'Content-Type': 'application/json'
};
export async function getStandardTasks(label) {

  const url = `https://api.airtable.com/v0/apphMT5j6gtUnMY1p/StandardTasks?filterByFormula={Label}='${label}'&sort%5B0%5D%5Bfield%5D=Sort&sort%5B0%5D%5Bdirection%5D=asc`

  const responseProjecten = await axios.get(url, { headers: HEADERS });
  console.log('Response from API:', responseProjecten.data);
  return responseProjecten;
}