import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Chip } from '@mui/material';

const MaterialDetailsDialog = ({ openMaterialDialog, handleCloseMaterialsDetails, selectedMaterial }) => {
    return (
        <Dialog open={openMaterialDialog} onClose={handleCloseMaterialsDetails}>
            {selectedMaterial && (
                <>
                    <DialogTitle>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span>{selectedMaterial.fields.ID}</span>
                            {selectedMaterial.fields.Status && (
                                <Chip
                                    label={selectedMaterial.fields.Status}
                                    //color="primary"
                                    size="small"
                                    color="error" // Rode kleur
                                />
                            )}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">{selectedMaterial.fields.Categorie}</Typography>
                        <Typography variant="body1">Omschrijving: {selectedMaterial.fields.Omschrijving}</Typography>
                        {selectedMaterial.fields.Afbeelding && (
                            <img src={selectedMaterial.fields.Afbeelding[0].url} alt="Material" style={{ maxWidth: '100%', marginTop: '10px' }} />
                        )}
                    </DialogContent>
                </>
            )}
            <DialogActions>
                <Button onClick={handleCloseMaterialsDetails} color="primary">Sluiten</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MaterialDetailsDialog;
