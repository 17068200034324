import Calendar from "./Calendar";
import { Paper,Typography } from "@mui/material";
import "../../../styles/UrenVerantwoording/App.css"
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * Wrapper over the calendar, handels it showing or not
 * @param {*} param0 
 * @returns 
 */
export default function CalendarContainer({calendarDate,handleCalendarDate, dateText}){
      const [calendarVisible, setCalendarVisible] = useState(false);
      const [shortDate, setShortDate] = useState('')

      /**
       * Create the short date text
       */
      useEffect(()=>{
        const numbers = extractNumbersFromString(dateText)
        setShortDate(`${numbers[0].toString().padStart(2,'0')} - ${numbers[1].toString().padStart(2,'0')}`)
      },[dateText])

      /**
       * Creates the event listeners to handle clicking away from the calendar 
       */
      useEffect(() => { 
        const calendarElement = document.getElementById('calendar');
        const dateSubElement = document.getElementById('dateSub');
        const dateTextElement = document.getElementById('datetext')
        const dateShortTextElement = document.getElementById('datetext-short')
    
        function handleClickOutside(event) {
  
          if (
            calendarVisible &&
            !calendarElement.contains(event.target) &&
            !dateSubElement.contains(event.target) &&
            !dateTextElement.contains(event.target) &&
            !dateShortTextElement.contains(event.target)
          ) {
            if (dateSubElement) {
              dateSubElement.classList.remove("invis");
            }
            if (calendarElement) {
              calendarElement.classList.add("invis");
            }
            if(dateTextElement){
              dateTextElement.classList.remove("currentactive")
            }
            if(dateShortTextElement){
              dateShortTextElement.classList.remove("currentactive")
            }
            setCalendarVisible(false);
          }
        }
    
        document.addEventListener("click", handleClickOutside);
    
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, [calendarVisible]);

    const handleCalendarShow = () => {
        setCalendarVisible(true);
        const elementSub = document.getElementById("dateSub");
        if (elementSub) {
          elementSub.classList.add("invis");
        }
        const elementCalendar = document.getElementById("calendar");
        if (elementCalendar) {
          elementCalendar.classList.remove("invis");
        }
        const dateTextElement = document.getElementById('datetext')
        if(dateTextElement){
          dateTextElement.classList.add("currentactive")
        }
        const dateShortTextElement = document.getElementById('datetext-short')
        if(dateShortTextElement){
          dateShortTextElement.classList.add("currentactive")
        }
      };

      function extractNumbersFromString(inputString) {
        const numbers = inputString.match(/\d+/g);
        return numbers ? numbers.map(Number) : [];
      }
    return(
        <div>
            <div className="datetext " id="datetext" onClick={handleCalendarShow}>
                <Typography>{dateText}</ Typography>
                <ExpandMoreIcon/>
            </ div>
            <div className="datetext " id="datetext-short" onClick={handleCalendarShow}>
                <Typography>{shortDate}</ Typography>
                <ExpandMoreIcon/>
            </ div>
            <div className="calendar invis " id="calendar">
                <Paper elevation={10}>
                  <Calendar calendarDate={calendarDate} handleCalendarDate={handleCalendarDate} disablepast={false}/>
                </ Paper>
            </div>
      </div>
    )
}