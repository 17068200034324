import React, { useState, useEffect, useRef} from "react";
import ProjectRow from "./ProjectRow";
import "../../../styles/UrenVerantwoording/App.css"
import Button from '@mui/material/Button';

/**
 * Creates rows of project based on all the projects
 * @param {*} props 
 * @returns 
 */
const AddHours = ({Parts, Week, Email,UpdateHourLog, CreateHourLog, handleRemovePart, handleProjectRowAdd, CreateHourLogWeek, createComment,mobileActive, handleInputRow, searchQuery, specialAbsence,setSpecialAbsence}) => 
{    
  const [Loading, setLoading] = useState(true)
  /**
   * Check if parts is filled before rendering
   */
  useEffect(()=>{
    if(Parts === null) return
    if(Parts === undefined) return
    setLoading(false)
  },[Parts])

    if(Loading) return (<div></div>)
    return (
      <>
        <div className="projects-block">
          {Object.keys(Parts).map((part,i) =>(
            <ProjectRow key={part} onderdelen={Parts[part]}  projectNaam={part} Week={Week} UpdateHourLog={UpdateHourLog}
            CreateHourLog={CreateHourLog} handleInputRow={handleInputRow} handleRemovePart={ handleRemovePart} handleProjectRowAdd={handleProjectRowAdd}
            CreateHourLogWeek={CreateHourLogWeek} createComment={createComment} mobileActive={mobileActive} searchQuery={searchQuery} />
           )
          )}
        </div>
        {specialAbsence === true &&
          <div onClick={() => setSpecialAbsence(false)} style={{textAlign:"center"}}>
            <Button variant="contained">Terug naar projecten</Button>
          </div>
        }
        
        </>
    )
}

export default AddHours;
