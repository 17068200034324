import "../../../styles/UrenVerantwoording/App.css"
import TaskRow from "./TaskRow";
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect, useRef} from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/**
 * Loops through all task in a project and create a taskRow for them
 * @param {*} param0 
 * @returns
 */
const ProjectRow = ({onderdelen, projectNaam, Week, Email,UpdateHourLog, CreateHourLog,handleInputRow, handleRemovePart,handleProjectRowAdd,CreateHourLogWeek, createComment, mobileActive, searchQuery}) => 
{
    const [show, setShow] = useState(true)
    /**
     * useEffect to make sure the project is not in its collaped state
     */
    useEffect(() => {
        // Reset visibility when search query changes
        setShow(true);
    }, [searchQuery]);
        
    let isMatch = false
    // Filter projects based on search query
    if(projectNaam.toLowerCase().includes(searchQuery.toLowerCase()) || checkParts()){
        isMatch = true
    }
    
    /**
     * Checks if a partname contains the search querry
     * @returns true or false
     */
    function checkParts(){
        for (let index = 0; index < onderdelen.length; index++) {
            const part = onderdelen[index];
            if(part.Name.toLowerCase().includes(searchQuery.toLowerCase())) return true
        }
        return false
    }

    const reveal = () => setShow(!show)
    return (
        <div style={{ display: isMatch ? "block" : "none" }}>
        <div className="TimeRow">
            <div className="taskblock">
                <div className="titleblock" onClick={reveal}>
                    {projectNaam} <div className="expand">{show&&<ExpandLessIcon className="Registration"/>} {!show&& <ExpandMoreIcon className="Registration"/>}</div>
                </ div>
                 <div className={!show? "invis" : ''}>
                {onderdelen.map((Onderdeel,i) =>( <TaskRow onderdeel={{...Onderdeel}} handleInputRow={handleInputRow} key={`${projectNaam} + ${Onderdeel.Name} + ${i}`} week={Week} UpdateHourLog={UpdateHourLog} 
                CreateHourLog={CreateHourLog} irregular={i % 2 === 0? false: true} handleRemovePart={ handleRemovePart} 
                CreateHourLogWeek={CreateHourLogWeek} createComment={createComment} mobileActive={mobileActive}/>))}</div>
            </ div>
            <div className={!show? "invis" : ""+"container-part-add"} onClick={()=>{handleProjectRowAdd(projectNaam)}}>
                <div className="part-add">
                    <AddIcon className="Registration"></AddIcon>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ProjectRow;
