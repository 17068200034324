import axios from 'axios';


const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
//console.log(API_KEY);
const HEADERS = {
  Authorization: `Bearer ${API_KEY}`,
  'Content-Type': 'application/json'
};
export async function getProject(Projectnummer) {

  const url = `https://api.airtable.com/v0/apphMT5j6gtUnMY1p/Projecten?filterByFormula=SEARCH('${Projectnummer}', LOWER({Projectnr_en_naam}))&sort[0][field]=Projectnummer`
 //filter on project number
  const responseProjecten = await axios.get(url, { headers: HEADERS });
  return responseProjecten;
}


