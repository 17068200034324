import React, { useState, useEffect } from "react";
import { getProject } from "../../ui-components/projecten";
import { getStandardTasks } from "../../ui-components/standaardTaken";
import { getDeliverables } from "../../ui-components/deliverables";
import { getStandardTasksLabels } from "../../ui-components/standaardTakenLabels";
import { postTasks } from "../../ui-components/postTasks";
//import {getPartsFromProject} from "./UrenVerantwoording/API/OperationalTable.js"
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from "@mui/material/Select";
import { Typography, Paper, colors } from "@mui/material";
import Loading from "../../ui-components/Urenverantwoording/MiscComp/LoadingScreen"
import Button from "@mui/material/Button";
import { getPeople } from "../../ui-components/asignees";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GeneralSnackBar from "../../ui-components/Urenverantwoording/MiscComp/GeneralSnackBar";
import { patchPart } from "../../ui-components/patchPart";


// all useStates 
const TaskButton = () => {
    const [projecten, setProjecten] = useState(null)
    const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [standardTasksLabel, setStandardTasksLabel] = useState(null)
    const [selectedTaskLabel, setSelectedTaskLabel] = useState(null)
    const [deliverables, setDeliverables] = useState(null)
    const [selectedTaskId, setSelectedTaskId] = useState(null)
    const [deliverableRecordId, setDeliverableRecordId] = useState(null)
    const [selectedAssignee, setSelectedAssignee] = useState('')
    const [substeps, setSubsteps] = useState(null)
    const [totalHours, setTotalHours] = useState(null)
    const [showLabels, setShowLabels] = useState(false)
    const [showAssignee, setShowAssignee] = useState(false)
    const [showDeadline, setShowdeadline] = useState(false)
    const [showStart, setShowStart] = useState(false)
    const [functionsNeeded, setFunctionsNeeded] = useState([]);
    const [selectedFunction1, setSelectedFunction1] = useState('')
    const [selectedFunction2, setSelectedFunction2] = useState('')
    const [secondAssignee, setSecondAssignee] = useState('')
    const [showFeedback, setShowFeedback] = useState(false)
    const [feedbackNumber, setFeedbackNumber] = useState(0)
    const [startDate, setStartDate] = useState(new Date())
    const [deadlineOption, setDeadlineOption] = useState('custom')
    const [deadlineType, setDeadlineType] = useState('')
    const [selectedValue, setSelectedValue] = useState(-1)
    const [selectedCustom, setSelectedCustom] = useState(-1)
    const [open, setOpen] = React.useState(false);
    const [failed, setFailed] = useState(false)
    const [formatted, setFormatted] = useState('')
    const [failer, setFailer] = useState('')
    const [assignee, setAssignee] = useState([])
   
    const [snackbarData,setSnackbarData] = useState({open : false, text:'', type:''})
    const [createdTasks, setCreatedTasks] = useState([])
    const [deliverableName, setDeliverableName] = useState()
    const [partProjects, setPartProject] = useState([]) 
    const [selectedValues, setSelectedValues] = useState({})



    // custom marks for feedback day selection
    const marks = [{
        value: 0,
        label: '0'
    }, {
        value: 1,
        label: '1'
    }, {
        value: 2,
        label: '2'
    }, {
        value: 3,
        label: '3'
    }, {
        value: 4,
        label: '4'
    }, {
        value: 5,
        label: '5'
    }]


    useEffect(()=>{
        console.log(createdTasks)
    },[createdTasks])

    //data being loaded at page load    
    useEffect(() => {
        async function fetchData() {
            try {
                
                //get label data
                const tasksData = await getStandardTasksLabels();
                setStandardTasksLabel(tasksData);
                console.log(standardTasksLabel)

                //get employee data
                const peopleData = await getPeople()
                setAssignee(peopleData)


            } catch (error) {
                console.error("Error fetching data:", error);
                // Set projecten and standardTasks to a default value or handle the error accordingly
                setProjecten({ data: { records: [] } });
                setStandardTasksLabel({ data: { records: [] } });
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);  // Empty dependency array, so this effect runs only once

    if (loading) return (<Loading />);

    const records = projecten?.data?.records || [];

    // filter projects based on search term
    const searchWord = searchTerm.toLowerCase(); // Convert the search term to lowercase

    const filteredProjects = records.filter((record) => {
        const projectName = record.fields.Projectnr_en_naam.toString().toLowerCase(); // Convert the project name to lowercase
        return projectName.includes(searchWord); // Check if the lowercase project name includes the lowercase search term
    });



    // run this function when project button is clicked
    // gets deliverables based on project number
    // sorts deliverables
    const handleProjectClick = async (projectnummer) => {
        try {
            const deliverableData = await getDeliverables(projectnummer);

            const sortedDeliverables = deliverableData.data.records.sort((a, b) => a.fields.Name.localeCompare(b.fields.Name));
            setDeliverables({ ...deliverableData, data: { ...deliverableData.data, records: sortedDeliverables } });
            console.log("Deliverables:", sortedDeliverables);  // Log sorted deliverables
            setSearchTerm('')

            //get part data
            //return await _Airtable.GetPartsFromProject(projectNumber)

            const partData = await _Airtable.getPartsFromProject(projectnummer, AirtableData.Operational.children.Onderdeel)
            console.log('_airtable',_Airtable)
            setPartProject(partData)
            console.log(partData)
        } catch (error) {
            console.error("Error fetching deliverables or parts:", error);
            // Handle the error accordingly
        }
    };

    // function that runs when text in project search bar is changed
    const handleNumber = async (e) => {
        const typedProjectNumber = e.target.value;

        setSearchTerm(typedProjectNumber);  // Update the searchTerm with the typed Projectnummer
        //let searchWord = typedProjectNumber.toLowerCase()

        try {
            const projectData = await getProject(searchWord);  // Fetch projects based on typed Projectnummer
            setProjecten(projectData);
        } catch (error) {
            console.error("Error fetching projects:", error);
            setProjecten({ data: { records: [] } });  // Handle the error accordingly
        }
    }

    // function that runs when a standard task has been selected
    const handleTaskSelection = async (taskId) => {
        const selectedTask = standardTasksLabel.data.records.find(task => task.id === taskId);

        // set data related to tasks
        if (selectedTask) {
            setSelectedTaskId(taskId);
            setSelectedTaskLabel(selectedTask.fields.Name);
            setSubsteps(selectedTask.fields.TotalSubstappen);

            try {
                // Fetch tasks data
                const tasksData = await getStandardTasks(selectedTask.fields.Name);
                console.log('Tasks Data:', tasksData.data.records);

                // Extract unique functions
                const uniqueFunctions = await extractUniqueFunctions(tasksData.data.records);
                setFunctionsNeeded(uniqueFunctions);
                console.log(functionsNeeded);

                // Perform getPeople query for each unique function
                const functionQueries = uniqueFunctions.map(async (func, index) => {
                    try {
                        const peopleData = await getPeople(func); // Assuming getPeople accepts a function argument

                        // Update state based on index
                        if (index === 0) {
                            setSelectedFunction1(peopleData);
                            console.log('Data for function 1:', peopleData);
                        } else if (index === 1) {
                            setSelectedFunction2(peopleData);
                            console.log('Data for function 2:', peopleData);
                        }
                    } catch (error) {
                        console.error(`Error fetching people data for function ${func}:`, error);
                    }
                });

                // Wait for all queries to complete
                await Promise.all(functionQueries);

                console.log('Unique functions needed:', uniqueFunctions);
            } catch (error) {
                console.error('Error fetching tasks data:', error);
            }
        };
        setShowdeadline(true)
    };

    // function to get all different types of employee needed
    const extractUniqueFunctions = async (tasks) => {
        const uniqueFunctions = new Set();
        tasks.forEach(task => {
            if (task.fields.FunctieNodig) {
                task.fields.FunctieNodig.forEach(func => {
                    console.log('Function found:', func);
                    uniqueFunctions.add(func);
                });
            }
        });
        return Array.from(uniqueFunctions);
    };


    // function that sets variables when a deliverable has been selected
    const handleDeliverableSelection = (deliverableID, deliverableName) => {
        const selectedDeliverable = deliverables.data.records.find(deliverable => deliverable.id === deliverableID);
        if (selectedDeliverable) {
            setDeliverableRecordId(deliverableID);
            setTotalHours(selectedDeliverable.fields['Uren geoffreerd']); // Set total hours using useState
            setDeadlineType(selectedDeliverable.fields.TypeDeadline) // set deadline type
            console.log(totalHours)
            setShowLabels(true)
            setDeadlineOption(selectedDeliverable.fields.DeadlineDeliverable)
            setDeliverableName(deliverableName)
        }
    }


    // function that handles deadline selection
    // switch case for data based on selected radiobutton
    const handleDeadlineSelection = (event) => {
        const value = event.target.value;
        const today = new Date();
        let newDeadline;

        const addDays = (date, days) => {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + days);
            return newDate;
        };

        switch (value) {
            case '0':
                // Deadline deliverable
                newDeadline = deadlineOption;
                console.log(value)
                setSelectedValue(value)
                break;

            case '1':
                // Over 1 week
                newDeadline = addDays(today, 7);
                console.log(value)
                setSelectedValue(value)
                break;

            case '2':
                // Over 2 weken
                newDeadline = addDays(today, 14);
                console.log(value)
                setSelectedValue(value)
                break;

            case '3':
                // Over 3 weken
                newDeadline = addDays(today, 21);
                console.log(value)
                setSelectedValue(value)
                break;

            case '4':
                // Over 4 weken
                newDeadline = addDays(today, 28);
                console.log(value)
                setSelectedValue(value)
                break;

            case '5':
                // Over 6 weken
                newDeadline = addDays(today, 42);
                console.log(value)
                setSelectedValue(value)
                break;

            case '6':
                // Custom
                const deadlineString = event.target.nextSibling.value;
                newDeadline = new Date(deadlineString);
                console.log(newDeadline)
                setSelectedValue(value)
                break;

            default:
                break;
        }

        setDeadlineOption(newDeadline);
        setShowStart(true);
    };

    // function that handles the start date selection
    // switch case based on which radiobutton is selected
    const handleStartSelection = (event) => {
        const value = event.target.value;

        switch (value) {
            case '0':
                // Today
                setStartDate(new Date());
                setSelectedCustom(value)
                break;

            case '1':
                // Tomorrow
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                setStartDate(tomorrow);
                setSelectedCustom(value)
                break;

            case '2':
                // Next Monday
                const today = new Date();
                const nextMonday = new Date(today);
                nextMonday.setDate(today.getDate() + ((1 + 7 - today.getDay()) % 7));
                setStartDate(nextMonday);
                setSelectedCustom(value)
                break;

            case '3':
                //custom
                const customStartDate = new Date(event.target.nextSibling.value);
                console.log('Custom start date:', event.target.nextSibling.value);
                // Update state if needed
                setStartDate(customStartDate);
                setSelectedCustom(value)
                break;

            default:
                break;
        }
        setShowAssignee(true)
        setShowFeedback(true)
    };


    // handles how many feedback days have been selected
    const feedbackDays = (event) => {
        setFeedbackNumber(event.target.value)
        console.log(event.target.value)
    }

    // formats date inputs to YYYY-MM-DD format
    const formatDateToYYYYMMDD = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };
    const tasksToCreate = [];




    //main function that creates all tasks
    const createTasks = async () => { 
        // Check if a task is selected and a deliverable record ID is available
        if (selectedTaskId && deliverableRecordId && selectedAssignee) {
            let previousTaskId = null; // Initialize to null for the first task
            let currentDate = new Date(startDate);
            let prio = '';
            console.log(currentDate);

            // tasksToCreate = []; // Create an array to hold the tasks to be created

            try {
                // Use the selected standardTasksLabel (Name field) to fetch tasks
                const tasksData = await getStandardTasks(selectedTaskLabel);
                console.log(tasksData)
                console.log()

                // Fetch additional information needed for the task creation from the existing deliverable data
                const deliverable = deliverables.data.records.find(
                    (deliverable) => deliverable.id === deliverableRecordId
                );

                const projectId =
                    deliverable.fields['Project Record ID in ToDo_Planning Base (from Project)'];

                const totalTaskHours = totalHours; // Total hours for the deliverable
                let remainingHours = totalTaskHours;

                let isFirstTask = true;

                // Iterate over the selected standard tasks and create a task object for each
                for (const [index, task] of tasksData.data.records.entries()) {
                    // Calculate task hours based on weight per substep

                    const taskHours = (totalTaskHours / substeps) * task.fields.ZwaarteStap;
                    console.log(task);
                    let endDate = currentDate


                    if (!isFirstTask) {
                        // Calculate start date for the next task based on feedback days
                        currentDate = addWorkDays(endDate, feedbackNumber + 1);
                        if (deadlineType === "Hard") {
                            prio = "Prio 1"
                        }
                        else {

                            prio = "Nog niet van toepassing";
                        }
                    }
                    else {
                        if (deadlineType === "Hard") {
                            prio = "Prio 1";
                        } else {
                            prio = "Prio 3";
                        }
                    }
                    // Calculate end date based on task hours and remaining hours
                    if (remainingHours <= 8) {
                        endDate = addWorkDays(currentDate, 1)
                        console.log("EIND " + endDate + "START " + currentDate + index)
                    }

                    if (remainingHours > 8) {
                        endDate = addWorkDays(currentDate, Math.ceil(taskHours / 8));
                    }

                    if (taskHours > 8) {
                        endDate = addWorkDays(currentDate, Math.round(taskHours / 8));
                        console.log("FLOEPFLOEPFLOEP dit getal is " + Math.round(taskHours / 8))
                    }
                    else {
                        endDate = currentDate
                        console.log("Eind datum zou op de zelfde dag moeten zijn")
                    }


                    isFirstTask = false;

                    //format start and end dates
                    const formattedEndDate = formatDateToYYYYMMDD(endDate);
                    const formattedStartDate = formatDateToYYYYMMDD(currentDate);

                    // Create the task name using the standard task's name and the deliverable's name
                    const taskName = `${task.fields.Name} - ${deliverable.fields.Name}`;

                    let person = '';

                    //checks what employees are needed for specific task
                    //sets to null if FunctieNodig is empty
                    if (!task.fields.FunctieNodig) {
                        person = null;
                    } else if (task.fields.FunctieNodig.toString() === functionsNeeded[0]) {
                        person = [selectedAssignee];
                    } else if (task.fields.FunctieNodig.toString() === functionsNeeded[1]) {
                        person = [secondAssignee];
                    }

                    //
                    const type = task.fields.Type 
                    //store tasks in array
                    //array is used for checking later
                    const taskObject = {
                        taskName,
                        projectId,
                        formattedEndDate,
                        person: person ? person : null,
                        deliverableRecordId,
                        type,
                        taskHours,
                        previousTaskId: previousTaskId ? [previousTaskId.id.id] : null,
                        formattedStartDate,
                        deadlineType,
                        prio,
                    };


                    // Push the task object into the tasksToCreate array
                    tasksToCreate.push(taskObject);

                    remainingHours -= taskHours;
                }
                setCreatedTasks(tasksToCreate)
                // Check if the end date of the final task is before the deadline
                let i = 1
                let prevId = null
                const createdIds = []
                const formattedOption = formatDateToYYYYMMDD(deadlineOption)
                setFormatted(formattedOption)
                setFailer(tasksToCreate[tasksToCreate.length - 1].formattedEndDate)

                if (new Date(tasksToCreate[tasksToCreate.length - 1].formattedEndDate) <= new Date(deadlineOption)) {
                    console.log(tasksToCreate[tasksToCreate.length - 1].formattedEndDate + " compared to: " + deadlineOption)

                    // loop over task array
                    // post to airtable with eacht iteration

                    for (const taskObject of tasksToCreate) {
                        const taskId = await postTasks(
                            taskObject.taskName,
                            taskObject.projectId,
                            i === tasksToCreate.length ? formattedOption : taskObject.formattedEndDate,
                            taskObject.person,
                            taskObject.deliverableRecordId,
                            taskObject.taskHours,
                            prevId ? [prevId] : null,
                            taskObject.formattedStartDate,
                            taskObject.formattedEndDate,
                            taskObject.deadlineType,
                            taskObject.prio,
                            taskObject.type
                        );
                        createdIds.push(taskId.id)
                        // set previous id for dependencies
                        prevId = taskId.id
                        i++
                    }
                    for (let index = 0; index < createdIds.length; index++) {
                        const id = createdIds[index];
                        tasksToCreate[index]["id"] = id
                    }
                    
                    console.log('tastks to create', tasksToCreate)
                    setCreatedTasks(tasksToCreate)
                    
                    // open Dialog when tasks are created successfully
                    setOpen(true);
                    console.log(tasksToCreate)
                    window.scrollBy(0 , window.innerHeight)
                } else {
                    // open Dialog when task creation fails
                    setFailed(true)
                }
            } catch (error) {
                console.error('Error creating tasks:', error);
            }
        } else {
            console.error('Please select a task and a deliverable.');
        }
    };


    // when assignee is selected
    const handleAssignee = (event) => {
        setSelectedAssignee(event.target.value)
    }

    // when second assignee is selected
    const handleSecondAssignee = (event) => {
        setSecondAssignee(event.target.value)
    }

    // when a custom deadline is used
    const handleCustomDeadline = (event) => {
        const custom = new Date(event.target.value)
        setDeadlineOption(custom)
        console.log(custom)
        setSelectedValue(6)
        setShowStart(true)
    }

    // when a custom startdate is used
    const handleCustomStart = (event) => {
        const custom = new Date(event.target.value)
        setStartDate(custom)
        console.log(custom + " CUSTOM")
        console.log(startDate + " STARTDATE")
        setSelectedCustom(3)
        setShowFeedback(true)
        setShowAssignee(true)
    }

    // handles adding work days
    const addWorkDays = (date, days) => {
        const result = new Date(date);

        for (let i = 0; i < days; i++) {
            result.setDate(result.getDate() + 1);

            // Skip weekends (Saturday: 6, Sunday: 0)
            while (result.getDay() === 6 || result.getDay() === 0) {
                result.setDate(result.getDate() + 1);
            }
        }

        return result;
    }

    // when oké is clicked on either Dialog
    const handleOkay = () => {
        setOpen(false)
        setFailed(false)
    }



    const PartMenu = (taskid) =>{
        console.log(taskid)
        function handleChange(event){
            handleSelectChange(taskid, event.target.value)
        }
        const handleSelectChange = (taskId, selectedValue) => {
            const nextState = selectedValues
            nextState[taskId] = selectedValue
            setSelectedValues(nextState)
          };   

        return(
            <div>
            <Typography style={{ fontSize: 18, marginBottom: '5px' }} align='left'>
                Selecteer Onderdeel dat bij de taak hoort
            </Typography>
                <Select
                    value={selectedValues[taskid] }
                    onChange={(event) => handleChange(event)}
                    style={{ width: '200px' }}
                >
                    <MenuItem value="" disabled>
                        Select an option
                    </MenuItem>{partProjects.records.map((part) => (
                        <MenuItem key={part.id}  value={part.id}>
                            {part.fields.offerteregelOmschrijving}
                        </MenuItem>
                    ))}

                </Select>
            </div>
        )
    }

    async function patchParts(){
        console.log(selectedValues)

        Object.keys(selectedValues).forEach(async (key)=>{
            try{
                const res = await patchPart(key,selectedValues[key])
                console.log(res)
                setSnackbarData({open:true,text:"Onderdeel toegevoegt", type:"success"}) 
            }catch(err){
                console.error(err)
                setSnackbarData({open:true,text:"Er is iets mis gegaan", type:"error"}) 
            }
        })
    }


    return (
        <>
        <Paper sx={{ marginTop: 3, paddingRight: 10, paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }} elevation={10}>
            <Typography variant="h4" align="center" component="h1" gutterBottom>
                <strong >STANDAARD TAKEN TOEVOEGEN</strong>
            </Typography>
            <TextField fullWidth
                placeholder="Typ projectnaam of nummer"
                value={searchTerm}
                onChange={handleNumber}  // Update to call handleNumber when the input changes
                style={{ width: '500px' }}
            />

            {searchTerm && (
                <Box style={{ maxWidth: '500px', maxHeight: '300px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '10px' }} sx={{ borderRadius: 2 }}>
                    <List>
                        {filteredProjects.map((record) => (
                            <ListItemButton component="a" key={record.id} onClick={() => handleProjectClick(record.fields.Projectnummer)}>
                                {record.fields.Projectnr_en_naam}
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            )}
            {deliverables && (
            <Box sx={{ mt: 1 }}> {/* Use spacing system provided by MUI for margin-top */}

                <Typography variant="subtitle1" gutterBottom> {/* Use a smaller variant */}
                Selecteer een deliverable
                </Typography>

                <RadioGroup size="small"> {/* There is no size prop for RadioGroup but you can target its style */}
                {deliverables.data.records.map((deliverable) => (
                    <FormControlLabel
                    key={deliverable.id}
                    value={deliverable.id}
                    control={<Radio size="small" />} 
                    label={
                        <Box sx={{ mt: 0.5 }}> {/* Adjust margin-top to reduce space */}
                        <Typography variant="body2"> {/* Use a smaller variant */}
                            {deliverable.fields.NaamDeliverable}
                        </Typography>
                        {deliverable.fields.DeadlineDeliverable && (
                            <Typography variant="body2" color="textSecondary">
                            Deadline: {deliverable.fields.DeadlineDeliverable}
                            </Typography>
                        )}
                        {deliverable.fields.TypeDeadline && (
                            <Typography variant="body2" color="textSecondary">
                            Type deadline: {deliverable.fields.TypeDeadline}
                            </Typography>
                        )}
                        </Box>
                    }
                    onChange={() => handleDeliverableSelection(deliverable.id, deliverable.fields.NaamDeliverable)}
                    sx={{
                        '& .MuiTypography-body1': { // Target the label's typography to reduce its size
                        fontSize: '0.8rem',
                        },
                    }}
                    />
                ))}
                </RadioGroup>
            </Box>
            )}

            <br></br>
            <br></br>
            {showLabels && (
                <Box>
                    <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                        Kies standaard taken om toe te voegen
                    </Typography>
                    <RadioGroup>
                        {standardTasksLabel.data.records.map((task) => (
                            <FormControlLabel
                                key={task.id}
                                value={task.id}
                                control={<Radio />}
                                label={task.fields.Name}
                                onChange={() => handleTaskSelection(task.id, task.fields.Name)}  // Call handleTaskSelection on radio button change
                            />
                        ))}
                    </RadioGroup>
                </Box>
            )}
            <br></br>
            <br></br>
            {showDeadline && (
                <Box>
                    <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                        Wanneer wil je dat de deliverable is afgerond?
                    </Typography>
                    <RadioGroup onChange={handleDeadlineSelection} value={selectedValue}>
                        <FormControlLabel value={0} control={<Radio />} label="Deadline deliverable" />
                        <FormControlLabel value={1} control={<Radio />} label="Over 1 week" />
                        <FormControlLabel value={2} control={<Radio />} label="Over 2 weken" />
                        <FormControlLabel value={3} control={<Radio />} label="Over 3 weken" />
                        <FormControlLabel value={4} control={<Radio />} label="Over 4 weken" />
                        <FormControlLabel value={5} control={<Radio />} label="Over 6 weken" />
                        <FormControlLabel value={6} control={<Radio />} label={<input type="date" id="customDeadline" name="customDeadline" onChange={handleCustomDeadline} />} />
                    </RadioGroup>
                </Box>
            )}
            <br></br>
            <br></br>
            {showStart && (
                <Box>
                    <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                        Wat is de startdatum?
                    </Typography>
                    <RadioGroup onChange={handleStartSelection} value={selectedCustom}>
                        <FormControlLabel value={0} control={<Radio />} label="Vandaag" />
                        <FormControlLabel value={1} control={<Radio />} label="Morgen" />
                        <FormControlLabel value={2} control={<Radio />} label="Aanstaande maandag" />
                        <FormControlLabel value={3} control={<Radio />} label={<input type="date" id="customStart" name="customStart" onChange={handleCustomStart} />} />
                    </RadioGroup>
                </Box>
            )}
            <br></br>
            <br></br>
            {showFeedback && (
                <Box>
                    <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                        Aantal werkdagen wachten op feedback: {feedbackNumber}
                    </Typography>
                    <Slider onChange={feedbackDays} defaultValue={0} step={1} marks={marks} max={5} valueLabelDisplay="auto" />
                </Box>
            )}
            <br></br>
            <br></br>
            {showAssignee && (
                <Box>
                    <Typography style={{ fontWeight: "bold", fontSize: 20 }} align='left'>
                        Beoogde creative
                    </Typography>
                    <br></br>
                    {functionsNeeded.length === 1 && (

                        <div>

                            <Typography style={{ fontSize: 18, marginBottom: '5px' }} align='left'>
                                {functionsNeeded[0]}
                            </Typography>
                            <Select
                                value={selectedAssignee}
                                onChange={handleAssignee}
                                displayEmpty
                                style={{ width: '200px' }}
                            >
                                <MenuItem value="" disabled>
                                    Select an option
                                </MenuItem>{selectedFunction1.data && selectedFunction1.data.records.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.fields.Naam}
                                    </MenuItem>
                                ))}

                            </Select>
                        </div>
                    )}
                    {functionsNeeded.length === 2 && (
                        <div>
                            <Typography style={{ fontSize: 18, marginBottom: '5px' }} align='left'>
                                {functionsNeeded[0]}
                            </Typography>
                            <Select
                                value={selectedAssignee}
                                onChange={handleAssignee}
                                displayEmpty
                                style={{ width: '200px' }}
                            >
                                <MenuItem value="" disabled>
                                    Selecteer een optie
                                </MenuItem>
                                {functionsNeeded.length === 2 && selectedFunction1 && selectedFunction1.data && selectedFunction1.data.records.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.fields.Naam}
                                    </MenuItem>
                                ))}
                            </Select>

                            <Typography style={{ fontSize: 18, marginTop: '10px', marginBottom: '5px' }} align='left'>
                                {functionsNeeded[1]}
                            </Typography>
                            <Select
                                value={secondAssignee}
                                onChange={handleSecondAssignee}
                                displayEmpty
                                style={{ width: '200px' }}
                            >
                                <MenuItem value="" disabled>
                                    Selecteer een optie
                                </MenuItem>
                                {functionsNeeded.length === 2 && selectedFunction2 && selectedFunction2.data && selectedFunction2.data.records.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.fields.Naam}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}
                </Box>
            )}


            <br></br>
            <br></br>
            <Button
                variant="contained"
                onClick={createTasks}
                disabled={!selectedTaskId || !deliverableRecordId || !selectedAssignee || (functionsNeeded.length === 2 && !secondAssignee) || feedbackNumber === null || startDate === null}
            >Opslaan</Button>

            <br></br>
            <br></br>
            {createdTasks.length > 0 && 
            (
            <div id="part-select">
                {createdTasks.map((task) =>(
                    <div key={task.id}>
                        <span style={{ fontSize: 18, fontWeight: 'bold'}}>{task.taskName}</span>
                        {PartMenu(task.id)}
                        <br></br>
                    </div>
                ))}
                            <Button
                                variant="contained"
                                onClick={patchParts}
                                disabled={selectedValues.length> 0}
                                >
                                Sla onderdelen op
                            </Button>
            
            </div>
            )}

            {open && (
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <span style={{ color: 'green', fontSize: 30 }}>✓ Taken aangemaakt</span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <span style={{fontSize: 21, fontWeight: 'bold'}}>{deliverableName}</span>
                            <br></br><br></br>
                            {createdTasks.map((item, index) => (
                                <div key={index}>
                                    <span style={{ fontSize: 18, fontWeight: 'bold'}}>{item.taskName}</span>
                                    <br></br>
                                    <span>Start: {item.formattedStartDate} &nbsp;&nbsp;</span> 
                                    <span>Eind: {item.formattedEndDate}</span>
                                    <br></br><br></br>
                                </div>
                            ))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOkay} >Oké</Button>
                    </DialogActions>
                </Dialog>)}

            <Dialog
                open={failed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span style={{ color: 'red' }}>Daar ging iets fout!</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        De laatste einddatum van deze taken is <span style={{ color: 'red', fontWeight: 'bold' }}>{failer}</span>, dat gaat niet met je deadline op <span style={{ color: 'red', fontWeight: 'bold' }}>{formatted}</span>. Kies een andere deadline of neem minder feedback dagen!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOkay} >Oké</Button>
                </DialogActions>
            </Dialog>
            <br></br>
            <br></br>
        </Paper>
        <GeneralSnackBar Data={snackbarData} close={setSnackbarData} />
        </>
    );
   

};

export default TaskButton;
