import axios from 'axios';


const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
//console.log(API_KEY);
const HEADERS = {
    Authorization: `Bearer ${API_KEY}`,
    'Content-Type': 'application/json'
};
export async function getDeliverables(projNum) {

    const url = `https://api.airtable.com/v0/appairPVZTnXeJaJG/Deliverables?filterByFormula={Projectnummer}='${projNum}'`
    const responseProjecten = await axios.get(url, { headers: HEADERS });
    return responseProjecten;
}