import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { loginRequest } from "../authConfig";

export const SignInButton = () => {
    const { instance } = useMsal();
// or for localStorage:
//    for (let key in localStorage) {
//        if (key.indexOf("msal.") !== -1) {
//            localStorage.removeItem(key);
//        }
//    }
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleLogin = (loginType) => {
        setAnchorEl(null);

        if (loginType === "popup") {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
         */
            instance.loginPopup({
                ...loginRequest,
                redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect({...loginRequest,
            redirectUri:process.env.REACT_APP_POPUP_REDIRECT_URI
            });
        }
    }

    return (
        <div>
            <Button
                onClick={() => handleLogin("redirect")}
                color="inherit"
            >
                Login
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleLogin("redirect")} key="loginRedirect">Sign in</MenuItem>

            </Menu>
        </div>
    )
};