import * as React from 'react';
import List from '@mui/material/List';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { FixedSizeList } from 'react-window';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ProjectPartDialog from '../Dialog/ProjectPartDialog';
import Airtabledata from '../../../utils/UrenVerantwoording/API/AirtableData.json'


import axios from 'axios';

const url_self = process.env.REACT_APP_CURRENT_URL;


/**
 * A list of all project
 * @param {*} param0 
 * @returns 
 */
export default function ProjectList({_Airtable, values, searchPart, searchTerm, submit,role, structure }) {
  const [resolvedValues, setResolvedValues] = React.useState([]);
  const [filteredValues, setFilteredValues] = React.useState([]);
  const [checked, setChecked] = React.useState([0]);
  const [openPart, setOpen] = React.useState(false);
  const [selectedPartValues, setSelectedPartValues] = React.useState([])
  const [filterCheckPartDialog, setFilterCheckPartDialog] = React.useState(true)
  const projectData = Airtabledata.Operational.children.Projecten.children

  const close = () =>{
    setOpen(false)
  }

  async function GetPartsFromProjectLocal(projectNumber, partData) {
      try {
          console.log('partData', partData)
          const query = encodeURIComponent(`${partData.name}?filterByFormula=AND({${partData.children["Projectnummer (from Projecten)"]}} = "${projectNumber}", {${partData.children["hideForTimeWriting"].name}} = "false")`);
          console.log('Making API Call to:', `${url_self}/Api/OperationalTable/${query}`);
          const response = await axios.get(`${url_self}/Api/OperationalTable/${query}`);
          console.log('API Response:', response.data);
          return response.data.records;
      } catch (err) {
          console.error('Error fetching parts:', err);
          return err;
      }
  }

  async function searchParts(projectNumber) {
    try {
        var response;
        console.log("airtableData", Airtabledata.Operational.children.Onderdeel)
        response = await GetPartsFromProjectLocal(projectNumber, Airtabledata.Operational.children.Onderdeel);
        console.log('responseXXX2', await GetPartsFromProjectLocal(projectNumber,Airtabledata.Operational.children.Onderdeel));
        return response;
    } catch (error) {
        console.error('Error fetching parts:', error);
    }
  }

  const handleToggle = (value) => async () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
        newChecked.push(value);
    } else {
        newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    const parts = await searchParts(value.fields[projectData.Projectnummer]);
    if (parts) {
        console.log('parts2', parts);
        setSelectedPartValues(parts);
        setOpen(true);
    } else {
        console.error('No parts fetched');
    }
  };


  /**
   * Value prop is a promise and needs to be resolved before we can use it.
   */
  React.useEffect(() => {
    if (values instanceof Promise) {
      values.then((resolved) => {
        setResolvedValues(resolved);
        setResolvedValues((resolved)=>{
            resolved['index'] = 0
            return resolved
        })
      });
    }else{
      setResolvedValues(values)
      setResolvedValues((resolved)=>{
        resolved['index'] = 0
        return resolved
    })
    }
  }, [values]);

  /**
   * 
   */
  React.useEffect(()=>{
    setFilteredValues(resolvedValues)
  },[resolvedValues])

  React.useEffect(()=>{
    if(resolvedValues.length === 0) return
        setFilteredValues(resolvedValues.filter((value)=>{
            return value.fields[projectData.Projectnr_en_naam].toLowerCase().includes(searchTerm.toLowerCase())
    }))
  },[searchTerm])

  function changeCheck(){
    setFilterCheckPartDialog(!filterCheckPartDialog)
  }

  if (filteredValues.length === 0) return <div>Nothing found</div>;

  const Row = ({ index, style }) => {
    const value = filteredValues[index];
    const labelId = `checkbox-list-label-${value.id}`;
    return (
        <div style={style}>
      <ListItem key={value.id} disablePadding className='big-item'>
        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
        <ListItemText id={labelId} primary={`${value.fields[projectData.Projectnr_en_naam]}`} />
          <ListItemIcon>
            <ArrowForwardIosIcon/>
          </ListItemIcon>

        </ListItemButton>
      </ListItem>
      </div>
    );
  };

  return (
    <div>
    <FixedSizeList
      height={400} // Set the height of the visible area
      itemCount={filteredValues.length}
      itemSize={80} // Set the height of each item
      width={1000}
    >
      {Row}
    </FixedSizeList>
    <ProjectPartDialog open={openPart} onClose={close} submit={submit} values={selectedPartValues} filterCheck={filterCheckPartDialog} changeCheck={changeCheck} role={role} structure={structure}/>
    </div>
  );
}