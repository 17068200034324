import React from 'react';
import {Scanner} from '@yudiel/react-qr-scanner';

const QrCodeScanner = ({ onScan }) => {
    const handleResult = (result, error) => {
        if (!!result) {
            onScan(result?.text);
        } else if (!!error) {
            console.error("QR Code decoding error:", error);
        }
    };

    return (
        <Scanner
            onResult={handleResult}
            style={{ width: '100%' }}
        />
    );
};

export default QrCodeScanner;
