import axios from "axios";
import Airtabledata from "./AirtableData.json"

const url_self = process.env.REACT_APP_CURRENT_URL;
/**
 * Data layer accessing operational base
 */
export class OPERATIONAL_CRUD {

    /**
     * gets all user info of user from the greenbase. All data thats needed gets requested
     * @param {*} email 
     * @param {*} personenData JSON data
     * @returns 
     */
    async GetActivePart(email, personenData) {
        try {
            const fieldsToRetrieve = [
                Airtabledata.Operational.children.Personen.children["Active Task History Project Name"],
                Airtabledata.Operational.children.Personen.children["Aantal van offerteregel"],
                Airtabledata.Operational.children.Personen.children["Active Task History Projects"],
                Airtabledata.Operational.children.Personen.children.Email,
                Airtabledata.Operational.children.Personen.children.Functie,
                Airtabledata.Operational.children.Personen.children["Group not empty (from OfferteRegels)"],
                Airtabledata.Operational.children.Personen.children["Omschrijving (from OfferteRegels)"],
                Airtabledata.Operational.children.Personen.children["OmschrijvingCategorie (from OfferteRegels)"],
                Airtabledata.Operational.children.Personen.children["OfferteRegels"],
                Airtabledata.Operational.children.Personen.children["Projectnr_en_naam (from Projecten) (from OfferteRegels)"],
                Airtabledata.Operational.children.Personen.children["Standalone Project"],
                Airtabledata.Operational.children.Personen.children["Standalone Project Name"],
                Airtabledata.Operational.children.Personen.children.Type,
                Airtabledata.Operational.children.Personen.children.Unit,
                Airtabledata.Operational.children.Personen.children["autonumber (from OfferteRegels)"],
                Airtabledata.Operational.children.Personen.children["offerteregelOmschrijving (from OfferteRegels)"],
                Airtabledata.Operational.children.Personen.children["rec ID green"],
                Airtabledata.Operational.children.Personen.children.recordID,
                Airtabledata.Operational.children.Personen.children["Projecten waarbij PL"],
                Airtabledata.Operational.children.Personen.children["Active Task With Part"],
                Airtabledata.Operational.children.Personen.children["Active task RecID"],
                Airtabledata.Operational.children.Personen.children["Active task count"],
                Airtabledata.Operational.children.Personen.children["Active Task project name"],
                Airtabledata.Operational.children.Personen.children["Active Task part name"],
                Airtabledata.Operational.children.Personen.children["Active Task short description"],
                Airtabledata.Operational.children.Personen.children["Active Task long description"],
                Airtabledata.Operational.children.Personen.children["Active Task autonumber"],
                Airtabledata.Operational.children.Personen.children["Active Task start date"],
                Airtabledata.Operational.children.Personen.children["Active task end date"],
                Airtabledata.Operational.children.Personen.children["Active Task type"],
                Airtabledata.Operational.children.Personen.children["Active Task part unit"],
                Airtabledata.Operational.children.Personen.children["Active Task onderdeel group"],
                Airtabledata.Operational.children.Personen.children.Naam,
                Airtabledata.Operational.children.Personen.children["Draaidag tasks this week"],
                Airtabledata.Operational.children.Personen.children["Draaidag tasks startdate"],
                Airtabledata.Operational.children.Personen.children["Draaidag tasks enddate"],
                Airtabledata.Operational.children.Personen.children["Draaidag tasks project"],
                Airtabledata.Operational.children.Personen.children["Draaidag task recID"],
                Airtabledata.Operational.children.Personen.children["Draaidag task deliverable"],
                Airtabledata.Operational.children.Personen.children["Project Managment Part"]
            ];
            const fieldsQueryString = fieldsToRetrieve.join('&fields%5B%5D=');
    
            const query = encodeURIComponent(`${personenData.name}?filterByFormula=%7B${personenData.children.Email}%7D+%3D+%22${email}%22&fields%5B%5D=${fieldsQueryString}`)

            const response = await axios.get(
                `${url_self}/Api/OperationalTable/${query}`,
            );

            return response.data.records[0];
        } catch (err) {
            console.log(err);
            return err;
        }
    }


    /**
     * get all projects that are not archived
     * @param {*} projectsData 
     * @returns 
     */
    async GetAllProjectsBetter(offset,projectsData){
        try{
            const fieldsToRetrieve=[
                Airtabledata.Operational.children.Projecten.children.Status.name,                
                Airtabledata.Operational.children.Projecten.children["Taaktype offerteRegel"],
                Airtabledata.Operational.children.Projecten.children["Projectnr_en_naam"],
                Airtabledata.Operational.children.Projecten.children["Projectnummer"]               
            ]

            const fieldsQueryString = fieldsToRetrieve.join('&fields%5B%5D=');

            const query = encodeURIComponent(`${projectsData.name}?&fields%5B%5D=${fieldsQueryString}&filterByFormula=AND({${projectsData.children["Status"].name}},NOT({${projectsData.children["Status"].name}} = "${projectsData.children["Status"].children["Gearchiveerd"]}", {${projectsData.children["Status"].name}} = ""))`)

            const response = await axios.get(
                `${url_self}/Api/OperationalTable/${query}`,
                {params:{offset:offset, sort:[{field:"Projectnummer", direction: "desc"}]}})

            return response
        }catch(err){
            console.log(err)
            return err
        }
    }
    
    /**
     * Get all projects that are ongoing
     * @param {*} offset 
     * @param {*} projectsData 
     * @returns 
     */
    async GetAllActiveProjectsBetter(offset,projectsData){
        try{
            const fieldsToRetrieve=[
                Airtabledata.Operational.children.Projecten.children.Status.name,        
                Airtabledata.Operational.children.Projecten.children["Taaktype offerteRegel"],
                Airtabledata.Operational.children.Projecten.children["Projectnr_en_naam"],
                Airtabledata.Operational.children.Projecten.children["Projectnummer"]    
            ]

            const fieldsQueryString = fieldsToRetrieve.join('&fields%5B%5D=');

            const query = encodeURIComponent(`${projectsData.name}?&fields%5B%5D=${fieldsQueryString}&filterByFormula={${projectsData.children["Status"].name}} = "${projectsData.children["Status"].children["In progress"]}"`)

            const response = await axios.get(
                `${url_self}/Api/OperationalTable/${query}`,
                {params:{offset:offset, sort:[{field:"Projectnummer", direction: "desc"}]}})

            return response
        }catch(err){
            console.log(err)

            return err
        }
    }

    /**
     * Adds part to user
     * @param {*} part 
     * @param {*} user 
     * @param {*} personenData 
     * @returns 
     */
    async AddParts(part, user, personenData) {
        try {
            const response = await axios.post(`${url_self}/Api/AddParts`, {
                part,
                user,
                personenData
            });
    
            return response.status;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

   
    /**
     * removes part from user
     * @param {*} Part 
     * @param {*} user 
     * @param {*} personenData 
     * @returns 
     */
    async RemovePart(Part, user, personenData){
        console.log('RemovePart', Part, user, personenData);
        try {
            const response = await axios.post(`${url_self}/Api/RemoveParts`, {
                Part,
                user,
                personenData
            });
    
            return response.data.status;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

 
    /**
     * get all parts from project
     * @param {*} projectNumber 
     * @param {*} partData 
     * @returns 
     */
    async GetPartsFromProject(projectNumber, partData) {
        try {
            const query = encodeURIComponent(`${partData.name}?filterByFormula=AND({${partData.children["Projectnummer (from Projecten)"]}} = "${projectNumber}", {${partData.children["hideForTimeWriting"].name}} = "false")`);
            console.log('Making API Call to:', `${url_self}/Api/OperationalTable/${query}`);
            const response = await axios.get(`${url_self}/Api/OperationalTable/${query}`);
            console.log('API Response:', response.data);
            return response.data.records;
        } catch (err) {
            console.error('Error fetching parts:', err);
            return err;
        }
    }
    

}




