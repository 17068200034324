import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../../styles/UrenVerantwoording/App.css"

/**
 * Dialog used to warn user about unpinning a part
 * @param {*} param0 
 * @returns 
 */
export default function AlertDialog({open, onClose, handleRemove, task}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  /**
   * Closes the dialog and removes the pinned part
   */
  function handleClick(){
    onClose()
    handleRemove()
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {task && (<div> Dit onderdeel kan niet verwijderd worden omdat je er een taak bij hebt</div>)}
          {!task && ("Weet je zeker dat je dit onderdeel weg wilt halen")}
        </DialogTitle>
        <DialogActions>
            <div className='alertaction'>
              {!task&&(               
              <Button onClick={handleClick} autoFocus>
                    Akkoord
                </Button>)}
                <Button onClick={onClose} autoFocus>
                    Sluiten
                </Button>
            </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
