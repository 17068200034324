import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
/**
 * gives the tooltip containing info from the part
 * @param {*} param0 
 * @returns 
 */
const ExtraInfo = ({Info}) => 
{

    return (
        <>
        <Tooltip title={Info}>
            <HelpOutlineIcon sx={{fontSize:"1rem"}}/>
        </Tooltip>

        </>
    )
}

export default ExtraInfo;
