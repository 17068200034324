import { OPERATIONAL_CRUD } from "./OperationalTable";
import { UREN_CRUD } from "./UrenTable";
import CreateDataStructure from "../CreateDataStructure"
import { sortStructure } from "../SortStructure";
import AirtableData from './AirtableData.json'


// const headerData = await axios.get(`http://localhost:5000/UrenVerantwoording`)
// console.log(headerData.data)



/***
 * Bussines layer of app. Sends all request further
 */
export class AirTableHourClass{ 


    
    constructor(){
        this._Operational = new OPERATIONAL_CRUD()
        this._Uren = new UREN_CRUD()
        this.APIRequests = 0
        this.requestQueue=[]
    }



/***
 * Retrieves all relevant information about a user from the operational base for a given week.
 * This includes fetching active parts associated with the user and their weekly hours.
 * @param {string} email - The email address of the user to identify the specific user record.
 * @param {Object} week - An object representing the current week, used to fetch weekly hours.
 * @returns {Array} - An array containing the sorted structure of user data and the original result.
 */
async GetPart(email, week){ 
    // Start tracking the request for performance or debugging
    await this.StartRequest()

    try {
        // Fetch active parts associated with the user's email
        const result = await this._Operational.GetActivePart(email, AirtableData.Operational.children.Personen)
        
        // Fetch weekly hours for the user
        await this._Uren.GetWeekHours(email, week)

        // Structure the fetched data into a more usable format, integrating weekly hours
        const resultStructure = await this.StructureData(result, week, email, this._Uren.hours)

        let sortedStructure

        // Ensure the structured data is in array format and sort it
        if (resultStructure instanceof Array) {
            sortedStructure = await sortStructure(resultStructure[0])
        } else {
            sortedStructure = await sortStructure(resultStructure)
        }

        // Return an array with the sorted structure and the raw result
        return [sortedStructure, result]
    } catch (err) {
        // Log errors to console for debugging
        console.log(err)
        
        // Handle rate limiting specific errors by retrying the request after a pause
        if (err.response?.status === 429) {
            await this.sleep()
            return this.GetPart(email, week)
        }
    } finally {
        // Mark the request as complete, potentially logging the end or freeing up resources
        this.CompleteRequest()
    }
}


    /**
     * Create a record in UrenLog table
     * @param {*} id Blue base record id of person
     * @param {*} day 
     * @param {*} value hours to put in
     * @param {*} weekStart 
     * @param {*} onderdeelAutoID 
     * @param {*} name 
     * @returns record id of created log
     */
    async CreateHourLog(id,day,value,weekStart, onderdeelAutoID,name ){
        const onderdeelID = await this.GetHourSyncPart(onderdeelAutoID)
        await this.StartRequest()
        try{
            const response = await this._Uren.CreateHourLog(id,day,value,weekStart, onderdeelID, AirtableData.Uren.children.UrenLog.name,name )
            return response
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.CreateHourLog(id,day,value,weekStart, onderdeelAutoID,name)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Create multiple logs of user
     * @param {*} email email of user
     * @param {*} weekStart 
     * @param {*} onderdeelID 
     * @param {*} offset offset given if more than 100 records
     * @param {*} allRecords total of records given if more than 100 records
     * @returns all records if there are more than 100
     */
    async GetAllHourLogsOfPart(email,weekStart,onderdeelID,offset, allRecords){
        await this.StartRequest()
        try{
            const res = await this._Uren.GetAllHourLogsOfPart(email,weekStart,onderdeelID, offset, AirtableData.Uren.children.UrenLog)
            const records = res.data.records
            allRecords.push(...records)
            if (res.data.offset) {
                offset = res.data.offset;
                this.GetAllHourLogsOfPart(email,weekStart,onderdeelID,offset, allRecords)
              } else {
                return allRecords
              }
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.GetAllHourLogsOfPart(email,weekStart,onderdeelID,offset, allRecords)
            }
        }finally{
            this.CompleteRequest()
        }

    }

    /**
     * Create a log in the bijzonder verlof table
     * @param {*} partName name of the special absence
     * @param {*} day 
     * @param {*} value 
     * @param {*} weekStart 
     * @param {*} id 
     * @param {*} userName 
     * @returns 
     */
    async CreateSpecialAbsenceLog(partName,day,value,weekStart,id,userName){
        await this.StartRequest()
        try{
            const res = await this._Uren.CreateSpecialAbsenceLog(AirtableData.Uren.children["Bijzonder verlof"].name,partName,day,value,weekStart,id,userName)
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.CreateSpecialAbsenceLog(partName,day,value,weekStart,id,userName)
            }
        }finally{
            this.CompleteRequest()
        }
    }
    /**
     * Updates the special absence log
     * @param {*} logID recID of the log
     * @param {*} day 
     * @param {*} value 
     * @returns 
     */
    async UpdateSpecialAbsenceLog(logID, day, value){
        await this.StartRequest()
        try{
            const res = await this._Uren.UpdateHourLog(logID,day,value,AirtableData.Uren.children["Bijzonder verlof"].name)
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.UpdateSpecialAbsenceLog(logID, day, value)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Changes all days of hour log
     * @param {*} monday 
     * @param {*} onderdeelAutoID 
     * @param {*} id 
     * @param {*} values Array of hours to change week in 
     * @param {*} name 
     * @returns 
     */
    async CreateHourLogWeek(monday,onderdeelAutoID,id,values,name){
        const onderdeelID = await this.GetHourSyncPart(onderdeelAutoID) 
        await this.StartRequest()
        try{
            await this._Uren.CreateHourLogWeek(monday,onderdeelID,id,values,AirtableData.Uren.children.UrenLog.name,name)
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.CreateHourLogWeek(monday,onderdeelAutoID,id,values,name)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Updates all days of an hour log
     * @param {*} logID recID of the log
     * @param {*} values array of hours to change
     * @returns 
     */
    async UpdateHourLogWeek(logID,values){
        await this.StartRequest()
        try{
            await this._Uren.UpdateHourLogWeek(logID,values,AirtableData.Uren.children.UrenLog.name)
        } catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.UpdateHourLogWeek(logID,values)
            }
        } finally{
            this.CompleteRequest()
        }
    }

    /**
     * Retrieves all projects from Airtable, including completed ones, handling pagination.
     * This method is designed to fetch projects in batches using Airtable's offset functionality.
     * 
     * @param {string} offset - A string used by Airtable for pagination, indicating where the next fetch should start.
     * @param {Array} allProjects - An array to accumulate all the fetched projects.
     * @returns {Array} - The complete list of projects accumulated over recursive calls if pagination is needed.
     */
    async GetAllProjects(offset, allProjects) {
        await this.StartRequest(); // Indicate the start of an API request
        
        try {
            // Make the API call to fetch projects, passing the current offset
            const res = await this._Operational.GetAllProjectsBetter(offset, AirtableData.Operational.children.Projecten);
            const records = res.data.records;
            allProjects.push(...records); // Add the fetched records to the allProjects array
            console.log(allProjects)
            if (res.data.offset) {
                // If there is an offset in the response, more records are available. Recursively fetch the next batch.
                return this.GetAllProjectsBetter(res.data.offset, allProjects);
            } else {
                // If there is no offset, all records have been fetched
                return allProjects;
            }
        } catch(err) {
            console.log(err);
            if (err.response && err.response.status === 429) {
                // Handle API rate limiting
                await this.sleep(); // Wait before retrying the request
                return this.GetAllProjectsBetter(offset, allProjects); // Retry the request
            }
        } finally {
            this.CompleteRequest(); // Indicate the end of an API request
        }
    }


    /**
     * Get all projects that are ongoing
     * @param {*} offset 
     * @param {*} allProjects 
     * @returns 
     */
    async GetAllActiveProjects(offset,allProjects){
        await this.StartRequest();
        try {
            const res = await this._Operational.GetAllActiveProjectsBetter(
                offset,
                AirtableData.Operational.children.Projecten
            );
            const records = res.data.records;
            allProjects.push(...records);
            if (res.data.offset) {
                offset = res.data.offset;
                return this.GetAllProjects(offset, allProjects);
            } else {
                return allProjects;
            }
        } catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.GetAllActiveProjects(offset,allProjects)
            }
        } finally {
            this.CompleteRequest();
        }
    }

    /**
     * update a single day in the hour log
     * @param {*} logID recID of log
     * @param {*} day 
     * @param {*} value 
     * @returns 
     */
    async UpdateHourLog(logID, day, value){
        await this.StartRequest()
        try{
            await this._Uren.UpdateHourLog(logID, day, value,AirtableData.Uren.children.UrenLog.name)
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.UpdateHourLog(logID, day, value)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Add part to user
     * @param {*} part part to add
     * @param {*} user recID green base user
     * @returns 
     */
    async AddPart(part, user) {
        const personenData = AirtableData.Operational.children.Personen
        await this.StartRequest();
        try {
            const res = await this._Operational.AddParts(part, user, personenData);
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.AddPart(part, user)
            }
        } finally {
            this.CompleteRequest();
        }
        }

    /**
     * removes linked part of user
     * @param {*} Part recID of part
     * @param {*} user recID of user
     * @returns 
     */
    async RemovePart(Part,user){
        await this.StartRequest()
        try{
            const res = await this._Operational.RemovePart(Part,user,AirtableData.Operational.children.Personen)
            console.log(res)
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.RemovePart(Part,user)
            }
        }finally{
            this.CompleteRequest()
        }
    }
   /**
 * Constructs a data structure for user information, integrating parts (offerteregels), tasks (projecten), and project assignments.
 * It also handles the initial loading and processing of all data related to parts and tasks.
 * @param {*} data - User information from the operational base.
 * @param {*} week - The current week for which data is being structured.
 * @param {*} email - User's email, used to fetch specific data.
 * @param {*} hoursParts - Hour logs for parts.
 * @returns {Object} - Structured data containing tasks and parts organized by project.
 */
async StructureData(data, week, email, hoursParts) {
    // Create a data structure from the user information
    const Structure = await CreateDataStructure(data, AirtableData.Operational.children.Personen, hoursParts, AirtableData.Uren.children.UrenLog.children);
    
    // If no structure could be created or if it's empty, return an empty array
    if (Structure === undefined || Structure.length === 0) return [];

    // Destructure the structured data into its components
    const [Tasks, Parts, Projects, PartsToAdd] = Structure;

    // Check if there are any parts that still need to be added to the user's project list
    if (PartsToAdd.length !== 0) {
        // Process the parts in chunks to manage large arrays or API request limits
        let chunkSize = 10;
        for (let index = 0; index < PartsToAdd.length; index += chunkSize) {
            const chunk = PartsToAdd.slice(index, index + chunkSize);
            // Add each chunk of parts
            await this.AddPart(chunk, [[], data]);
        }
        // After adding all parts, fetch and return the updated part information
        return this.GetPart(email, week);
    }

    // Map over each task to fetch and attach hours to them
    const taskPromises = Tasks.map(async (task) => {
        // Fetch the hours for each task for the specified week and email
        await this.StartRequest();
        try {
            const GetHours = await this._Uren.GetHours(email, task.ID, week);
            if (GetHours !== undefined) {
                task["hours"] = GetHours[0];
            }
        } catch (err) {
            console.log(err);
            // Handle specific API limit errors
            if (err.response?.status === 429) {
                await this.sleep();
                return this.CreateHourLog(email, week);
            }
        } finally {
            this.CompleteRequest();
        }

        // Fetch and attach the sum of hours to each task
        await this.StartRequest();
        try {
            const sumHours = await this._Uren.GetSumHours(task.ID);
            if (sumHours !== undefined) {
                task["sumHours"] = sumHours[0].fields.SumHours;
            }
        } finally {
            this.CompleteRequest();
        }
        return task;
    });

    // Await all the task updates and organize them by project name
    const result = {};
    const sortedTask = await Promise.all(taskPromises);
    sortedTask.forEach((task) => {
        if (result.hasOwnProperty(task.projectName)) {
            result[task.projectName].push(task);
        } else {
            result[task.projectName] = [task];
        }
    });

    // Repeat the process for parts, attaching hours and summing them
    const partPromises = Parts.map(async (part) => {
        // Fetch hours for each part
        await this.StartRequest();
        try {
            const GetHours = await this._Uren.GetHours(email, part.ID, week);
            if (GetHours !== undefined) {
                part["hours"] = GetHours[0];
            }
        } finally {
            this.CompleteRequest();
        }

        // Fetch and attach the sum of hours to each part
        await this.StartRequest();
        try {
            const sumHours = await this._Uren.GetSumHours(part.ID);
            if (sumHours !== undefined) {
                part['sumHours'] = sumHours[0].fields.SumHours;
            }
        } finally {
            this.CompleteRequest();
        }
        return part;
    });

    // Await all the part updates and organize them by project name
    const sortedResult = await Promise.all(partPromises);
    sortedResult.forEach((part) => {
        if (result.hasOwnProperty(part.projectName)) {
            result[part.projectName].push(part);
        } else {
            result[part.projectName] = [part];
        }
    });

    // Initialize projects in the result if they are undefined
    if (Projects === undefined) {
        return result;
    }
    Projects.forEach(project => {
        result[project.Name] = [];   
    });

    return result;
}

    /**
     * Updates a comment record in comment table
     * @param {*} logID recID of user
     * @param {*} content text of the comment
     * @returns 
     */
    async CreateComment(logID, content){
        await this.StartRequest()
        try{
            const res = await this._Uren.CreateComment(logID,content,AirtableData.Uren.children.UrenLog.name)
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.CreateComment(logID, content)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Create comment on part of the log if log does not exist
     * @param {*} content text of the comment
     * @param {*} id recID of the user
     * @param {*} onderdeelAutoID autoID of the part
     * @param {*} week 
     * @returns 
     */
    async CreateCommentLog(content, id, onderdeelAutoID, week){
        const onderdeelID = await this.GetHourSyncPart(onderdeelAutoID) 
        await this.StartRequest()
        try{
            const res = await this._Uren.CreateCommentLog(content, id, onderdeelID, week,AirtableData.Uren.children.UrenLog.name)
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.CreateCommentLog(content, id, onderdeelAutoID, week)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Get all part of a project
     * @param {*} projectNumber 
     * @returns 
     */
    async GetPartsFromProject(projectNumber){
        await this.StartRequest()
        try{
            const res = await this._Operational.GetPartsFromProject(projectNumber, AirtableData.Operational.children.Onderdeel)
            return res.records
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Retrieves the record ID (recID) of a specific part from the 'blue base' within Airtable.
     * This function is designed to fetch the recID based on the part's identifier, which is crucial
     * for operations that require referencing this specific record in subsequent API calls or internal logic.
     *
     * @param {string} onderdeelID The unique identifier for the part whose record ID needs to be fetched.
     * @returns {Promise<string>} The record ID of the part if successful.
     */
    async GetHourSyncPart(onderdeelID) {
        await this.StartRequest(); // Begin tracking the request, potentially for throttling or logging purposes.
        try {
            // Make an API call to fetch the part's details using its ID.
            const res = await this._Uren.GetHourSyncPart(onderdeelID, AirtableData.Uren.children.OfferteRegels);
            // Assuming the response contains at least one record, return the first record's ID.
            return res.data.records[0].id;
        } catch (err) {
            console.log(err); // Log any errors for debugging purposes.
            // If the error is due to reaching the API rate limit (429), pause execution and retry.
            if (err.response && err.response.status === 429) {
                await this.sleep();
                return this.GetHourSyncPart(onderdeelID);
            }
            throw err; // Rethrow the error if it's not a rate limiting issue.
        } finally {
            this.CompleteRequest(); // Signal that this request is complete, possibly ending tracking or logging.
        }
    }


    
    /**
     * Create week notition
     * @param {*} text content of notition
     * @param {*} week 
     * @param {*} id 
     * @returns 
     */
    async CreateNotition(text,week, id){
        await this.StartRequest()
        try{
            const res = this._Uren.CreateNotition(text,week,AirtableData.Uren.children["Week Notition"], id)
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.CreateNotition(text,week, id)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * gets notition of user
     * @param {*} week 
     * @param {*} email 
     * @returns 
     */
    async GetNotition(week,email){
        await this.StartRequest()
        try{
            const res = this._Uren.FindNotition(week,email,AirtableData.Uren.children["Week Notition"])
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.GetNotition(week,email)
            }
        }finally{
            this.CompleteRequest()
        }
    }
    /**
     * Change notition
     * @param {*} text 
     * @param {*} notitionID recID of notition
     * @returns 
     */
    async UpdateNotition(text,notitionID){
        await this.StartRequest()
        try{
            const res = this._Uren.UpdateNotition(notitionID, text, AirtableData.Uren.children["Week Notition"])
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.UpdateNotition(text,notitionID)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * Post request of absence to verlof verzoek table
     * @param {*} dates date of the request
     * @param {*} irregularDate date with differte hour than 8
     * @param {*} comment extra comment of the request
     * @param {*} deltaHours 
     * @param {*} id recID of blue base urer
     * @param {*} name 
     * @param {*} weekStart 
     * @returns 
     */
    async PostAbsence(dates,irregularDate,comment,deltaHours, id, name,weekStart){
        await this.StartRequest()
        try{
            const status = await this._Uren.PostAbsence(dates,irregularDate,comment,deltaHours, AirtableData.Uren.children.Verlof, id, name,weekStart)
            return status
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.PostAbsence(dates,irregularDate,comment,deltaHours, id, name,weekStart)
            }
        }finally{
            this.CompleteRequest()
        }
    }

    /**
     * get recID of user in blue base
     * @param {*} email 
     * @returns 
     */
    async GetHourBaseRecID(email){
        await this.StartRequest()
        try{
            const res = await this._Uren.GetRecID(email,AirtableData.Uren.children.Personen)
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.GetHourBaseRecID(email)
            }
        }
        finally{
            this.CompleteRequest()
        }
    }

    /**
     * get all hours of the week of a user from the bijzonder verlof base
     * @param {*} week 
     * @param {*} email 
     * @returns 
     */
    async getSpecialAbsenceHours(week,email){
        await this.StartRequest()
        try{
            const res =  await this._Uren.getSpecialAbsenceHours(week,email,AirtableData.Uren.children["Bijzonder verlof"])
            return res
        }catch(err){
            console.log(err)
            if(err.response.status === 429){
                await this.sleep()
                return this.getSpecialAbsenceHours(week,email)
            }
        }
        finally{
            this.CompleteRequest()
        }
    }

    /**
     * 
     * @returns a promise that resolves after 30000 ms
     */
    async sleep() {
        return new Promise(resolve => setTimeout(resolve, 30000));
    }

    /**
     * start of the request
     * counts ongoing request
     * if there are more than 5, put the request in a promise.
     * @returns 
     */
    async StartRequest() {
        return new Promise((resolve, reject) => {
          if (this.APIRequests >= 5) {
            this.requestQueue.push(resolve);
          } else {
            this.APIRequests++;
            resolve();
          }
        });
      }

      /**
       * removes a ongoing request
       * If there is a request in the request queue, perform it.
       */
      async CompleteRequest() {
        this.APIRequests--;
        if (this.requestQueue.length > 0) {
          const nextRequest = this.requestQueue.shift();
          this.APIRequests++;
          nextRequest();
      }
    }


}       