import { useEffect, useState, useRef } from "react";
import OutlookCalendar from "./OutlookCalendar";



/**
 * Resizeable container for the outlook calendar
 * @param {*} param0 
 * @returns 
 */
const OutlookCalendarContainer = ({token, display, close}) => 
{
    const ref = useRef(null);
    const refLeft = useRef(null);
    const refTop = useRef(null);
    const refBoth = useRef(null)

    const [width,setWidth] = useState(700)
    const [height, setHeight] = useState(450)

    /**
     * sets the eventlisteners to the sides of the container Code from https://www.youtube.com/watch?v=CgjFATXhxiE 
     */
    useEffect(() => {
        const resizeableEle = ref.current;
        const styles = window.getComputedStyle(resizeableEle);
        let width = parseInt(styles.width, 10);
        let height = parseInt(styles.height, 10);
        let x = 0;
        let y = 0;

        resizeableEle.style.height = "450px"
        resizeableEle.style.top = "300px";
        resizeableEle.style.left = "50px";

    
        // Top resize
        const onMouseMoveTopResize = (event) => {
          const dy = event.clientY - y;
          height = height - dy;
          setHeight(height)
          y = event.clientY;
          resizeableEle.style.height = `${height}px`;
        };
    
        const onMouseUpTopResize = (event) => {
          document.removeEventListener("mousemove", onMouseMoveTopResize);
        };
    
        const onMouseDownTopResize = (event) => {
          y = event.clientY;
          const styles = window.getComputedStyle(resizeableEle);
          resizeableEle.style.bottom = styles.bottom;
          resizeableEle.style.top = null;
          document.addEventListener("mousemove", onMouseMoveTopResize);
          document.addEventListener("mouseup", onMouseUpTopResize);
        };
    
        // Left resize
        const onMouseMoveLeftResize = (event) => {
          const dx = event.clientX - x;
          x = event.clientX;
          width = width - dx;
          setWidth(width)
          resizeableEle.style.width = `${width}px`;
        };
    
        const onMouseUpLeftResize = (event) => {
          document.removeEventListener("mousemove", onMouseMoveLeftResize);
        };
    
        const onMouseDownLeftResize = (event) => {
          x = event.clientX;
          resizeableEle.style.right = styles.right;
          resizeableEle.style.left = null;
          document.addEventListener("mousemove", onMouseMoveLeftResize);
          document.addEventListener("mouseup", onMouseUpLeftResize);
        };

        //both

        const onMouseMoveBothResize = (event) => {
          const dx = event.clientX - x;
          x = event.clientX;
          width = width - dx;
          setWidth(width)
          const dy = event.clientY - y;
          height = height - dy;
          setHeight(height)
          y = event.clientY;
          resizeableEle.style.height = `${height}px`;
          resizeableEle.style.width = `${width}px`;
        };
    
        const onMouseUpBothResize = (event) => {
          document.removeEventListener("mousemove", onMouseMoveBothResize);
        };
    
        const onMouseDownBothResize = (event) => {
          y = event.clientY;
          x = event.clientX;
          resizeableEle.style.right = styles.right;
          resizeableEle.style.left = null;
          resizeableEle.style.bottom = styles.bottom;
          resizeableEle.style.top = null;
          document.addEventListener("mousemove", onMouseMoveBothResize);
          document.addEventListener("mouseup", onMouseUpBothResize);
        };

    
        // Add mouse down event listener
        const resizerTop = refTop.current;
        resizerTop.addEventListener("mousedown", onMouseDownTopResize);
        const resizerLeft = refLeft.current;
        resizerLeft.addEventListener("mousedown", onMouseDownLeftResize);
        const resizerBoth = refBoth.current;
        resizerBoth.addEventListener("mousedown", onMouseDownBothResize);

    
        return () => {
          resizerTop.removeEventListener("mousedown", onMouseDownTopResize);
          resizerLeft.removeEventListener("mousedown", onMouseDownLeftResize);
          resizerBoth.removeEventListener("mousedown", onMouseDownBothResize);
        };
      }, []);

    return (
        <div className={`container-outlook ${display? '' : 'invis'}`}>
            <div ref={ref}className="resizeable">
                <div ref={refLeft} className="resizer resizer-l"></div>
                <div ref={refTop} className="resizer resizer-t">
                  <div className="calendar-exit" onClick={close}>x</div>
                </div>
                <div ref={refBoth} className="resizer resizer-both"></div>
                
                <OutlookCalendar token={token} width={width} height={height}/>
            </div>
        </div>
    )
}

export default OutlookCalendarContainer;
