import { Paper } from "@mui/material";
import "../styles/App.css";
import AddTaskIcon from '@mui/icons-material/AddTask';
import BookIcon from '@mui/icons-material/Book';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Link } from "react-router-dom";

const menuItems = [
  { to: "MaterialReservation", title: "Materialen Reservatie", Icon: BookIcon },
  { to: "AddStandardTask", title: "Add Standard Task", Icon: AddTaskIcon },
  { to: "UrenVerantwoording", title: "Uren Registratie", Icon: ScheduleIcon },
  { to: "SRTomzettenFCP", title: "SRT omzetten voor FCP", Icon: ScheduleIcon }
];
console.log('test');


export function HomePortal() {
  return (
    <div className="portal">
      {menuItems.map(({ to, title, Icon }) => (
        <Link to={to} style={{ color: 'inherit', textDecoration: 'none' }}>
          <Paper className="big-paper" elevation={7}>
            <h1>{title}</h1>
            <div className="portal-icon-container">
              <Icon className="portal-icon" />
            </div>
          </Paper>
        </Link>
      ))}
    </div>
  );
}
