import {CircularProgress} from "@mui/material"
/**
 * loading element in the middle 
 * @returns 
 */
const Loading = () => 
{
    return (
        <div className="loading">
                <CircularProgress />
        </div>
    )
}

export default Loading;
