import { AppContent } from "../../ui-components/MateriaalReserving/MaterialReservationApp";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import "../../styles/App.css"


export function MaterialReservation() {
  const { instance } = useMsal();
  
  function handleLogin(){
        instance.loginRedirect({...loginRequest,
        redirectUri:process.env.REACT_APP_POPUP_REDIRECT_URI
        });
    }

  return (
      <>
          <AuthenticatedTemplate>
            <AppContent />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="login-text">
              <Button  onClick={handleLogin}  style={{fontSize:"2rem"}}>
                <center>Graag inloggen.</center>
                </Button>
            </div>

          </UnauthenticatedTemplate>
      </>
  );
}

