import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import {Hours} from './HoursAccountability'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import Button from "@mui/material/Button";
import { InteractionRequiredAuthError } from "@azure/msal-browser";


/**
 * Wraps the whole hour registration in an authentication template
 * @returns 
 */
export function StartHours() {
  const { instance } = useMsal();
  
  function handleLogin() {
    instance.acquireTokenSilent(loginRequest)
        .then(response => {
            console.log("Silent token acquisition succeeded.");
        })
        .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                // Fallback to interactive method when silent request fails
                instance.loginRedirect({
                    ...loginRequest,
                    redirectUri: process.env.REACT_APP_REDIRECT_URI
                });
            } else {
                console.error("Non-interaction error:", error);
            }
        });
  }



  return (
      <>
          <AuthenticatedTemplate>
            <Hours />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="login-text">
              <Button  onClick={handleLogin}  style={{fontSize:"2rem"}}>
                <center>Graag inloggen.</center>
                </Button>
            </div>

          </UnauthenticatedTemplate>
      </>
  );
}
