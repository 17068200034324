//Importing style 
import "../../styles/UrenVerantwoording/App.css"
//Import JSON
import Airtabledata from "../../utils/UrenVerantwoording/API/AirtableData.json"
//Importing UI
import { Paper, Typography, Button,Collapse, List, ListItem } from "@mui/material";
import Loading from "../../ui-components/Urenverantwoording/MiscComp/LoadingScreen";
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarContainer from "../../ui-components/Urenverantwoording/MiscComp/CalendarContainer";
import AddIcon from '@mui/icons-material/Add';
import PartDialogAllProjects from "../../ui-components/Urenverantwoording/Dialog/AddPartDialogAllProjects";
import AbsenceDialog from "../../ui-components/Urenverantwoording/Dialog/AbsenceDialog";
import OutlookDialog from "../../ui-components/Urenverantwoording/Dialog/OutlookDialog";
import CommentIcon from '@mui/icons-material/Comment';
import MedicationIcon from '@mui/icons-material/Medication';
import NotitionDialog from "../../ui-components/Urenverantwoording/Dialog/NotitionDialog";
import GeneralSnackBar from "../../ui-components/Urenverantwoording/MiscComp/GeneralSnackBar.jsx";
import RemoveIcon from '@mui/icons-material/Remove';
//Importing Function
import { useEffect, useRef, useState } from "react";
import { AirTableHourClass } from "../../utils/UrenVerantwoording/API/AirHour"
import { useMsal } from "@azure/msal-react";
import { generateWeekDates, getCurrentWeekDate, Formatedtext, nextWeek, prevWeek, getWeekDate, getMondayBetweenDates } from "../../utils/UrenVerantwoording/getDate";
import dayjs, {Dayjs} from "dayjs";
import { loginRequest } from "../../authConfig";
import AddHours  from "../../ui-components/Urenverantwoording/InterfaceStructure/AddProjects.jsx"
import ProjectPartDialog from "../../ui-components/Urenverantwoording/Dialog/ProjectPartDialog";
import returnNewStructure from "../../utils/UrenVerantwoording/AddPartToStructure";
import { sortStructure } from "../../utils/UrenVerantwoording/SortStructure";
import FindPart from "../../utils/UrenVerantwoording/FindPartInStructure.js";
import AttachDraaidag from "../../utils/UrenVerantwoording/AttachDraaidagTask.js";
import SearchBar from "../../ui-components/Urenverantwoording/MiscComp/SearchBar.jsx";
import { useNavigate } from "react-router-dom";
import SpecialAbsence from "../../ui-components/Urenverantwoording/InterfaceStructure/SpecialAbsence.jsx";

dayjs.locale({
  ...require('dayjs/locale/en'), 
  weekStart: 1, 
});

//initializing class object
const _Airtable = new AirTableHourClass()
/**
 *  Main hub and start place for everything hour registration related. All API request start here
 * @returns 
 */
export function Hours() {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount().username.replace(/-/g, ""); 
    
    const [loading, setLoading] = useState(true);
    const [partInfo,setPartInfo] = useState([0,0])
    const [userInfo, setUserInfo] = useState({
      fields:{
        Functie:""
      }
    })
    const [week, setweek] = useState(null)
    const [calendarDate, setCalendarDate] = useState();
    const [dateText,setDateText] = useState()
    const [allProjects, setAllProjects] = useState([])
    const [userToken,setUserToken] = useState()
    const [mobileActive,setMobileActive] = useState(0)
    const [selectedProject,setSelectedProject ] = useState()
    const [selectedProjectValues, setSelectedProjectValues] = useState(['temp'])
    const [filterCheckProjectDialog, setFilterCheckProjectDialog] = useState(true)
    const [filterCheckPartDialog, setFilterCheckPartDialog] = useState(true)
    const [notition, setNotition] = useState({id:null,note:""})
    const [needsDraaidagTask , setNeedsDraaidagTask] = useState(false)
    const [searchQuery,setSearchQuery] = useState(String)
    const [hasFetched, setHasFetched] = useState(0)
    const [saldo , setSaldo] = useState(0)

    const [snackbarData,setSnackbarData] = useState({open : false, text:'', type:''})
    const [openPartDialog,setOpenPartDialog] = useState(false)
    const [openAbsenceDialog,setOpenAbsenceDialog] = useState(false)
    const [openProjectPartDialog,setOpenProjectPartDialog] = useState(false)
    const [openNotitionDialog,setOpenNotitionDialog] = useState(false)
    const [changedHours, setChangedHours] = useState({
      "ma":-1,
      "di":-1,
      "wo":-1,
      "do":-1,
      "vr":-1,
      "za":-1,
      "zo":-1
    }
  )

    const [DailyHours, setDailyHours] = useState({
      "ma":0,
      "di":0,
      "wo":0,
      "do":0,
      "vr":0,
      "za":0,
      "zo":0
    }
  )

    const [openCollapse, setOpenCollapse] = useState(false)
    const [specialAbsence,setSpecialAbsence] = useState(false)

    const hamburger = useRef(null)
    const mobileMenu = useRef(null)
    const nav = useRef(null)
    const navigate = useNavigate()

    /**
     * Function to change the use state of the project part dialog
     */
    function HandleClose(){
      setOpenPartDialog(false)
    }
    /**
     * Checks if the user is a user that needs to have a draaidag thats is attached to an part
     */
    useEffect(()=>{
      const functionName = Airtabledata.Operational.children.Personen.children.Functie
      const draaidagTaskName = Airtabledata.Operational.children.Personen.children["Draaidag tasks this week"]
      if(userInfo.fields[functionName] === "") return

      if(userInfo.fields[functionName].some(role => ["Cameraman" , "Regisseur" , "Stagiair"].includes(role)) && userInfo.fields[draaidagTaskName] !== undefined ){ // Zet funcaties hier bij als het een functie die vaak op een draaidag zit en een eigen offerte regel heeft 
        setNeedsDraaidagTask(true)
      } 
    },[userInfo])


    /**
     * Create and sets current week data, only needed once at the begining
     */
    useEffect(() => {
      if (week === null) {
          setweek(generateWeekDates(getCurrentWeekDate()));
      }
    }, []);

    /**
     * Gets the all the info about the user based on their operational base row
     */
    useEffect(() => {
      const emailName = Airtabledata.Operational.children.Personen.children.Email
      async function fetchProjects() {
        try {
          if (week) {
            const data = await _Airtable.GetPart(activeAccount.replace(/-/g, ""), week);
            if (data === undefined) return;
            // Ensure the component is still mounted before updating state
              setPartInfo(data[0]);
    
              // Some emails in airtable are without a - in the middle
              data[1].fields[emailName] = data[1].fields[emailName].replace(/-/g, "");
              setUserInfo(data[1]);
    
              setLoading(false);
              
              //track to make sure the draaidag attach happens after this
              setHasFetched((pastfetched) => pastfetched + 1);
            
          }
        } catch (error) {
          console.error(error);
        } 
      }
    
      fetchProjects();
    }, [week]);

    useEffect(()=>{
      setSpecialAbsence(false)
    },[week])

    /**
     * Get the hourbase rec of the user to use later
     */
    useEffect(()=>{
      const emailName = Airtabledata.Operational.children.Personen.children.Email
      const verlofUrenName = Airtabledata.Uren.children.Personen.children["Aantal verlofuren over"]
      async function fetchRec(){
        try{
          const rec = await _Airtable.GetHourBaseRecID(userInfo.fields[emailName])
          const User = userInfo
          User["hourRecId"] =  rec.id
          setSaldo(rec.fields[verlofUrenName])
          setUserInfo(User)
        }catch(err){
          console.error(err)
        }
      }
      if(userInfo.fields[emailName] !== undefined){
        fetchRec()
      }
    },[userInfo])
  
  
  /**
   * Get an access token used to get events from the calendar
   
  useEffect(()=>{
    if (accounts.length === 0) {
      // gebruiker moet inloggen
      navigate("/")
    } else {
      // Get an access token
      try{
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        }).then((response) => {
          const accessToken = response.accessToken;
          setUserToken(accessToken)
        })
      }catch(err){
        setUserToken()
      }
}
  },[accounts,instance])
*/
    /**
     * Get all projects that are still in progress for the project part dialog
     */
    useEffect(()=>{
      const projects = _Airtable.GetAllActiveProjects('',[])
      setAllProjects(projects)
    },[])

    /**
     * Sets the text of the current week
     */
    useEffect(()=>{
      if(week){
        setDateText(Formatedtext(week.weekRowDate[0],week.weekRowDate[4]))
      }
    },[week])

    /**
     * Reset Changed hours every week
     */
    useEffect(()=>{
      setChangedHours({
        "ma":-1,
        "di":-1,
        "wo":-1,
        "do":-1,
        "vr":-1,
        "za":-1,
        "zo":-1
      })
    },[week])

  /**
   * UseEffect to calculalte sum hour of the days based on the initial value taken from Airtable
   */
    useEffect(()=>{
      let DailyHoursValues = 
      {
        "ma":[0],
        "di":[0],
        "wo":[0],
        "do":[0],
        "vr":[0],
        "za":[0],
        "zo":[0]
      }
      //Info of the parts need to be available
      if(partInfo === null) return
      if(partInfo === undefined) return
      const Keys = Object.keys(partInfo)
      //If the user has no parts there no need to continue the function
      if (Keys.length === 0 || Number(Keys[0]) === 0){
        return
      }
      Keys.forEach((project,i)=>{
        partInfo[project].forEach(part => {
          // no hours written on the part
          if(part.hours === undefined){
            return
          }
              if (part.hours.fields.Maandag !== undefined) DailyHoursValues.ma.push(part.hours.fields.Maandag)
              if (part.hours.fields.Dinsdag!== undefined) DailyHoursValues.di.push(part.hours.fields.Dinsdag)
              if (part.hours.fields.Woensdag!== undefined) DailyHoursValues.wo.push(part.hours.fields.Woensdag)
              if (part.hours.fields.Donderdag!== undefined) DailyHoursValues.do.push(part.hours.fields.Donderdag)
              if (part.hours.fields.Vrijdag!== undefined) DailyHoursValues.vr.push(part.hours.fields.Vrijdag)
              if (part.hours.fields.Zaterdag!== undefined) DailyHoursValues.za.push(part.hours.fields.Zaterdag)
              if (part.hours.fields.Zondag!== undefined) DailyHoursValues.zo.push(part.hours.fields.Zondag)
        });
      })
      const DailyHourSom = Object.keys(DailyHoursValues).reduce((result, day) => {
        result[day] = DailyHoursValues[day].reduce((a, b) => a + b, 0);
        return result;
      }, {});

      Object.keys(changedHours).forEach((day)=>{
        if(changedHours[day] !== -1){
          DailyHourSom[day] = changedHours[day]
        }
      })

      setDailyHours(DailyHourSom)
    },[partInfo,week])
    

    /**
     * Create the current date
     * */ 
    useEffect(()=>{
      if(week){
        setCalendarDate(dayjs(week.weekRowDate[0]))
      }
    },[week])

    /**
     * fetch the notition of the week if available
     */
    useEffect(()=>{
      if(week){
        const emailName = Airtabledata.Operational.children.Personen.children.Email
        const noteName = Airtabledata.Uren.children["Week Notition"].children.Notes
        setNotition({id:null,note:""})
        const note = _Airtable.GetNotition(week,userInfo.fields[emailName])
        if(note instanceof Promise){
          note.then((resolvedNote)=>{
            if(resolvedNote.records.length === 0) return 
            setNotition({id:resolvedNote.records[0].id, note:resolvedNote.records[0].fields[noteName]})
          })
        }
      }
    },[week, userInfo])

    /**
     * Select the parts of a project if one has been selected.
     */
    useEffect(()=>{
      if(selectedProject === undefined) return
      async function fetchParts(){
        try{
          const res = await _Airtable.GetPartsFromProject(selectedProject, AirtableData.Operational.children.Onderdeel)
          console.log('SelectedProjectValues',res)
          setSelectedProjectValues(res)
        }catch(err){
          console.error(err)
        }
      }
      fetchParts()
    },[selectedProject])

    /**
     * If the values have been fetched the dialog of the parts will open
     */
    useEffect(()=>{
      if (selectedProjectValues[0] === "temp") {
        return
      }
      setOpenProjectPartDialog(true)
    },[selectedProjectValues])
    
    /**
     * Change has been made to the hour log
     * @param {RecID} logID 
     * @param {ISOformat} day 
     * @param {number} value 
     */
    function UpdateHourLog(logID,day,value, Name, onderdeelID){
      if(onderdeelID === "verlof" ){
        return _Airtable.UpdateSpecialAbsenceLog(logID,day,value)
      }
      _Airtable.UpdateHourLog(logID,day,value)
    }

    /**
     * Using a session storage to remember what the original start week is.
     */
    useEffect(()=>{ 
        sessionStorage.setItem("Currentweek",getCurrentWeekDate())
    },[])
    
    /**
     * When we know we have Draaidag tasks this function wil start and it wil create a 
     * new structure where the new tasks attached with a part have been added
     */
    useEffect(()=>{
      if(needsDraaidagTask){
        //sets the task to the structure
        const tasks = []
        const personData = Airtabledata.Operational.children.Personen

        // Loop through all the draaidag tasks and add them to the tasks array
        userInfo.fields[personData.children["Draaidag tasks this week"]].forEach((draaidag,index) => {
            tasks.push(
              {
                id : userInfo.fields[personData.children["Draaidag task recID"]][index],
                task :userInfo.fields[personData.children["Draaidag tasks this week"]][index] ,
                deliverable : userInfo.fields[personData.children["Draaidag task deliverable"]][index],
                type: "Draaidag",
                startDate: userInfo.fields[personData.children["Draaidag tasks startdate"]][index],
                endDate : userInfo.fields[personData.children["Draaidag tasks enddate"]][index],
                project : userInfo.fields[personData.children["Draaidag tasks project"]][index]
              }
            )
        });

        /**
         * Async method to add new part or add to existing part
         * @returns  Strucuture with added Draaidag Task and accompanying part
         */
        async function newStructure(){
          const emailName = Airtabledata.Operational.children.Personen.children.Email
          const functionName = Airtabledata.Operational.children.Personen.children.Functie

          const newStructure = await AttachDraaidag(_Airtable,partInfo,userInfo.fields[functionName],tasks, week,userInfo.id, userInfo.fields[emailName])
          if(newStructure === undefined) return
          const update = {...newStructure} ;
          const sortedUpdate = await sortStructure(update)
          setPartInfo(sortedUpdate)
        }
        newStructure()
      }
    },[needsDraaidagTask, hasFetched])
    /**
     * 
     * @param {ISOformat} day 
     * @param {number} value 
     * @param {ISOformat} weekStart 
     * @param {RecID} onderdeelID 
     * @returns 
     */
    async function  CreateHourLog(day,value,weekStart, onderdeelID, onderdeelName){
      if(onderdeelID === "verlof" ){
        return await CreateSpecialAbsenceLog(day,value,weekStart,onderdeelName)
      }
      const CreatedID = await _Airtable.CreateHourLog(userInfo.hourRecId,day,value,weekStart, onderdeelID, userInfo.fields[Airtabledata.Operational.children.Personen.children.Naam])
      return CreatedID
    }

    async function CreateSpecialAbsenceLog(day,value,weekStart,onderdeelName){
      const CreatedID = await _Airtable.CreateSpecialAbsenceLog(onderdeelName,day,value,weekStart,userInfo.hourRecId,userInfo.fields[Airtabledata.Operational.children.Personen.children.Naam])
      return CreatedID
    }

    /**
     * Function to create multiple hour logs depending on the end week
     * @param {dayjs} week The chosen end week
     * @param {String} weekStart The monday of the current chosen week
     * @param {RecID} onderdeelID 
     * @param {Array} values 
     */
    async function CreateHourLogWeek(week,weekStart,onderdeelID, values){
      const emailName = Airtabledata.Operational.children.Personen.children.Email
      const naamName = Airtabledata.Operational.children.Personen.children.Naam

      const mondays = getMondayBetweenDates(new Date(weekStart),week.toDate())
      //Check if theres overlap with existing hours
      const allHoursLogs = await _Airtable.GetAllHourLogsOfPart(userInfo.fields[emailName],weekStart,onderdeelID,'',[])

      allHoursLogs.splice(0,1)
      let delta = 0
      try{
        delta =  mondays.length - allHoursLogs.length 

      }catch{
        delta = -1
      }
      //If there is overlap, update those hour logs first else just create all the hour logs
      if(delta > 0){
        for (let index = 0; index < allHoursLogs.length; index++) {
          await _Airtable.UpdateHourLogWeek(allHoursLogs[index].id,values)
        }
        for (let index = allHoursLogs.length; index < mondays.length; index++) {
          const element = mondays[index];
          await _Airtable.CreateHourLogWeek(element,onderdeelID,userInfo.hourRecId,values,userInfo.fields[naamName])
        }
      }else{
        mondays.forEach(async (monday) => {
          await _Airtable.CreateHourLogWeek(monday,onderdeelID,userInfo.hourRecId,values,userInfo.fields[naamName])
        });
      }
    }

    /**
     * Use stored original week to return
     */
    function Handlereturn(){
      const date = sessionStorage.getItem("Currentweek")
      setLoading(true)
      setweek(generateWeekDates(new Date(date)))
    }

    /**
     * depending on parameter go a week further or a week back
     * @param {string} data Given in the buttons 
     */
    function handleDateClick(data){
      let newWeek
      if(data === "forward"){
        newWeek = nextWeek(week.weekRowDate[0])
      }else{
        newWeek = prevWeek(week.weekRowDate[0])
      }
      setLoading(true)
      setweek(newWeek)
    }

  /**
   * Use selected date to go to that week 
   * @param {*} date Comes from the calendar
   */
    function handleCalendarDate(date){
      setCalendarDate(dayjs(date));
      const dateObject = date.toDate()
      const newWeekStartDate =getWeekDate(dateObject)
      const DateWeek = new Date(newWeekStartDate)
      setLoading(true)
      setweek(generateWeekDates(DateWeek))
    }

    /**
     * Grab the first four numbers and use that to set selectedproject
     * @param {string} project 
     */
    function handleProjectRowAdd(project){ 
      const projectNumber = project.split('.')
      if(projectNumber[0] !== selectedProject){
        setSelectedProject(projectNumber[0])
        return
      }else{
        setOpenProjectPartDialog(true)
      }
    }

    /**
     * Creates the comment of the part based on the given parameters
     * @param {RecID} logID RecID of the hour base if empty create new log  
     * @param {String} comment 
     * @param {RecID} onderdeelID RecID of the part. Only needed if there is no logID 
     */
    function createComment(logID,comment, onderdeelID){
      if(logID !== null){
        _Airtable.CreateComment(logID,comment)
      }else{
        _Airtable.CreateCommentLog(comment, userInfo.hourRecId, onderdeelID, week)
      }

    }

   /**
    * Pin a part to stay 
    * @param {*} part 
    */
    async function handlePartAdd(part){
      const res = await _Airtable.AddPart(part, [{...partInfo},{...userInfo}], week)
      if(res === 200){
        const foundPart  = FindPart(partInfo,part, true) // This does nothing but without it the part will not show as pinned in task row. Maybe something to do with timing? 
        setSnackbarData({open:true,text:"Onderdeel is gepinned",type:"success"})
      }
    }

    /**
     * Temporarily adds a part to the current interface
     * @param {*} checkedPart Chosen part from project part dialog
     */
    async function handlePartAddTemp(checkedPart){
      const emailName = Airtabledata.Operational.children.Personen.children.Email

      if(checkedPart.length > 0){
        const res = await returnNewStructure(checkedPart, partInfo, userInfo.id, _Airtable, week,userInfo.fields[emailName])
        const update = {...res} ;
        const sortedUpdate = await sortStructure(update)
        setPartInfo(sortedUpdate)
        setSnackbarData({open:true,text:"Onderdeel is toegevoegd",type:"success"})
      }
    }

    /**
     * chooses based on the part if the parts needs to be pinned or unpinned
     * @param {*} part 
     */
    function handleRemovePart(part){
      if(part.Pinned === true){
        RemovePart(part)
      }else{
        handlePartAdd(part)
      }
    }

    /**
     * Unpins an part
     * @param {*} part 
     */
    async function RemovePart(part){
      const res = await _Airtable.RemovePart(part, userInfo)
      if(res.status === 200){
        const foundPart  = FindPart(partInfo,part, false) // This does nothing but without it the part will not show as unpinned in task row. I dont get react 
        setSnackbarData({open:true,text:"Onderdeel unpinned", type:"success"})
      }
    }

    /**
     * Changes the day of the mobile layout ma->di or ma<-di
     * @param {*} direction contains forward or else it will go back 
     * @returns if the day is monday or sunday the next or previous week will be returned
     */
    function handleMobileActive(direction){ 

      // get current day index
      const currentActive = nav.current.querySelector(".active-mobile")
      const currentId = currentActive.id
      const currentIndex = currentId.slice(9,10)
      let nextIndex = -2
      
      if(direction === "forward"){
        nextIndex = Number(currentIndex) +1
        if(nextIndex === 5) return handleDateClick("forward") // Go a week further       
      }else{
        nextIndex = Number(currentIndex) -1
        if (nextIndex === -1) return handleDateClick() // Go a week back
      }
      currentActive.className = currentActive.className.replace(/active-mobile/g,'')
      const nextActive = nav.current.querySelector(`#selector-${nextIndex.toString().slice(0,1)}`)
      nextActive.className = nextActive.className + "active-mobile"
      setMobileActive(nextIndex)
    }

    /**
     * Closes the absenceDialog
     */
    function HandleCloseAbsence(){
      setOpenAbsenceDialog(false)
    }

    /**
     * Closes the ProjectPartDialog
     */
    function HandleCloseProjectPart(){
      setOpenProjectPartDialog(false)
    }

    const handleCollapseToggle = () => {
      setOpenCollapse((prev) => !prev);
    };

    /**
     * Opens mobile menu 
     */
    const changeHamburger = () =>{ 
      const currentActive = nav.current.querySelector('.active-mobile');
      const dates = nav.current.querySelectorAll('.mobile-list-item')
      if(hamburger.current.className.includes("active")){
        hamburger.current.className = "hamburger"
        mobileMenu.current.className = "mobile-menu"
        currentActive.className =  currentActive.className.replace('invis', '')
        dates.forEach((ele)=>{
          ele.className = ele.className.replace('invis','')
        })        
      }
      else{
        hamburger.current.className = "hamburger active"
        mobileMenu.current.className = "mobile-menu active"
        currentActive.className = currentActive.className + ' invis'
        dates.forEach((ele)=>{
          ele.className = ele.className + ' invis'
        })
      }
    }

    /**
     * Decide if all project need to be fetched or just the active ones based on the filter
     */
    function olderProjectsPartDialog(){
      if(filterCheckProjectDialog){
        setAllProjects(_Airtable.GetAllProjects('',[]))
        setFilterCheckProjectDialog(false)
      }else{
        setAllProjects(_Airtable.GetAllActiveProjects('',[]))
        setFilterCheckProjectDialog(true)
      }
 
    }

    /**
     * Change state of filtercheck of projectPartDialog
     */
    function changePartDialogCheck(){
      setFilterCheckPartDialog(!filterCheckPartDialog)
    }

    /**
     * Search part of the project based on the given project number in projectpartdialog
     * @param {*} projectNumber 
     * @returns array of parts
     */
    async function searchParts(projectNumber){
      return await _Airtable.GetPartsFromProject(projectNumber)
    }

    /**
     *  Change of day value 
     * @param {*} daynumber index number of the day
     * @param {*} value total delta value
     */
    function handleInputRow(daynumber ,value){
      const updatedDailyHours = { ...DailyHours }; 
      const dayKey = Object.keys(DailyHours)
      
      updatedDailyHours[dayKey[daynumber]] -= Number(value);
      setDailyHours(updatedDailyHours)
      setChangedHours(updatedDailyHours)
    }
    /**
     * If text has been written set that in Airtable else close the Dialog
     * @param {*} Text 
     * @returns 
     */
    async function HandleCloseNotition(Text){
      if(Text instanceof Object) return setOpenNotitionDialog(false)
      if(Text === '') return setOpenNotitionDialog(false)
      let res
      if(notition.id !== null) {
        res = await _Airtable.UpdateNotition(Text,notition.id)
        setNotition({id:notition.id, note:Text})

      }else{
        res = await _Airtable.CreateNotition(Text,week, userInfo.hourRecId)
        setNotition({id:res.data.records[0].id, note:Text})
      }
      setOpenNotitionDialog(false)
      if(res === undefined) return
      if(res.status === 200) {
        setSnackbarData({open:true,text:"Notitie is opgeslagen", type:"success"})
      }else{
        setSnackbarData({open:true,text:"Iets is verkeerd gegaan", type:"warning"})
      }
      
    }
    /**
     * Send info of absence to Airtable
     * @param {String} dates 
     * @param {String} irregularDate 
     * @param {String} comment 
     * @param {*} deltaHours 
     */
    async function sendAbscence(dates,irregularDate,comment,deltaHours, weekStart){
      const naamName = Airtabledata.Operational.children.Personen.children.Naam

      const status = await _Airtable.PostAbsence(dates,irregularDate,comment,deltaHours, userInfo.hourRecId, userInfo.fields[naamName],weekStart)
      if(status === 200){
        setSnackbarData({open:true,text:"Verof verzoek is verzonden", type:"success"})
      }else{
        setSnackbarData({open:true,text:"Iets is verkeerd gegaan",type:"warning"})
      }
    }

    async function getSpecialAbsenceHours(week){
      return await _Airtable.getSpecialAbsenceHours(week,userInfo.fields[Airtabledata.Operational.children.Personen.children.Email])
    }

    if(loading) {return(<Loading />)}
    return (
      <div>
        <SpecialAbsence originalStructureObject={partInfo} setOriginalStructureObject={setPartInfo} shown={specialAbsence} userID={userInfo.hourRecId} getSpecialAbsenceHours={getSpecialAbsenceHours} week={week} TypeField={Airtabledata.Uren.children["Bijzonder verlof"].children.Type}/>


        <div className="cheat-navbar remove-mobile">
        <OutlookDialog token={userToken}/>
        <Button variant="outlined" onClick={() => setOpenAbsenceDialog(true)}><Typography>Verlof vragen</Typography></Button>
        </div>

        <Paper className ="content" elevation={20}>
            <div className="nav" ref={nav}>
              <div className="hamburger" ref={hamburger} onClick={changeHamburger}>
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
              </div>
                <div className="emptyblock">
                  <CalendarContainer calendarDate={calendarDate} handleCalendarDate={handleCalendarDate} dateText={dateText}/>
                  <div id="dateSub">
                  <IconButton className="arrowbutton" onClick={handleDateClick} >
                    <ArrowBackIosIcon className="Registration"  />
                  </IconButton>
                  <IconButton className="arrowbutton" onClick={() => handleDateClick("forward")}>
                    <ArrowForwardIosIcon className="Registration" />
                  </IconButton>
                  <Button onClick={Handlereturn}>Vandaag </ Button>
                  </div>
                </div>
                <div>
                  <div className="remove-mobile search-part">
                   <SearchBar onSearchChange={setSearchQuery}/>
                  </div>
                </div>
                {week && week.weekRowtext.map((day, i) =>
                    <div className={`${i===0? 'active-mobile' : ''} dayrow ${i === 6? 'last':''} `} key={i} id={`selector-${i}`}>
                            <div className="day-text">{day} {week.weekRowNumber[i]}</div><div className={`sumhour  ${DailyHours[day] < 9? "green":"red"}`}>{Math.round(Number(DailyHours[day]) * 100) / 100}u</div>
                    </div>
                )}
                <div className="collapse-Icon">
                        
                        <IconButton onClick={handleCollapseToggle}>
                        {
                          openCollapse &&
                            (<RemoveIcon />)
                        }  
                        {
                          !openCollapse &&
                            (<AddIcon />)
                        }
                        
                      </IconButton>
                      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <List sx={{zIndex : 99, border:"solid black", backgroundColor:"white" }}>
                          <ListItem >
                            <Button onClick={() => {setOpenPartDialog(true); handleCollapseToggle()}}>
                              <AddIcon />
                              <div className="collapse">Voeg toe</div>
                            </Button>
                          </ListItem>
                          <ListItem >
                            <Button onClick={() =>{ setOpenNotitionDialog(true); handleCollapseToggle()}}>
                              <CommentIcon className="Registration" />
                              <div className="collapse">Week notitie</div>
                            </Button>
                          </ListItem>
                          <ListItem >
                            <Button onClick={() =>{ setSpecialAbsence(true); handleCollapseToggle()}}>
                              <MedicationIcon className="Registration" />
                              <div className="collapse">Bijzonder verlof</div>
                            </Button>
                          </ListItem>
                        </List>
                      </Collapse>
                      </div>
                <div className="mobile-list-item"> 
                <div id="dateSub">
                  <IconButton className="arrowbutton"  onClick={handleMobileActive} sx={{padding: "5px"}}>
                    <ArrowBackIosIcon className="Registration" />
                  </IconButton>
                  <IconButton className="arrowbutton" onClick={() => handleMobileActive("forward")} sx={{padding: "5px"}}>
                    <ArrowForwardIosIcon  className="Registration"/>
                  </IconButton>
                  </div>
                </div>
              <div className="mobile-list-item">
                <Button onClick={Handlereturn}>Vandaag </ Button>
              </div>
              <PartDialogAllProjects _Airtable={_Airtable} open={openPartDialog} onClose={HandleClose} submit={handlePartAddTemp} role={userInfo.fields[Airtabledata.Operational.children.Personen.children.Functie]} email={activeAccount} projects={allProjects} searchParts={searchParts} filterCheck={filterCheckProjectDialog} olderProjects={olderProjectsPartDialog} structure={partInfo} pastActive={[userInfo.fields[Airtabledata.Operational.children.Personen.children["Active Task History Projects"]], userInfo.fields[Airtabledata.Operational.children.Personen.children["Active Task History Project Name"]]]} key={allProjects.length}/>
              <AbsenceDialog open={openAbsenceDialog} onClose = {HandleCloseAbsence} saldo={saldo} name={userInfo.fields[Airtabledata.Operational.children.Personen.children.Naam]} email={userInfo.fields[Airtabledata.Operational.children.Personen.children.Email]} sendAbscence={sendAbscence} />
              <ProjectPartDialog open={openProjectPartDialog} onClose={HandleCloseProjectPart} submit={handlePartAddTemp} values={selectedProjectValues} filterCheck={filterCheckPartDialog} role={userInfo.fields[Airtabledata.Operational.children.Personen.children.Functie]} changeCheck={changePartDialogCheck} structure={partInfo}/>
              <NotitionDialog open={openNotitionDialog} onClose={HandleCloseNotition} notition={notition.note}/>
              <GeneralSnackBar Data={snackbarData} close={setSnackbarData} />
            </div>
            <div className="mobile-menu" ref={mobileMenu}>
              <div>
              <Button onClick={() => setOpenPartDialog(true)}>
                <AddIcon className="Registration"/>
                <div>
                    Voeg toe
                  </div>
              </ Button>
              </div>
              <div>
              <Button onClick={() => setOpenAbsenceDialog(true)}>
                <CommentIcon className="Registration"/>
                  <div>
                    Vraag verlof
                  </div>
              </ Button>
              </div>
              
            </div>
            <div className = "col">
              {
                week && (
                  <AddHours  Parts={{...partInfo}} Week ={week} Email={activeAccount} UpdateHourLog={UpdateHourLog} CreateHourLog={CreateHourLog}  
                  handleRemovePart={ handleRemovePart} handleProjectRowAdd={handleProjectRowAdd} CreateHourLogWeek={CreateHourLogWeek} 
                  createComment={createComment} mobileActive={mobileActive} handleInputRow={handleInputRow} searchQuery={searchQuery} specialAbsence={specialAbsence} setSpecialAbsence={setSpecialAbsence}
                  />
                )
              }
            </ div>
          </ Paper>
        </div>
        )
}