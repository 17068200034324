//Name of internal project
const specificProjectName = "7593. Full Frame - Algemene interne uren 2024"

/**
 * Data structure gets sorted based on task and alphabet
 * @param {*} structure 
 * @returns sorted data structure
 */
export async function sortStructure(structure){
  if(structure.length === 0) return
  const sortedKeys = Object.keys(structure).sort(customSort);

// Create a new sorted object
const sortedProjectData = {};
sortedKeys.forEach(key => {
  sortedProjectData[key] = structure[key];
});

return(sortedProjectData)
/**
 * sorts two parts based on internal project then task and then on alphabet
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
function customSort(a, b) { // First check for specific program. Then if the project has a task or not. If both projects being compared have a task or dont compare them by their number
    if (a=== specificProjectName) { // check for internal hour project
      return -1;
    } else if (b === specificProjectName) {
      return 1;
    } else {
      //get tasks
      const hasTaskA = structure[a].some(item => item.task); 
      const hasTaskB = structure[b].some(item => item.task);
      // If one has a task and the other does not
      if (hasTaskA && !hasTaskB) {
        return -1;
      } else if (!hasTaskA && hasTaskB) {
        return 1;
      } else {
        const aProjectName = a.split('.')
        const bProjectName = b.split('.')
        // if you want to sort on number
        // const projectNumberA = getProjectNumber(a);
        // const projectNumberB = getProjectNumber(b);
        // return projectNumberA 0 projectNumberB
        return  String(bProjectName).localeCompare(String(aProjectName));
      }
    }
  }
}

/**
 * Used to sort on number
 * @param {*} projectName 
 * @returns Only the numbers of a project
 */
function getProjectNumber(projectName) {
  // Assuming the project number is the first part of the project name before the dot
  const parts = projectName.split('.');
  parts[0].replace('"'," ").trim() // sometimes project name has a " " as wrapper see 7487
  return parseInt(parts[0], 10) || 0; // Convert the first part to an integer
}





