import axios from 'axios';


const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
const url = "https://api.airtable.com/v0/apphMT5j6gtUnMY1p/StandardTasksLabelSynced" 
//console.log(API_KEY);
const HEADERS = {
  Authorization: `Bearer ${API_KEY}`,
  'Content-Type': 'application/json'
};
export async function getStandardTasksLabels() {

  const responseProjecten = await axios.get(url, { headers: HEADERS });
  return responseProjecten;
}