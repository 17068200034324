import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import HomeButton from "./HomeButton";
import { Link as RouterLink , useLocation } from "react-router-dom";
import TaskButton from "./StandardTasks/TaskButton";




const NavBar = () => {
    const location = useLocation();
    return (
        <div sx={{ flexGrow: 1 }}>
            <AppBar position="static">
            <Toolbar>
                <WelcomeName />
                <SignInSignOutButton />
          
                {location.pathname !== '/'&&
                <HomeButton />
                }
                
            </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;