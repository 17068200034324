import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../../styles/UrenVerantwoording/App.css"

/**
 * Dialog used to show detailed info about event
 * @param {*} param0 
 * @returns 
 */
export default function CalendarEventDialog({open, onClose, event}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function handleClose(){
    onClose(false)
  }

  if(event === undefined){
    return
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
            {event.title}
        </DialogTitle>
        <DialogContentText>
            <div className='event-dialog'>
            <div className='date-range'>
                Datums:
                <div className='start-date'>
                    van: {event.start.getDate()}-{event.start.getMonth() + 1} 
                </div>
                <div className='end-date'>
                    tot: {event.end.getDate()}-{event.end.getMonth() + 1}
                </div>
            </div>
            <div className='organizer'>
                Organizer : {event.organizer.emailAddress.name}
            </div>
            <div className='attendees'>
                Attendees:
                {event.attendees.map((people)=>{
                    return(<div className='people-event'>{people.emailAddress.name}</div>)
                })}
            </div>
            </div>
        </DialogContentText>
        <DialogActions>
            <div className='alertaction'>
                <Button onClick={handleClose} autoFocus>
                    Sluiten
                </Button>
            </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
