// srtapp.js
import React, { useState } from "react";
import axios from 'axios';
import { Container, Typography, Button, Box, List, ListItem, ListItemText } from "@mui/material";


const url_self = process.env.REACT_APP_CURRENT_URL;

export function AppContent() {
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [downloadedFiles, setDownloadedFiles] = useState([]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Selecteer een SRT-bestand om te uploaden.");
            return;
        }
        
        const formData = new FormData();
        formData.append("srtFile", file);

        try {
            const response = await axios.post(`${url_self}/convert-srt-to-fcpxml`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setUploadedFiles(prev => [...prev, file.name]);

            // Handle download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const downloadFileName = file.name.replace('.srt', '.fcpxml');
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadFileName);
            document.body.appendChild(link);
            link.click();

            setDownloadedFiles(prev => [...prev, downloadFileName]);
        } catch (error) {
            console.error('Er was een fout bij het uploaden van het bestand:', error);
            alert('Fout bij het uploaden van het bestand.');
        }
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
            <Typography variant="h5">Upload SRT voor Conversie naar FCPXML</Typography>
            <Box marginTop={2}>
                <input type="file" onChange={handleFileChange} accept=".srt" />
                <Button variant="contained" color="primary" onClick={handleUpload}>
                    Upload en Converteer
                </Button>
            </Box>
            {uploadedFiles.length > 0 && (
                <List>
                    <Typography variant="h6">Geüploade bestanden:</Typography>
                    {uploadedFiles.map((file, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={file} />
                        </ListItem>
                    ))}
                </List>
            )}
            {downloadedFiles.length > 0 && (
                <List>
                    <Typography variant="h6">Gedownloade bestanden:</Typography>
                    {downloadedFiles.map((file, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={file} />
                        </ListItem>
                    ))}
                </List>
            )}
        </Container>
    );
}
