import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';

const MismatchedReservations = ({reservationsData,handleDeleteReservation, onReservationClick  }) => {

    const [mismatchedReservations, setMismatchedReservations] = useState([]);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [reservationToDelete, setReservationToDelete] = useState(null);
    const [expanded, setExpanded] = useState(false);



    useEffect(() => {
        console.log('reservations changed');
        console.log('reservations mismatch', reservationsData);
    
        if (Array.isArray(reservationsData)) { // Voeg deze controle toe
            const getMismatchedReservations = (reservationsData) => {
                return reservationsData.filter(reservation => reservation.fields.misMatchDataOutlook === 'True');
            };
    
            const mismatched = getMismatchedReservations(reservationsData);
            setMismatchedReservations(mismatched);
        }
    }, [reservationsData]); // Run when reservationsData changes
  
    const handleChange = () => {
        setExpanded(!expanded);
    };

    if (mismatchedReservations.length === 0) {
        return null; // Return null or some other placeholder if there are no mismatched reservations
    }    

    const initiateDeleteReservation = (reservationId) => {
        setReservationToDelete(reservationId);
        setConfirmDeleteDialogOpen(true);
    };
    
    const handleReservationClick = (reservation) => {
        console.log('clicked reservation', reservation);
    
        // Controleer of de reservering gekoppeld is aan een draaidag
        if (!reservation.fields.DraaidagOutlook || reservation.fields.DraaidagOutlook.length === 0) {
            // Toon een alert als de reservering niet aan een draaidag is gekoppeld
            alert("Deze reservering is niet gekoppeld aan een draaidag. Gelieve de reservering te verwijderen of handmatig opnieuw te koppelen aan een draaidag in Airtable.");
        } else {
            // Voer de oorspronkelijke klikfunctie uit als alles in orde is
            onReservationClick(reservation);
        }
    };
    

    return (
        <>
        <Paper style={{ padding: '20px', marginTop: '20px', marginBottom: '20px' }}>
            <Alert severity="warning" onClick={handleChange} style={{ cursor: 'pointer', marginBottom: '20px' }}>
                <AlertTitle>Waarschuwing</AlertTitle>
                Er zijn reserveringen met mismatched data. — <strong>Klik om te bekijken!</strong>
            </Alert>
            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Controleer onderstaande reserveringen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        Bij onderstaande reserveringen kloppen de data van de draaidag niet meer met de data van de originele reservering. Door op de reservering te klikken kan je de data van de reservering wijzigen zodat deze weer overeenkomt met data van draaidag.
                    </Typography>
                    <List>
                        {mismatchedReservations.map(mismatchedReservation => (
                            <ListItem key={mismatchedReservation.id} button onClick={() => handleReservationClick(mismatchedReservation)}>
                                <ListItemText 
                                    primary={`Reservering: ${mismatchedReservation.fields.reserveringNummer} - ${mismatchedReservation.fields.OrigineleDraaidagNaam}`} 
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" color="textPrimary" display="block">
                                                Draaidagagenda: {`${mismatchedReservation.fields.DraaidagNaam}`}
                                            </Typography>
                                            <Typography component="span" variant="body2" color="textPrimary" display="block">
                                                {mismatchedReservation.fields.startDatumDraaidag} - {mismatchedReservation.fields.eindDatumDraaidag} (draaidagdata) 
                                            </Typography>
                                            <Typography component="span" variant="body2" color="textPrimary" display="block">
                                                {mismatchedReservation.fields.Start} - {mismatchedReservation.fields.Eind} (reserveringsdata)
                                            </Typography>
                                            <Typography color="error">Mismatch!</Typography>
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Button edge="end" aria-label="delete" onClick={() => initiateDeleteReservation(mismatchedReservation.id)}>
                                        Verwijder reservering
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Paper>
        <Dialog open={confirmDeleteDialogOpen} onClose={() => setConfirmDeleteDialogOpen(false)}>
            <DialogTitle>Bevestig Verwijdering</DialogTitle>
            <DialogContent>
                <Typography>
                    Weet u zeker dat u deze reservering wilt verwijderen?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDeleteDialogOpen(false)}>Annuleren</Button>
                <Button color="primary" onClick={() => {
                    handleDeleteReservation(reservationToDelete);
                    setConfirmDeleteDialogOpen(false);
                }}>
                    Verwijderen
                </Button>
            </DialogActions>
        </Dialog>
        </>
    
    );
};

export default MismatchedReservations;
