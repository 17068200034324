import React from "react"
import { useState } from "react";
import {Chip, Checkbox, Dialog, DialogContent, DialogTitle, Grid, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


const MaterialSearch = ({ 
    setSearchDialogOpen,
    isMaterialAvailable,
    selectedMaterials,
    searchDialogOpen,
    alleMaterialen,
    handleMaterialClickForOverlap,
    handleMaterialToggle,
    reservations
     }) =>{

    const [searchTerm, setSearchTerm] = useState('');

    return(
        // Search Dialog 
        <Dialog open={searchDialogOpen} onClose={() => setSearchDialogOpen(false)}>
            <DialogTitle>
                Zoek materiaal
                <IconButton
                    onClick={() => setSearchDialogOpen(false)}
                    style={{ position: 'absolute', right: '8px', top: '8px' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField 
                    fullWidth 
                    label="Zoek" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginTop: '20px' }}
                />
                {alleMaterialen.filter(record => 
                    (record.fields.Naam ? record.fields.Naam.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
                    (record.fields.ID ? record.fields.ID.toLowerCase().includes(searchTerm.toLowerCase()) : false)
                ).map(record =>  
                    {
                        const isAvailable = isMaterialAvailable(record, reservations);
    
                        return (
                            //<Grid key={record.id} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <ListItem 
                                key={record.id}
                                style={{ paddingTop: 2, paddingBottom: 2, display: 'flex', alignItems: 'center' }}
                                onClick={() => {
                                    if (!isAvailable) handleMaterialClickForOverlap(record);
                                    else handleMaterialToggle(record);
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedMaterials.indexOf(record.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        disabled={!isAvailable}  // Checkbox is disabled when isAvailable is false
                                    />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={record.fields.Naam}
                                    secondary={`${record.fields.ID}`}
                                    primaryTypographyProps={{ noWrap: true }} // Voorkomt tekstomslag
                                    secondaryTypographyProps={{ noWrap: true }} // Voorkomt tekstomslag
                                    style={{ marginRight: '16px', maxWidth: '60%' }} // Voeg rechter marge toe voor ruimte tussen tekst en chip
                                />
                                {/* Status Tag zoals reparatie */}
                                {record.fields.Status && record.fields.Status !== 'In gebruik' && (
                                    <Chip
                                        label={record.fields.Status}
                                        color="error" // Rode kleur
                                        size="small"
                                    />
                                )}
                            </ListItem>

                            //</Grid>
                        )
                    }
                )}
            </DialogContent>

        </Dialog>
        //{/* End Search Dialog */}  
    )
}

export default MaterialSearch;