// Asynchronously attaches 'draaidag' tasks to parts of a project efficiently
export default async function AttachDraaidag(_Air, Structure, Functions, tasks, week, userID, email) {
    // Create a map to hold project parts for quick access and reduce API calls
    const projectPartsCache = new Map();

    // Process each task, caching parts data to minimize API calls
    for (const task of tasks) {
        let projectParts = projectPartsCache.get(task.project);
        if (!projectParts) {
            projectParts = await _Air.GetPartsFromProject(task.project);
            projectPartsCache.set(task.project, projectParts);
        }
        if (projectParts.length === 0) continue; // Skip if no parts are available, but don't return

        // Process each required function within the task
        for (const func of Functions) {
            // Attach parts related to the current function
            Structure = await attachPartByRole(Structure, task, week, projectParts, userID, func, _Air, email);
        }
    }
    return Structure; // Return the updated structure after processing all tasks
}

// Function to attach parts to the structure based on their role
async function attachPartByRole(Structure, task, week, projectParts, userID, functie, _Air, email) {
    const parts = getParts(projectParts, functie);
    const projectName = projectParts[0]?.fields["Projectnr_en_naam (from Projecten)"]?.[0];

    for (const part of parts) {
        const projectStructure = Structure[projectName] || (Structure[projectName] = []);
        let matchingElement = projectStructure.find(x => x.ID === part.fields.autonumber);

        if (!matchingElement) {
            matchingElement = await returnNewStructure([part], Structure, userID, _Air, week, email, task);
            projectStructure.push(matchingElement);
        } else {
            matchingElement['task'] = task;
        }
    }
    return Structure;
}

// Retrieves parts of a specific type (role) from project parts
function getParts(projectParts, type) {
    return projectParts.filter(part => part.fields["Taaktype (from StandardOnderdeel)"]?.[0] === type);
}
