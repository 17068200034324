import AirtableData from "./API/AirtableData.json"

/**
 * Creates new structure object
 * @param {*} parts all parts with the new part added
 * @param {*} structure old structure object
 * @param {*} userID 
 * @param {*} _Air AirHour class needed to get hours
 * @param {*} week 
 * @param {*} email 
 * @param {*} task 
 * @returns new Structure with new part added
 */
export default async function returnNewStructure(parts, structure, userID, _Air, week, email, task = undefined) {
    for (const part of parts) {
        const partProjectName = part.fields[AirtableData.Operational.children.Onderdeel.children["Projectnr_en_naam (from Projecten)"]];
        
        let partStuc = await CreatePartStructure(part, userID);
        
        if (task !== undefined) {
            partStuc['task'] = task;
            partStuc.Pinned = true
        }

        partStuc =  await getAllHours(_Air,partStuc,week,email)

        if (structure.length === 0) {
            structure['partname'] = partStuc;
        } else {
            if (structure.hasOwnProperty(partProjectName)) {
                structure[partProjectName].push(partStuc);
            } else {
                structure[partProjectName] = [partStuc];
            }
        }
    }

    return structure;
}

/**
 * Formats data in new object
 * @param {*} Part 
 * @param {*} userID 
 * @returns array of part objects
 */
async function CreatePartStructure(Part, userID){
    const partNames ={
        ID : Part.fields[AirtableData.Operational.children.Onderdeel.children.autonumber],
        UserID: userID,
        Name: Part.fields[AirtableData.Operational.children.Onderdeel.children["offerteregelOmschrijving"]].split('(')[0],
        projectName : Part.fields[AirtableData.Operational.children.Onderdeel.children["Projectnr_en_naam (from Projecten)"]][0],
        onderdeelRecID: Part.id,
        Aantal: Part.fields[AirtableData.Operational.children.Onderdeel.children.Aantal],
        KorteOmschrijving : Part.fields[AirtableData.Operational.children.Onderdeel.children["OmschrijvingCategorie not empty"]],
        LangeOmschrijving : Part.fields[AirtableData.Operational.children.Onderdeel.children["Categorie not empty"]],
        Unit: Part.fields[AirtableData.Operational.children.Onderdeel.children.Eenheid.name],
        Group : Part.fields[AirtableData.Operational.children.Onderdeel.children["Group not empty"]],
        hideForTimeWriting : Part.fields[AirtableData.Operational.children.Onderdeel.children.hideForTimeWriting.name],
        Pinned : false
    }

    return partNames
}
/**
 * Gets hour of the week of a part
 * @param {*} _Air 
 * @param {*} part 
 * @param {*} week 
 * @param {*} email 
 * @returns 
 */
async function getAllHours(_Air,part,week,email){
    await _Air.StartRequest();
    try {
        const GetHours = await _Air._Uren.GetHours(email, part.ID, week);
        if (GetHours !== undefined) {
            part["hours"] = GetHours[0];
        }
    } finally {
        _Air.CompleteRequest();
    }
    await _Air.StartRequest()
    try{
        const sumHours = await _Air._Uren.GetSumHours(part.ID)
        if(sumHours !== undefined){
            part['sumHours'] = sumHours[0].fields.SumHours
        }
    }finally{
        _Air.CompleteRequest()
    }

    return part
}


