
/**
 * sorts the array based on the function of the user
 * @param {*} projects list of all projects
 * @param {*} roles functions of user
 * @returns projects sorten on role of user
 */
export function sortProjectonPartType(projects, roles){
    if (!(roles instanceof Array)) return []
    const combinedRoleCounts = projects.map(array =>
        roles.reduce((total, role) => total + (array.roles[role] || 0), 0)
        );
    

    // Create an array of indices to maintain the original order
    const indices = Array.from({ length: projects.length }, (_, index) => index);


    // Sort the indices based on the role counts
    indices.sort((a, b) => combinedRoleCounts[b] - combinedRoleCounts[a]);

    // Create an array of sorted arrays
    const sortedArrays = indices.map(index => projects[index]);

    return sortedArrays;
}

