import axios from "axios";

const url_self = process.env.REACT_APP_CURRENT_URL;


/**
 * Data layer accessing Urenregistratie base
 */
export class UREN_CRUD {
    constructor(){
        this.hours = null
        this.reports = []
    }

    /**
     * Creates month rapport
     * @param {*} userId 
     * @param {*} date 
     * @returns record of created rapport
     */
    async CreateReport(userId, date) {
        console.log('CreateReport');
        try {
            const res = await axios.post(`${url_self}/Api/CreateReport`, {
                userId, 
                date
            });
    
            this.reports.push(...res.data);
            return res.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Find rapport of user
     * @param {*} userId 
     * @param {*} date start of the month
     * @param {*} name 
     * @returns foud record. If non found empty array
     */
    async FindReport(userId, date, name) {
        try {
            const queryParams = new URLSearchParams({ userId, date, name }).toString();
            const res = await axios.get(`${url_self}/Api/FindReport?${queryParams}`);
    
            this.reports.push(...res.data);
            return res.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Creates log in UrenLog
     * @param {*} id 
     * @param {*} day 
     * @param {*} value 
     * @param {*} weekStart 
     * @param {*} onderdeelID 
     * @param {*} tableID id of table
     * @param {*} name 
     * @returns recID of created record
     */
    async CreateHourLog(id, day, value, weekStart, onderdeelID, tableID, name) {
        try {
            const response = await axios.post(`${url_self}/Api/CreateHourLog`, {
                id, day, value, weekStart, onderdeelID, tableID, name
            });
    
            return response.data.id; // Assuming the server response includes the created record's ID
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    
    /**
     * Updates singular day of log 
     * @param {*} logID 
     * @param {*} day 
     * @param {*} value 
     * @param {*} tableID 
     * @returns 
     */
    async UpdateHourLog(logID, day, value, tableID) {
        try {
            await axios.patch(`${url_self}/Api/UpdateHourLog`, {
                logID, day, value, tableID
            });
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Creates log in Bijzonder verlof table
     * @param {*} tableID id of the table
     * @param {*} partName name of the type of special absence
     * @param {*} day 
     * @param {*} value 
     * @param {*} weekStart 
     * @param {*} id 
     * @param {*} userName 
     * @returns recID of created log
     */
    async CreateSpecialAbsenceLog(tableID, partName, day, value, weekStart, id, userName) {
        try {
            const response = await axios.post(`${url_self}/Api/CreateSpecialAbsenceLog`, {
                tableID, partName, day, value, weekStart, id, userName
            });

            return response.data.id; // Assuming the server responds with the created record's ID
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Updates log of special absence
     * @param {*} tableID 
     * @param {*} logID 
     * @param {*} day 
     * @param {*} value 
     * @returns 
     */
    UpdateSpecialAbsenceLog(tableID, logID, day, value) {
        try {
            axios.patch(`${url_self}/Api/UpdateSpecialAbsenceLog`, {
                tableID, logID, day, value
            });
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Gets all hours of the person of the part
     * @param {*} email 
     * @param {*} onderdeelID 
     * @param {*} week 
     * @returns 
     */
    async GetHours(email,onderdeelID, week){
        try
        {  
            if(this.hours === null){
                await this.GetWeekHours(email,week)
            }
            return this.hours.filter(x => {
                return Number(x.fields.OnderdeelID[0]) === Number(onderdeelID)}) 
        }catch(err){
            return err
        }
    }

    /**
     * gets all hours of user in the bijzonder verlof table
     * @param {*} week 
     * @param {*} Email 
     * @param {*} tableData 
     * @returns 
     */
    async getSpecialAbsenceHours(week, Email, tableData) {
        try {
            // Ensure to convert tableData to a query-compatible format if necessary
            const response = await axios.get(`${url_self}/Api/GetSpecialAbsenceHours?week=${encodeURIComponent(JSON.stringify(week))}&email=${Email}&tableData=${encodeURIComponent(JSON.stringify(tableData))}`);
            return response.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Gets all hours of a user from UrenLog
     * @param {*} email 
     * @param {*} week 
     * @returns 
     */
    async GetWeekHours(email, week) {
        try {
            const response = await axios.get(`${url_self}/Api/GetWeekHours?email=${email}&week=${encodeURIComponent(JSON.stringify(week))}`);
            this.hours = response.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Gets all sum hours of a part
     * @param {*} onderdeelID 
     * @returns 
     */
    async GetSumHours(onderdeelID) {
        try {
            const response = await axios.get(`${url_self}/Api/GetSumHours?onderdeelID=${onderdeelID}`);
            return response.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Creates whole week of hours
     * @param {*} monday 
     * @param {*} onderdeelID 
     * @param {*} id 
     * @param {*} values array of hours
     * @param {*} tableID 
     * @param {*} name 
     * @returns 
     */
    async CreateHourLogWeek(monday, onderdeelID, id, values, tableID, name) {
        try {
            const response = await axios.post(`${url_self}/Api/CreateHourLogWeek`, {
                monday, onderdeelID, id, values, tableID, name
            });
    
            return response.data.id; // Assuming the server responds with the created record's ID
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Updates the whole log
     * @param {*} logID 
     * @param {*} values array of hours 
     * @param {*} tableID 
     * @returns 
     */
    async UpdateHourLogWeek(logID, values, tableID) {
        try {
            const response = await axios.patch(`${url_self}/Api/UpdateHourLogWeek`, {
                logID, values, tableID
            });
    
            return response.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * updates a hour log with a comment
     * @param {*} logID 
     * @param {*} content 
     * @param {*} tableID 
     * @returns 
     */
    async CreateComment(logID, content, tableID) {
        try {
            await axios.patch(`${url_self}/Api/CreateComment`, {
                logID, content, tableID
            });
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Create a hour log to put a comment in
     * @param {*} content 
     * @param {*} id 
     * @param {*} onderdeelID 
     * @param {*} week 
     * @param {*} tableID 
     * @returns 
     */
    async CreateCommentLog(content, id, onderdeelID, week, tableID) {
        try {
            const response = await axios.post(`${url_self}/Api/CreateCommentLog`, {
                content, id, onderdeelID, week, tableID
            });
    
            return response.data.id;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Gets all hours of a part to check if there is a possibility of overlap
     * @param {*} email 
     * @param {*} weekStart 
     * @param {*} onderdeelID 
     * @param {*} offset 
     * @param {*} urenLogData 
     * @returns 
     */
    async GetAllHourLogsOfPart(email, weekStart, onderdeelID, offset, urenLogData) {
        try {
            const response = await axios.get(`${url_self}/Api/GetAllHourLogsOfPart`, {
                params: {
                    email, weekStart, onderdeelID, offset, urenLogData: JSON.stringify(urenLogData) // Might need to handle urenLogData object conversion appropriately
                }
            });
    
            return response.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Gets record id of blua base part
     * @param {*} OnderdeelID 
     * @param {*} syncData 
     * @returns 
     */
    async GetHourSyncPart(OnderdeelID, syncData) {
        if (OnderdeelID === undefined) return;
        try {
            const response = await axios.get(`${url_self}/Api/GetHourSyncPart`, {
                params: {
                    OnderdeelID, 
                    syncData: JSON.stringify(syncData) // Might need to handle syncData object conversion appropriately
                }
            });
    
            return response;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Creates week notition in the Week notition base
     * @param {*} text 
     * @param {*} week 
     * @param {*} notitionData 
     * @param {*} id 
     * @returns 
     */
    async CreateNotition(text, week, notitionData, id) {
        try {
            const response = await axios.post(`${url_self}/Api/CreateNotation`, {
                text, week, notitionData, id
            });
    
            return response;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Finds notition of user in a certain week
     * @param {*} week 
     * @param {*} email 
     * @param {*} notitionData 
     * @returns empty array if nothing found
     */
    async FindNotition(week, email, notitionData) {
        try {
            const response = await axios.get(`${url_self}/Api/FindNotition`, {
                params: {
                    week: JSON.stringify(week), // Adjust for correct serialization
                    email, 
                    notitionData: JSON.stringify(notitionData) // Adjust for correct serialization
                }
            });
    
            return response.data;


        } catch (err) {
            console.log(err);
            return err;
        }
    }
    /**
     * updates log based on given record id
     * @param {*} notitionID 
     * @param {*} text 
     * @param {*} notitionData 
     * @returns 
     */
    async UpdateNotition(notitionID, text, notitionData) {
        try {
            const response = await axios.patch(`${url_self}/Api/UpdateNotation`, {
                notitionID, text, notitionData
            });
    
            return response;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Post request of absence to verlof verzoek table
     * @param {*} dates date of the request
     * @param {*} irregularDate date with differte hour than 8
     * @param {*} comment extra comment of the request
     * @param {*} deltaHours 
     * @param {*} id recID of blue base urer
     * @param {*} name 
     * @param {*} weekStart 
     * @returns 
     */
    async PostAbsence(date, irregularDate, comment, deltaHours, verlofData, id, name, weekStart) {
        console.log(weekStart);
        try {
            const response = await axios.post(`${url_self}/Api/PostAbsence`, {
                date, irregularDate, comment, deltaHours, verlofData, id, name, weekStart
            });
    
            return response.status;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * gets record id of user in the blue base
     * @param {*} email 
     * @param {*} personenData 
     * @returns 
     */
    async GetRecID(email, personenData) {
        try {
            const response = await axios.get(`${url_self}/Api/GetRecID`, {
                params: {
                    email, 
                    personenData: JSON.stringify(personenData) // Adjust for correct serialization
                }
            });
    
            return response.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    /**
     * Helper function to get the start of the month. Usefull for getting or creating hour registration rapport
     * @param {*} weekStart 
     * @returns 
     */
    async getFirstDayOfMonth(weekStart){
        const dateObjectWeekStart = new Date(weekStart)

        const year = dateObjectWeekStart.getFullYear();
        const month = dateObjectWeekStart.getMonth();
      
        const firstDayOfMonth = new Date(year, month, 2).toISOString().slice(0,10)
        
        return firstDayOfMonth;
    }



}