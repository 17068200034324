import axios from 'axios';


const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
//console.log(API_KEY);
const HEADERS = {
  Authorization: `Bearer ${API_KEY}`,
  'Content-Type': 'application/json'
};
export async function getPeople(functie) {

  const url = `https://api.airtable.com/v0/appairPVZTnXeJaJG/Personen?filterByFormula=FIND('${functie}', ARRAYJOIN({Functie}, ','))&sort%5B0%5D%5Bfield%5D=Naam`
  const responseProjecten = await axios.get(url, { headers: HEADERS });
  return responseProjecten;
}