import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarEventDialog from '../Dialog/CalendarEventDialog'
import moment from 'moment';
import { Calendar, Views, Navigate, momentLocalizer } from 'react-big-calendar'
import { Typography } from "@mui/material";

// load in the correct local to NL
moment.locale('nl-NL'); // or 'nl' for Dutch
const localizer = momentLocalizer(moment);



/**
 * Loads in all outlook email events and place them in a calendar
 * @param {*} param0 
 * @returns 
 */
const OutlookCalendar = ({token, width , height}) => 
{
  const now = new Date();

    const today8AM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0);

    const { instance, accounts } = useMsal();
    const [events, setEvents] = useState([]);
    const [openEventDialog,setOpenEventDialog] = useState(false);
    const [selectedEvent,setSelectedEvent] = useState()
  
    useEffect(()=>{
      setOpenEventDialog(true)
    },[selectedEvent])

    /**
     * Retrieves calendar events within a specified time range
     * @param {string} startDateTime - The start date of the calendar view
     * @param {string} endDateTime - The end date of the calendar view
     * @param {string} nextLink - Optional link to fetch more events
     * @returns {Array} - An array of calendar events
     * @returns 
     */
    async function getCalendarEvents(startDateTime, endDateTime, nextLink){
      const events = [];
      
      const requestUrl = nextLink || `https://graph.microsoft.com/v1.0/me/calendar/calendarView?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
  
      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
  
      if (data.value) {
        data.value.forEach((event) =>{
            // Handle non-recurring events
            // events from outlook are One hour off 
            const startOneHourOff=new Date(event.start.dateTime)
            const endOneHourOff=new Date(event.end.dateTime)

            const start = startOneHourOff.setHours(startOneHourOff.getHours() + 1)
            const end = endOneHourOff.setHours(endOneHourOff.getHours() + 1)
            events.push({
              title: event.subject,
              start: new Date(start),
              end: new Date(end),
              attendees: event.attendees,
              organizer: event.organizer
            });
          }
        );
      }
  
      // If there is a next link, recursively fetch more events
      if (data['@odata.nextLink']) {
        const moreEvents = await getCalendarEvents(startDateTime, endDateTime, data['@odata.nextLink']);
        events.push(...moreEvents);
      }
  
      return events;
    };
    /**
     * Fetches and sets calendar events within the current month and the next month.
     */
    useEffect(() => {
      if (!token) {
        // Handle the error when there is no token  
      } else {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        const endOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0);
        
        const startOfMonthISOString = startOfMonth.toISOString();
        const endOfNextMonthISOString = endOfNextMonth.toISOString();
        
        getCalendarEvents(startOfMonthISOString, endOfNextMonthISOString)
          .then((filteredEvents) => {
            setEvents(filteredEvents);
          })
          .catch((error) => console.error(error));
      }
    }, [token]);
  


    return (
        <div>
            {events.length > 0 &&  
                <div>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultDate={today8AM}
                        style={{ height: (height-10), width:(width-10), widows: true }}
                        defaultView={Views.WEEK}
                        scrollToTime={new Date(1970,1,1,8)}
                        onSelectEvent={(event)=> setSelectedEvent(event)}
                     />
                </div>
            }
            {!token&& (<Typography>Er is iets fout gegaan. Probeer op nieuw in te loggen</Typography>)}
            <CalendarEventDialog open={openEventDialog} onClose={setOpenEventDialog} event={selectedEvent}/>
        </div>
    )
}

export default OutlookCalendar;
