import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../../styles/UrenVerantwoording/App.css"
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import DatePicker from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import {getWeekDate} from '../../../utils/UrenVerantwoording/getDate'

const today = new Date()
/**
 * Dialog where the user can indicate which dates he wants leave of abscence
 * @param {*} param0 
 * @returns 
 */
export default function AbsenceDialog({open, onClose, saldo, name , email, sendAbscence}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [absenceSaldo, setAbsenceSaldo] = React.useState(0)
  const [deltaHours, setDeltaHours] = React.useState(0)
  const [leftover, setLeftover]= React.useState()
  const [selectedDays, setSelectedDays] = React.useState([])
  const [hourInput, setHourInput] = React.useState({});
  const valueRef = React.useRef(null)

  /**
   * Put the saldo from the parameter in an useState variable
   */
  React.useEffect(()=>{
      setAbsenceSaldo(saldo)
    },[saldo])

 /**
  * change leftover saldo if deltahours or the absenceSaldo has changed
  */
  React.useEffect(()=>{
    setLeftover(absenceSaldo-deltaHours)
  },[deltaHours,absenceSaldo])

  /**
   * For each selected day set total hours + 8
   */
  React.useEffect(() => {
    const initialHourInput = selectedDays.reduce((input, date) => {
      input[date] = 8;
      return input;
    }, {});
    setHourInput(initialHourInput);
  }, [selectedDays]);
  /**
   * Depending on the total hours input change the delta hours
   */
  React.useEffect(()=>{
    const totalHours = Object.values(hourInput).reduce((total, hours) => total + parseInt(hours), 0);
    setDeltaHours(totalHours)
  },[hourInput])
  
  /**
   * compille and send filled in information if available
   * @returns Close Dialog if no info available
   */
  function handleClick(){
    if(selectedDays.length===0) return handleClose()
    const comment = valueRef.current.value
    selectedDays.forEach((date)=>{
      const isoDateString = new Date(date).toISOString().slice(0, 10);     
      const weekStart = getWeekDate(new Date(date)).toISOString().slice(0,10)
      sendAbscence(isoDateString,'',comment,Number(hourInput[date]),weekStart)
      handleClose()
    })  
  }
  /**
   * Empty values and then close Dialog
   */
  const handleClose = () =>{
    setSelectedDays([])
    valueRef.current = null
    onClose()
  }
  /**
   * Gets date from calendar and sort them based on which comes first
   * @param {*} date 
   */
  function handleDate(date){
    const dateArray = date
    dateArray.sort((a,b)=> {return a - b})
    setSelectedDays(dateArray)
  }

  return (
    <div className='absence'>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx:{ maxWidth: 650}}}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Verlof aan vragen"}
        </DialogTitle>
        <DialogContentText>
          <div>
            <center>Uw huidige saldo : {saldo}</center>
          </div>
          <div className='absence-calendar'>
          <Calendar
            multiple
            onChange={handleDate}
            value={selectedDays}
            minDate={today}
            mapDays={({ date }) => {
            
              let isWeekend = [0, 6].includes(date.weekDay.index)
              if (isWeekend) return {
                disabled: true,
                style: { color: "#ccc" },
              }
            }}
          />
          </div>

          {selectedDays.length > 0 && 
            selectedDays.map((date, index)=>(
              <div className='absence-date' key={date}>
                {date.weekDay.name} {date.day} / {date.month.number} / {date.year} 
                <TextField 
                label={"uren"} 
                type='number' 
                size="small" 
                focused 
                value={hourInput[date]}
                onChange={(e) => {
                  const updatedInput = { ...hourInput, [date]: e.target.value };
                  setHourInput(updatedInput);
                }}/>
              </div>
            ))
          }

          { selectedDays.length > 0 && <div className='absence-label'>
            <div>Overige saldo is dan = {leftover}</div>
          </div>} 

          {selectedDays.length > 0 && <div className='absence-text'>
            Extra informatie
            <TextField
                        id="outlined-multiline-static"
                        label="Comment"
                        multiline
                        rows={4}
                        className='dialog-comment'
                        inputRef={valueRef}
                    />
            
          </div>}



        </DialogContentText>
        <DialogActions> 
            <div className='alertaction'>
                <Button onClick={handleClick} disabled={leftover < 0}autoFocus>
                    Akkoord
                </Button>
                <Button onClick={handleClose} autoFocus>
                    Sluiten
                </Button>
            </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
