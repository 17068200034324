import { DateCalendar } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from "react";
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import dayjs from "dayjs";

        /**
         * Calendar styling coming from https://mui.com/x/react-date-pickers/date-calendar/#week-picker
         * @param {*} param0 
         * @returns 
         */
        const Calendar = ({calendarDate,handleCalendarDate, disablepast}) => 
        {
            const [hoveredDay, setHoveredDay] = React.useState(null);
            
            const CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
        })(({ theme, isSelected, isHovered, day }) => ({
        borderRadius: 0,
        ...(isSelected && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
            },
        }),
        ...(isHovered && {
            backgroundColor: theme.palette.primary[theme.palette.mode],
            '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
            },
        }),
        ...(day.day() === 1 && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...(day.day() === 0 && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
        }));

        /**
         * compares two days and check if dayB is in the same week as dayA
         * @param {dayjs} dayA 
         * @param {dayjs} dayB 
         * @returns true or false
         */
        const isInSameWeek = (dayA, dayB) => {
            if (dayB == null) {
              return false;
            }
          
            return dayA.isSame(dayB, 'week');
          };
    
    /**
     * Custom dayslot from https://mui.com/x/react-date-pickers/date-calendar/#week-picker 
     * @param {*} props 
     * @returns 
     */
    function Day(props) {
        const { day, selectedDay, hoveredDay, ...other } = props;
        return (
          <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
          />
        );
      }
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar value={calendarDate} onChange={(newCalendarDate) => handleCalendarDate(newCalendarDate)} disableHighlightToday={true}
                    showDaysOutsideCurrentMonth
                    slots={{ day: Day }}
                    shouldDisableDate={(date)=>{
                        return (calendarDate > date && disablepast && !(isInSameWeek(date, calendarDate))) 
                    }}
                    slotProps={{
                    day: (ownerState) => ({
                        selectedDay: calendarDate,
                        hoveredDay,
                        onPointerEnter: () => setHoveredDay(ownerState.day),
                        onPointerLeave: () => setHoveredDay(null),
                    }),
                    }}
                  />  
            </ LocalizationProvider>
        </div>
    )
}

export default Calendar;
