const millisecondsPerDay = 24 * 60 * 60 * 1000;
/**
 * Gets the data of the week 
 * @param {*} startDate 
 * @returns usefull data of the week in a object
 */
export function generateWeekDates(startDate) {
    const weekRowtext = ["ma", "di", "wo", "do", "vr" ];
    const weekRowtextFull = ["Maandag","Dinsdag","Woensdag","Donderdag","Vrijdag"]
    const weekRowNumber = [];
    const weekRowDate = [];
    
    // Loop through each day of the week
    for (let index = 0; index < weekRowtext.length; index++) {
      const currentDate = new Date(startDate.getTime() + index * millisecondsPerDay); // Calculate the date for the day
  
      // Format the date as "dd-MM-yyyy"
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if needed
      const day = currentDate.getDate().toString().padStart(2, '0'); // Add leading zero if needed
  
      const formattedDate = `${year}-${month}-${day}`;
  
      weekRowNumber.push(currentDate.getDate());
      weekRowDate.push(formattedDate);
    }
    return {
      weekRowtext,
      weekRowNumber,
      weekRowDate,
      weekRowtextFull
    };
  }
  
  /**
   * Gives date in string back
   * @param {*} startdate 
   * @param {*} enddate 
   * @returns 
   */
export function Formatedtext(startdate, enddate){

  const monthNames = ["januari", "februari", "maart", "april", "mei","juni", "juli", "augustus","september", "oktober", "november", "december"]

  const StartDate = new Date (startdate)
  const EndDate = new Date (enddate)

  // Add 2 days to the original end date to make it a Sunday
  EndDate.setDate(EndDate.getDate() + 2);
  const StartMonth = StartDate.getMonth()
  const StartYear = StartDate.getFullYear()
  let formatedtext = ""
  if(StartMonth === EndDate.getMonth()){
    formatedtext = `${StartDate.getDate().toString().padStart(2, '0')}-${EndDate.getDate().toString().padStart(2, '0')} ${monthNames[StartMonth]}, ${StartYear}`
  }
  else if(StartYear !== EndDate.getFullYear()){
    formatedtext = `${StartDate.getDate().toString().padStart(2, '0')} ${monthNames[StartMonth]}, ${StartYear}-${EndDate.getDate().toString().padStart(2, '0')} ${monthNames[EndDate.getMonth()]}, ${EndDate.getFullYear()}`
  }else{
    formatedtext = `${StartDate.getDate().toString().padStart(2, '0')} ${monthNames[StartMonth]}-${EndDate.getDate().toString().padStart(2, '0')} ${monthNames[EndDate.getMonth()]}, ${StartYear}`
  }
  return formatedtext

}

/**
 * Gets the monday of the current date
 * @returns 
 */
export function getCurrentWeekDate() {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const diffDays = currentDay - 1; // Calculate the number of days to subtract to get to Monday (assuming Monday is the start of the week)
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - diffDays);
    return startDate
  }

  /**
   * Gets monday of the startDate
   * @param {*} startDate 
   * @returns 
   */
export function getWeekDate(startDate){
  const startDay = startDate.getDay()
  const diffDays = startDay - 1
  const Monday = new Date(startDate)
  Monday.setDate(startDate.getDate() - diffDays)
  return Monday 
}

/**
 * Gets data about next week
 * @param {*} currentDate 
 * @returns Object with info about next week
 */
export function nextWeek(currentDate){
  const CurrentDate = new Date (currentDate)
  return generateWeekDates(new Date(CurrentDate.getTime() + 7 * millisecondsPerDay))
}
/**
 * Gets data about the previous week
 * @param {*} currentDate 
 * @returns Object with info about the previous week
 */
export function prevWeek(currentDate){
  const CurrentDate = new Date (currentDate)
  return generateWeekDates(new Date(CurrentDate.getTime() - 7 * millisecondsPerDay))
} 

/**
 * Get all the mondays between two dayes
 * @param {*} startDate 
 * @param {*} endDate 
 * @returns Array of dates 
 */
export function getMondayBetweenDates(startDate,endDate){
  const mondays = [];
  let current = getWeekDate(startDate);
  current.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero

  // Iterate through the weeks until the current date is before or equal to the end date
  while (current <= endDate) {
    if (current.getDay() === 1) { // Monday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const year = current.getFullYear();
      const month = (current.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if needed
      const day = current.getDate().toString().padStart(2, '0'); // Add leading zero if needed
      mondays.push(`${year}-${month}-${day}`)
      }
    current.setDate(current.getDate() + 7); // Move to the next week
  }
  mondays.splice(0,1)
  return mondays;
}
