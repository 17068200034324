import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../../styles/UrenVerantwoording/App.css"

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function NotitionDialog({open, onClose, notition}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const valueRef = React.useRef()
  /**
   * Closes dialog with text or without depending on de current valueRef
   */
  function handleClick(){
    if(valueRef.current.value !== notition){
        onClose(valueRef.current.value)
    }
    onClose('')
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",  // Set your width here
              },
            },
          }}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Week Notitie"}
        </DialogTitle>
        <DialogContent>
        <br/>
        <TextField
                        id="outlined-multiline-static"
                        label="Comment"
                        multiline
                        fullWidth
                        rows={7}
                        className='dialog-comment'
                        inputRef={valueRef}
                        defaultValue={notition}
                    />
        </DialogContent>
        <DialogActions>
            <div className='alertaction'>
                <Button onClick={handleClick} autoFocus>
                    Akkoord
                </Button>
                <Button onClick={()=>onClose('')} autoFocus>
                    Sluiten
                </Button>
            </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}