// MaterialenOverzicht.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    FormControlLabel, Checkbox, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions,useMediaQuery, useTheme , TextField, Grid,  Paper,  Typography, CircularProgress, Button
  } from '@mui/material';
import QrCodeScanner from './QrCodeScanner';
import { useMsal } from "@azure/msal-react";
import GereserveerdMateriaal from './GereserveerdMateriaalLijst';  // Importeer de nieuwe component
import MaterialWizard from './MaterialWizard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QrCode2Icon from '@mui/icons-material/QrCodeScanner';
import ReserveringsData from './ReserveringsData';
import PreviousReservationsDialog from './PreviousReservationsDialog';
import ReserveringPopup from './ReserveringPopup';
import DubbeleReserveringenDialog from './DubbeleReserveringenDialog';

const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
//const AIRTABLE_MATERIAL_ENDPOINT = process.env.REACT_APP_AIRTABLE_MATERIAL_ENDPOINT;
//const AIRTABLE_RESERVATION_ENDPOINT = process.env.REACT_APP_AIRTABLE_RESERVATION_ENDPOINT;
const AIRTABLE_MATERIAL_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblu8xOJFShn7U4Lv'
const AIRTABLE_RESERVATION_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tbl1aplX8tnwYIb4M'
const AIRTABLE_PERSONEN_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tbli2xhJ6U2IzdQtx'
const AIRTABLE_JUNCTION_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblWxxnUVWgZsACXa'
const AIRTABLE_MATERIAALSETS_ENDPOINT= 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblOw3Ua6837l60KJ'	
const AIRTABLE_DRAAIDAG_ENDPOINT = `https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblNMUYaehUz9SGMv`;

console.log("AIRTABLE_MATERIAL_ENDPOINT", AIRTABLE_MATERIAL_ENDPOINT);
console.log("AIRTABLE_RESERVATION_ENDPOINT", AIRTABLE_RESERVATION_ENDPOINT);


const HEADERS = {
    Authorization: `Bearer ${API_KEY}`,
  'Content-Type': 'application/json'
};

const CATEGORIES = [
    'Cameras', 'Drones', 'Lens', 'Monitors', 'Statief', 'Geluid', 'Grip', 'Licht',
    'Camera Accessoires', 'Special effects', 'Studio equipment', 'Vervoer en karren'
  ];

export function MaterialenOverzicht({initReservations, fetchAndProcessReservations, setDubbeleReserveringen, handleDeleteReservation, dubbeleReserveringen, fetchReserveringen, geselecteerdeDraaidag, setGeselecteerdeDraaidag, setReservations, reservations, reservationsData }) {

    const [materialen, setMaterialen] = useState([]);
    const [alleMaterialen, setAlleMaterialen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openWizard, setOpenWizard] = useState(false);
    const [currentStepCategorie, setCurrentStepCategorie] = useState(0);
    const [currentStepSet, setCurrentStepSet] = useState([]);
    
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [openQRScanner, setOpenQRScanner] = useState(false);

    // dit zijn alle reserveringen met als index het materiaal ID en als value een array met alle gereserveerde data 
    // zodat je kan checken of materiaal beschikbaar is
    const [scanErrorMessage, setScanErrorMessage] = useState("");
    const [scanSuccessMessage, setScanSuccessMessage] = useState("");

    // State voor het bijhouden van de geselecteerde data
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(geselecteerdeDraaidag.fields.StartDatum));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(geselecteerdeDraaidag.fields.EindDatum));

    // set dubbele reserveringen dialog open
    const [dubbeleReserveringenDialogOpen, setDubbeleReserveringenDialogOpen] = useState(false);

    
    const [geenMateriaalNodig, setGeenMateriaalNodig] = useState(false); // state voor de checkbox 'geen materiaal nodig'

    const [materialsFromPreviousReservation, setMaterialsFromPreviousReservation] = useState([]);

    const [reservedMaterials, setReservedMaterials] = useState([]);
    const [currentReservation, setCurrentReservation] = useState(null); // object of resrvation of draaidag (1)
    const [currentReservationId, setCurrentReservationId] = useState(null); //id of reservation of draaidag (kan er maar 1 zijn)
    const [notitie, setNotitie] = useState(''); // state voor de waarde van het tekstveld
    const [searchTerm, setSearchTerm] = useState("");
    const [searchDialogOpen, setSearchDialogOpen] = useState(false);
    const [email, setEmail] = useState(null);
    const [cameraSets, setCameraSets] = useState([]); // materialen sets uit airtable

    const [activeTab, setActiveTab] = useState(0); // 0 voor categorieën, 1 voor materiaalsets

    // constanten om vorige reserveringen op te halen en er één te selecteren
    const [isPreviousReservationsDialogOpen, setIsPreviousReservationsDialogOpen] = useState(false);
    const [previousReservations, setPreviousReservations] = useState([]);
    const [selectedPreviousReservation, setSelectedPreviousReservation] = useState(null);
    
    const [reserveringPopupOpen, setReserveringPopupOpen] = useState(false);

    // openen van dialog voor het zien van details van een niet beschikbaar materiaal
    const [openReservationDialog, setOpenReservationDialog] = useState(false);

    // geselecteerde reservering voor opzoeken details
    const [overlappingReservations, setOverlappingReservations] = useState([]);

    const { instance } = useMsal();
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('sm'));


    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount) {
            setEmail(activeAccount.username);
        } else {
            setEmail(null);
        }
    }, [activeAccount]);

    // laad materiaal sets uit airtable
    useEffect(() => {
        fetchCameraSets();
    }, []); // Lege dependency array zorgt ervoor dat dit één keer gebeurt bij het laden van de component


    // haal de record ID op van een persoon op basis van het e-mailadres
    const fetchPersonRecordIdByEmail = async (email) => {
        // strip '-' from email adress, because in airtable all users are registerd without '-' after the @
        email = email.replace(/-/g, ""); 
        try {
            const response = await axios.get(
                `${AIRTABLE_PERSONEN_ENDPOINT}?filterByFormula=SEARCH("${email}", {Email})`,
                { headers: HEADERS }
            );
    
            if (response.status === 200 && response.data.records.length > 0) {
                return response.data.records[0].id;
            } else {
                //console.log(email)
                console.error("Geen match gevonden voor e-mail in Personen tabel.");
                return null;
            }
        } catch (error) {
            console.error("Error bij het opzoeken van persoon op basis van e-mail: ", error);
            return null;
        }
    };



    

    // haal materiaalsets op uit airtable
    const fetchCameraSets = async () => {
        try {
            const response = await axios.get(`${AIRTABLE_MATERIAALSETS_ENDPOINT}`, { headers: HEADERS });
            if (response.status === 200) {
                setCameraSets(response.data.records);
                //setCurrentStepSet(response.data.records[0].fields.ProjectType);
                //console.log("typeDraaidagSets2", response.data.records);
            } else {
                console.error("Kon TypeDraaidagSets niet ophalen:", response.status, response.statusText);
            }
        } catch (error) {
            console.error("Fout bij het ophalen van TypeDraaidagSets:", error);
        }
    };

    // update de checkbox 'geen materiaal nodig' in airtable
    const updateGeenMateriaalNodigInAirtable = async (value) => {
        try {
            console
            const response = await axios.patch(
                `${AIRTABLE_DRAAIDAG_ENDPOINT}/${geselecteerdeDraaidag.id}`,
                {
                    fields: {
                        'Geen Materiaal Nodig': value,
                    },
                },
                { headers: HEADERS }
            );
    
            // Handle response if needed
        } catch (error) {
            console.error('Error updating Airtable:', error);
            // Handle error
        }
    };
    

    const fetchMaterialenVanSet = async (typeCameraSet) => {
        let allMaterialen = [];
        let offset = null;
    
        try {
            do {
                const filterFormula = `AND(FIND('${typeCameraSet}', {TypeDraaidagSets}), NOT({Status} = 'Verkocht/gearchiveerd'))`;
                console.log("filterFormula", filterFormula);
                
                const response = await axios.get(`${AIRTABLE_MATERIAL_ENDPOINT}?filterByFormula=${encodeURIComponent(filterFormula)}`, {
                    headers: HEADERS,
                    params: offset ? { offset: offset } : {} // Gebruik de offset als deze bestaat
                });
    
                console.log("response", response);
    
                if (response.status === 200) {
                    allMaterialen = allMaterialen.concat(response.data.records);
                    offset = response.data.offset; // Update de offset voor de volgende batch
                    console.log("Opgehaalde materialen:", response.data.records);
                } else {
                    console.error("Fout bij het ophalen van materialen:", response.status, response.statusText);
                    return null;
                }
    
            } while (offset); // Blijf verzoeken doen zolang er een offset is
    
            // Aanname dat setMaterialen een functie is die elders gedefinieerd is
            setMaterialen(allMaterialen); // Update de state met alle opgehaalde materialen
    
        } catch (error) {
            console.error("Fout bij het ophalen van materialen:", error);
            return null;
        }
    };
    
    

    // Function to fetch materials from a specific reservation
    const fetchMaterialenVanReservering = async (reservationId) => {
        try {
            // Filter formula to find materials related to the specified reservation ID
            console.log("reservationId", reservationId);
            //const filterFormula = `FIND('${reservationId}', ARRAYJOIN({Reservering}))`;
            const filterFormula = `ARRAYJOIN(FILTER({Reservering}, NOT({Status} = 'Verkocht/gearchiveerd')), ',')`;

            const response = await axios.get(`${AIRTABLE_MATERIAL_ENDPOINT}?filterByFormula=${filterFormula}`, { headers: HEADERS });

            if (response.status === 200) {
                console.log("Opgehaalde materialen van reservering:", response.data.records);
                // Update the state with the fetched materials
                setMaterialsFromPreviousReservation(response.data.records);

            } else {
                console.error("Fout bij het ophalen van materialen:", response.status, response.statusText);
                return null;
            }
        } catch (error) {
            console.error("Fout bij het ophalen van materialen:", error);
            return null;
        }
    };


    // haal alle materialen op uit airtable
    async function fetchAlleMaterialen() {
        try {
        
            let recordsAllMaterials = [];
            let offset = null;
        
            do {
            try {
                const filterFormula = "NOT({Status} = 'Verkocht/gearchiveerd')";
                const apiUrl = `${AIRTABLE_MATERIAL_ENDPOINT}?filterByFormula=${encodeURIComponent(filterFormula)}`;


                const response = await axios.get(apiUrl, {
                headers: HEADERS,
                params: offset ? { offset: offset } : {}
                });
        
                recordsAllMaterials = [...recordsAllMaterials, ...response.data.records];
                console.log("recordsAllMaterials", recordsAllMaterials);
                offset = response.data.offset; // Airtable geeft 'offset' alleen als er nog meer records zijn
                console.log("offset", offset);
            } catch (error) {
                console.error("Er ging iets mis met het ophalen van de data:", error);
                break; // Stop met laden als er een fout optreedt
            }
            } while (offset); // Doorgaan met laden zolang er een 'offset' is
        

        // Sorteer op aantalReserveringen
        const sortedRecords = recordsAllMaterials.sort((a, b) => {
            // Veronderstel dat aantalReserveringen een numeriek veld is. Gebruik 0 als fallback.
            const reserveringenA = a.fields.aantalReserveringen || 0;
            const reserveringenB = b.fields.aantalReserveringen || 0;

            // Voor numerieke waarden, retourneer het verschil
            return reserveringenA - reserveringenB;
        });

        setAlleMaterialen(sortedRecords);
        
        } catch (error) {
            console.error("Er ging iets mis met het ophalen van de materialen:", error);
        } finally {
            setLoading(false);
        }
    }

    // haal de junction record op van een materiaal op basis van het materiaal ID en de reservering ID uit Airtable
    const fetchJunctionRecordByMaterialID = async (materialID) => {
        // Implement the logic to find the junction record by material ID
        // This might involve fetching data from Airtable or your backend
        // Return the junction record

        try {
            const response = await fetch(`${AIRTABLE_JUNCTION_ENDPOINT}?filterByFormula=AND({MateriaalID}='${materialID}', {ReserveringID}='${currentReservationId}')`, {
                headers: HEADERS
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            const records = data.records;
            console.log("records", records);
            // Assuming we are interested in the first matching record
            return records.length > 0 ? records[0] : null;
        } catch (error) {
            console.error('Error fetching junction record:', error);
            return null;
        }
    };

    const handleDeleteDraaidag = async (draaidagId) => {
        if (!window.confirm("Weet je zeker dat deze draaidag uit Airtable verwijderd kan worden?")) {
            return; // Early exit if user cancels the action
        }

        try {
            const response = await axios.delete(`${AIRTABLE_DRAAIDAG_ENDPOINT}/${draaidagId}`, { headers: HEADERS });

            if (response.status === 200) {
                console.log("Draaidag deleted successfully");
                handleGoBack();
            } else {
                throw new Error(`Failed to delete Draaidag: ${response.status}`);
            }
        } catch (error) {
            console.error("Error deleting Draaidag:", error);
            // Optionally, inform the user that the deletion failed
        }
    };


    // Controleert of materiaal beschikbaar is behorende bij huidige reserveringsdata
    const isMaterialAvailable = (record, currentReservations) => {
        if (record){
            const reservedDatesForMaterial = currentReservations[record.id];
            //console.log("currentreservations", currentReservations);
            // If there's no reservation data for the material, assume it's available.
            if (!reservedDatesForMaterial) return true;
    
            // If the material is part of the currentReservation, it's available to deselect
            if (currentReservation && currentReservation.fields.Materiaal){
                if (currentReservation.fields.Materiaal.includes(record.id)) {
                    return true;
                }
            }

            // Bepaal de start- en einddatum op basis van het bestaan van currentReservation
            let startDatum = currentReservation && currentReservation.fields && currentReservation.fields.Start
            ? new Date(currentReservation.fields.Start)
            : new Date(geselecteerdeDraaidag.fields.StartDatum);

            let eindDatum = currentReservation && currentReservation.fields && currentReservation.fields.Eind
            ? new Date(currentReservation.fields.Eind)
            : new Date(geselecteerdeDraaidag.fields.EindDatum);


            
            // Controleer of de einddatum eerder is dan de begindatum
            if (eindDatum < startDatum) {
                // Maak de einddatum gelijk aan de begindatum (wil wel eens gebeuren bij een draaidag van 1 dag)
                eindDatum = startDatum;
            }

            // Get all the dates between StartDatum and Eind datum
            const selectedRange = getDatesInRange(startDatum, eindDatum);
            
            // Check if any date in the selectedRange is reserved.
            for (const date of selectedRange) {
                if (reservedDatesForMaterial.includes(date)) {
                    return false;  // Material is not available if any date in the range is reserved.
                }
            }
            //console.log("selectedRange", selectedRange);
            //console.log("reservedDatesForMaterial", reservedDatesForMaterial);
            // If no dates in the range are reserved, then the material is available.
            return true;
    
        } else {
            //console.log("isavailable record is null")
            return false;
        }
    };
    
    // reken alle data uit tussen start en einddatum
    const getDatesInRange = (start, end) => {
        const dateArray = [];
        let currentDate = new Date(start);
        const stopDate = new Date(end);
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate).toISOString().split('T')[0]);  // Formatteert de datum naar het formaat 'YYYY-MM-DD'
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    };
    

    const handleMaterialToggle = (record) => {
        //const record = alleMaterialen.find(mat => mat.id === id); // find the associated record
        if (!isMaterialAvailable(record, reservations)) return; // Early exit

        const currentIndex = selectedMaterials.indexOf(record.id);
        const newSelectedMaterials = [...selectedMaterials];
        if (currentIndex === -1) {
            newSelectedMaterials.push(record.id);
        } else {
            newSelectedMaterials.splice(currentIndex, 1);
        }
        setSelectedMaterials(newSelectedMaterials);

    };

    const isMaterialInCurrentCategoryOrSet = (material) => {
        if (activeTab === 0) { // Assuming 0 is the index for categories
            return material.category === currentStepCategorie; // Adjust this condition based on your data structure
        } else if (activeTab === 1) { // Assuming 1 is the index for sets
            return material.set === currentStepSet; // Adjust this condition based on your data structure
        }
        return false;
    };
    

    const handleSelectAllAvailable = () => {
        console.log("handleSelectAllAvailable");
        // Filter materials based on availability and current category or set
        console.log("materialen", materialen);
        console.log("activeTab", activeTab);
        console.log("currentStepCategorie", currentStepCategorie);
        console.log("currentStepSet", currentStepSet);
        const availableMaterialIds = materialen
            .filter(material => isMaterialAvailable(material, reservations))
            .map(material => material.id);
    
        // Combine current selected materials with available ones, avoiding duplicates
        const combinedSelection = Array.from(new Set([...selectedMaterials, ...availableMaterialIds]));
        console.log("combinedSelection", combinedSelection);
        setSelectedMaterials(combinedSelection);
    };
    
    const handleUnselectAll = () => {
        setSelectedMaterials([]);
    };     


    const handleGeenMateriaalNodigChange = async (event) => {
        setGeenMateriaalNodig(event.target.checked);
        await updateGeenMateriaalNodigInAirtable(event.target.checked);
    };
    
    
    const updateReservering = async (reservationId, startDatum, eindDatum) => {
        try {
            const personRecordId = await fetchPersonRecordIdByEmail(email);
            const response = await axios.patch(
                `${AIRTABLE_RESERVATION_ENDPOINT}/${reservationId}`,
                {
                    fields: {
                        "Notities": notitie, // Ensure 'notitie' is defined in the correct scope
                        "Start": startDatum,
                        "Eind": eindDatum,
                        "ModifiedBy": [personRecordId] // Ensure 'personRecordId' is defined in the correct scope
                    }
                },
                { headers: HEADERS } // Ensure 'HEADERS' is defined in the correct scope
            );
    
            if (response.status < 200 || response.status >= 300) {
                console.error("Er is iets misgegaan bij het bijwerken van de reservering. Status Code:", response.status);
                return false;
            }
            
            return true; // Indicate success
        } catch (error) {
            console.error("Er is iets misgegaan bij het bijwerken van de reservering.", error);
            return false; // Indicate failure
        }
    };
    
 
    async function fetchAllAssociations(reservationId) {
        let allAssociations = [];
        let offset = null;
    
        try {
            do {
                const encodedFormula = encodeURIComponent(`{ReserveringID}='${reservationId}'`);
                const params = offset ? { offset: offset } : {}; // Voeg de offset toe aan de params als deze bestaat
                const responseAssociations = await axios.get(
                    `${AIRTABLE_JUNCTION_ENDPOINT}?filterByFormula=${encodedFormula}`,
                    {
                        headers: HEADERS,
                        params: params // Voeg de params toe aan de request
                    }
                );
    
                if (responseAssociations.status === 200 && responseAssociations.data.records) {
                    allAssociations = allAssociations.concat(responseAssociations.data.records);
                    offset = responseAssociations.data.offset; // Update de offset voor de volgende batch
                } else {
                    console.error("Fout bij het ophalen van associaties:", responseAssociations.status, responseAssociations.statusText);
                    return null;
                }
            } while (offset); // Blijf data ophalen zolang er een offset is
    
            return allAssociations;
        } catch (error) {
            console.error("Error fetching associations:", error);
            return null;
        }
    }
    

    //  maak reservering aan of update bestaande reservering
    const postOrUpdateReservering = async (startDatumReservering, eindDatumReservering, materialen) => {
        try {
            const uniqueMaterials = [...new Set(materialen)];
            
            const personRecordId = await fetchPersonRecordIdByEmail(email);

            let startDatum = new Date(startDatumReservering);
            let eindDatum = new Date(eindDatumReservering);

            // Controleer de beschikbaarheid van elk materiaal
            // update reservations array
            //console.log("reservationsUKbefore", reservations);

            const currentReservations = await fetchAndProcessReservations();

            //console.log("reservationsUKafter", reservations);
            for (const materialId of uniqueMaterials) {
                //console.log('materialIDUK', materialId)

                const materialRecord = alleMaterialen.find(m => m.id === materialId);
                //console.log('materialrecordUK', materialRecord)
                console.log('materialrecordreserverddataUK', reservations[materialId])

                if (!isMaterialAvailable(materialRecord, currentReservations)) {
                    alert(`Het materiaal ${materialRecord.fields.Naam} is niet meer beschikbaar. Waarschijnklijk is het materiaal tegelijkertijd door een andere gebruik gereserveerd. Deselecteer het materiaal en probeer dan de reservering op te slaan.`);
                    return; // Stop de functie als een materiaal niet beschikbaar is
                }
            }

            
            if (!personRecordId) {
                console.error("Kan geen reservering maken zonder geldige Submitter.");
                alert("Sorry, we can't process your reservation without valid submitter information. Please check your details and try again.");
                return;
            }
    
            const draaidagId = geselecteerdeDraaidag.id; // Zorg ervoor dat 'geselecteerdeDraaidag' correct is gedefinieerd
            //handleDubbeleReserveringenDialog(draaidagId, currentReservationId);
            const fetchedReserveringen = await fetchReserveringen(geselecteerdeDraaidag.id);
            console.log("fetcheddubbeleReserveringen", fetchedReserveringen);
            if (Array.isArray(fetchedReserveringen.data.records)) {
                setDubbeleReserveringen(fetchedReserveringen.data.records);
              } else {
                console.error('Data is not an array:', fetchedReserveringen.data.records);
                setDubbeleReserveringen([]); // Zet een lege array als fallback
              }
            console.log("fetcheddubbeleReserveringen", fetchedReserveringen.data.records);
            if (fetchedReserveringen.data.records.length >= 2) {
                  setDubbeleReserveringenDialogOpen(true);
                return;
            } 
       

            let reservationId = currentReservationId;
    
            if (reservationId) {
                // Update the existing reservation
                const response = await axios.patch(
                    `${AIRTABLE_RESERVATION_ENDPOINT}/${reservationId}`,
                    {
                        fields: {
                            "Notities": notitie,
                            "ModifiedBy": [personRecordId],
                            "Start": startDatum,
                            "Eind": eindDatum,                           

                        }
                    },
                    { headers: HEADERS }
                );
    
                if (response.status !== 200) {
                    console.error("Er is iets misgegaan bij het bijwerken van de reservering.");
                    return;
                }
            } else {
                console.log("New reservation")
                // Neem data van draaidag over. 
                let startDatum = new Date(geselecteerdeDraaidag.fields.StartDatum);
                let eindDatum = new Date(geselecteerdeDraaidag.fields.EindDatum);

                // Controleer of de einddatum eerder is dan de begindatum
                if (eindDatum < startDatum) {
                    // Maak de einddatum gelijk aan de begindatum
                    eindDatum = startDatum;
                }

                // Create a new reservation
                const response = await axios.post(
                    AIRTABLE_RESERVATION_ENDPOINT,
                    {
                        fields: {
                            "DraaidagOutlook": [geselecteerdeDraaidag.id],
                            "Start": startDatum,
                            "Eind": eindDatum,
                            "Notities": notitie,
                            "OrigineleDraaidagNaam": geselecteerdeDraaidag.fields.Draaidag,
                            "Submitter": [personRecordId]
                        }
                    },
                    { headers: HEADERS }
                );
    
                if (response.status !== 200) {
                    console.error("Er is iets misgegaan bij het opslaan van de nieuwe reservering.");
                    return;
                }
                console.log("reservering aangemaakt, response:", response);
                reservationId = response.data.id; // Extract the newly created reservation's ID
            }
   
 
            
            //const existingMaterialAssociations = responseAssociations.data.records.map(record => record.fields.Materiaal[0]);
            const existingMaterialAssociations = await fetchAllAssociations(reservationId);

            console.log("existingMaterialAssociations", existingMaterialAssociations);
            console.log("reservationId", reservationId);

            // For each unique material, check if an association already exists
            for (const material of uniqueMaterials) {
                if (!existingMaterialAssociations.includes(material)) {
                    await axios.post(
                        AIRTABLE_JUNCTION_ENDPOINT,
                        {
                            fields: {
                                "Reservering": [reservationId],
                                "Materiaal": [material]
                            }
                        },
                        { headers: HEADERS }
                    );
                    console.log("New junction record created for material", material);
                }
            }

            // Check for associations that need to be deleted
            const recordsToDelete = existingMaterialAssociations.filter(record => !uniqueMaterials.includes(record.fields.Materiaal[0]));

            for (const record of recordsToDelete) {
                await axios.delete(`${AIRTABLE_JUNCTION_ENDPOINT}/${record.id}`, { headers: HEADERS });
                console.log("Deleted junction record:", record.id);
            }
    
            
            console.log("Reservering succesvol verwerkt!");
            setOpenWizard(false);
            fetchReservedMaterials(geselecteerdeDraaidag.id);

            // Update alle materialen
            fetchAlleMaterialen();


            // Uncheck the checkbox "Geen materiaal nodig" want er is nu materiaal geselecteerd
            setGeenMateriaalNodig(false);

            // Call function to update in Airtable the checkbox
            await updateGeenMateriaalNodigInAirtable(false);

        } catch (error) {
            console.error("Error managing reservation: ", error);
        }
    };
 

    

    const fetchPreviousReservations = async () => {
        try {
            const reservationURL = `${AIRTABLE_RESERVATION_ENDPOINT}`;
            const response = await axios.get(reservationURL, { headers: HEADERS });
            console.log("response", response);  
            if (response.status === 200) {
                // Assuming the response data has a format { records: [{...}, {...}] }
                const fetchedReservations = response.data.records.map(record => ({
                    IDdraaidag: record.fields.IDdraaidag,
                    naam: record.fields.DraaidagnaamProjectNaam, // Replace 'Name' with the actual field name of reservation's name
                    startDatum: record.fields.Start, // Replace 'Date' with the actual field name of reservation's date
                    eindDatum: record.fields.Eind, // Replace 'Date' with the actual field name of reservation's date

                    // Add other necessary fields
                }));
                setPreviousReservations(fetchedReservations);
                console.log("fetchedReservations", fetchedReservations);
            } else {
                console.error("Failed to fetch reservations: ", response.status, response.statusText);
                // Handle non-200 response (display message to user or similar)
            }
        } catch (error) {
            console.error("Error fetching previous reservations: ", error);
            // Handle error (display error message to user or similar)
        }
    };
    

      
    const handleSelectPreviousReservation = async (selectedReservation) => {
        // Assuming selectedReservation is the reservation object with an ID
        if (selectedReservation && selectedReservation.id) {
            try {
                // Call the function to fetch materials from the reservation
                //await fetchMaterialenVanReservering(selectedReservation.id);
                const materialPreviousReservation=await fetchJunctionRecords(selectedReservation.id)
                console.log("materialPreviousReservation", materialPreviousReservation);
                // Update the state with selected reservation details
                //setSelectedReservation(selectedReservation);
                
                
                setIsPreviousReservationsDialogOpen(false); // Close the dialog after selection
    
                // Now you may need to update the UI to show these materials
                // For instance, you might set them as selected materials or something similar
                // This depends on how your component and state are structured
            } catch (error) {
                console.error("Error fetching materials for previous reservation:", error);
                // Handle the error appropriately
            }
        }
    };
    
   

    // update de status van een materiaal naar 'Gepakt' als het gescand wordt
    const handleScannedData = async (scannedData) => {
        console.log("Scanned data:", scannedData);
        try {
            // Validate the scanned data
            if (!scannedData || typeof scannedData !== 'string') {
                throw new Error('Scanned data is invalid.');
            }
            
            // Assuming the scannedData is the URL of the QR code
            //const url = new URL(scannedData);
            const materialID = scannedData; // Extract the ID from the URL
    
            if (!materialID) {
                throw new Error('QR code does not contain valid material ID.');
            }
    
            // Find the corresponding junction record
            const junctionRecord = await fetchJunctionRecordByMaterialID(materialID);
    
            if (!junctionRecord) {
                console.error("No corresponding junction record found.");
                setScanErrorMessage("Gescande materiaal staat niet in de reservering.");
                return;
            }
            
            
            const currentStatuses = junctionRecord.fields.StatusJunction || [];
            console.log("currentStatuses", currentStatuses);
            
            console.log("junctionRecord");
            const isGepaktIncluded = currentStatuses.includes("Gepakt");
            
            let updatedStatuses;
            if (!isGepaktIncluded) {
                updatedStatuses = [...currentStatuses, "Gepakt"];
            } else {
                // If "Gepakt" is already there, no need to add it again, just use the current statuses
                updatedStatuses = currentStatuses;
            }
            // Update the StatusJunction field to 'Gepakt'
            console.log("updatedStatuses", updatedStatuses);
            console.log("Materialen", materialen);	
            const responseJunction = await updateJunctionStatus(junctionRecord.id, updatedStatuses);
            if (responseJunction && responseJunction.ok) {
                // Close the QR scanner dialog
                console.log(junctionRecord.fields.JunctionID + " heeft status Gepakt gekregen.")
                setScanSuccessMessage(junctionRecord.fields.JunctionID + "heeft status Gepakt gekregen.");
                // Update the materials state
                setReservedMaterials(prevMaterials => prevMaterials.map(material => {
                    if (material.fields.MateriaalID === junctionRecord.fields.MateriaalID) {
                        return {
                            ...material,
                            fields: {
                                ...material.fields,
                                JunctionStatus: updatedStatuses
                            }
                        };
                    }
                    return material;
                }));
                
            } else {
                // Handle the situation where the response is not successful
                console.error('Failed to update the status.');
                // Optionally, show an error message to the user
            }
        } catch (error) {
            console.error("Error processing scanned data:", error);
        }
    };

    // update de statussen van een materiaal naar selectie van de gebruiker
    const updateJunctionStatus = async (recordId, selectedStatuses) => {
        try {
            const response = await fetch(`${AIRTABLE_JUNCTION_ENDPOINT}/${recordId}`, {
                method: 'PATCH',
                headers: HEADERS,
                body: JSON.stringify({
                    fields: {
                        "StatusJunction": selectedStatuses
                    },
                }),
            });

            if (response.error) {
                throw new Error(response.error.message);
            }

            return response;
        } catch (error) {
            console.error('Error updating Airtable Junction Table:', error);
        }
    };
  
    // haal alle reserveringen van draaidag: geselecteerdeDraaidag.id 
    const fetchReservationData = async (draaidagId) => {
        const reservationURL = `${AIRTABLE_RESERVATION_ENDPOINT}?filterByFormula={IDdraaidagOutlook}='${draaidagId}'`;
        const response = await axios.get(reservationURL, { headers: HEADERS });
        console.log("Reservering opgehaald:", response);
        return response.data.records[0]; // Returns the first reservation record
    };

    // haal alle materialen op van een reservering
    const fetchJunctionRecords = async (reservationId) => {
        const filterFormula = encodeURIComponent(`{ReserveringID}='${reservationId}'`);
        const junctionURL = `${AIRTABLE_JUNCTION_ENDPOINT}?filterByFormula=${filterFormula}`;
        const response = await axios.get(junctionURL, { headers: HEADERS });
        return response.data.records; // Returns an array of junction records
    };

    // haal alle materialen informatie op van een materiaal ID
    const fetchMaterialData = async (materialIds) => {
        const materialsURL = `${AIRTABLE_MATERIAL_ENDPOINT}?filterByFormula=OR(${materialIds.map(id => `RECORD_ID()='${id}'`).join(",")})`;
        const response = await axios.get(materialsURL, { headers: HEADERS });
        return response.data.records; // Returns an array of material records
    };

    const fetchAndProcessJunctionRecords = async (reservationId) => {
        const filterFormula = encodeURIComponent(`{ReserveringID}='${reservationId}'`);
        const junctionURL = `${AIRTABLE_JUNCTION_ENDPOINT}?filterByFormula=${filterFormula}`;
        let allRecords = [];
        let offset = null;
    
        try {
            do {
                const params = offset ? { offset: offset } : {};
                const response = await axios.get(junctionURL, {
                    headers: HEADERS,
                    params: params
                });
    
                if (response.status !== 200) {
                    throw new Error("Failed to fetch junction records");
                }
    
                allRecords = allRecords.concat(response.data.records);
                offset = response.data.offset;
            } while (offset);
    
            const materialToJunctionStatus = {};
            const materialToJunctionRecordId = {};
    
            allRecords.forEach(record => {
                const materialId = record.fields.Materiaal && record.fields.Materiaal[0];
                if (materialId) {
                    materialToJunctionStatus[materialId] = record.fields.StatusJunction || [];
                    materialToJunctionRecordId[materialId] = record.id;
                }
            });
    
            return { materialToJunctionStatus, materialToJunctionRecordId };
    
        } catch (error) {
            console.error("Error in fetchAndProcessJunctionRecords:", error);
            return { materialToJunctionStatus: {}, materialToJunctionRecordId: {} };
        }
    };
    
    

    // haal gereserveerde materialen op met ID van draaidag, dit wordt gedaan na het opslaan van een reservering maar 
    // ook bij het opstarten van de pagina
    const fetchReservedMaterials = async (draaidagId) => {
        if (!draaidagId) {
            console.error("No draaidagId provided");
            return;  // Exit early if there's no draaidagId
        }
        
        try {
            // 1. Fetch reservation data
           
            // set current reservation id and reservation, assuming there's only one reservation per draaidagid
            // it's used to check if a material is available or not -> if the material is part of the current reservation, 
            // it's available to deselect
            const reservation = await fetchReservationData(draaidagId);
            console.log("reservation fetch, CurrentReservation", reservation)
            setCurrentReservation(reservation);
            setCurrentReservationId(reservation.id);
            setNotitie(reservation.fields.Notities);
    
            
            //setNotitie(reservationDraaidag.data.records[0].fields.Notities);

            // 2. Fetch all materials from reservation    
            // Initialize an empty array to store unique IDs of reserved materials.
            const reservedMaterialsIds = [];

            // Initialize an empty object to store reservation data based on material ID.
            const reservationsData = {};

            // Loop through each record in the reservationDraaidag data.
                
            // Check if the current record has a "Materiaal" field and if it's an array.
            if (reservation.fields.Materiaal && Array.isArray(reservation.fields.Materiaal)) {
                
                // Loop through each material ID in the "Materiaal" field.
                reservation.fields.Materiaal.forEach(materialId => {
                    // If the material ID is not yet in the reservedMaterialsIds array, add it.
                    if (!reservedMaterialsIds.includes(materialId)) {
                        reservedMaterialsIds.push(materialId);
                    }

                    // Initialize an array for the current material ID in reservationsData if it doesn't exist.
                    if (!reservationsData[materialId]) {
                        reservationsData[materialId] = [];
                    }
                    
                    // If the current record has a "tussenData" field, split its comma-separated values and add them to the reservationsData for the current material ID.
                    if (reservation.fields.tussenData) {
                        reservationsData[materialId].push(...reservation.fields.tussenData.split(', '));
                    }
                });
            }

            // Fetch junction records based on Material IDs
            
            const { materialToJunctionStatus, materialToJunctionRecordId } = await fetchAndProcessJunctionRecords(reservation.id);


            // Fetch actual material data for each material
            const materialsURL = `${AIRTABLE_MATERIAL_ENDPOINT}?filterByFormula=OR(${reservedMaterialsIds.map(id => `RECORD_ID()='${id}'`).join(",")})`;
            const materialsResponse = await axios.get(materialsURL, { headers: HEADERS });

            // Attach StatusJunction and reservationsData to the fetched materials
            materialsResponse.data.records.forEach(record => {
                record.fields.JunctionStatus = materialToJunctionStatus[record.id] || [];
                record.fields.JunctionID = materialToJunctionRecordId[record.id] || [];
                record.fields.reservationsData = reservationsData[record.id] || [];
            });
            console.log("materialsResponse", materialsResponse);

            // sort the selected materials
            if (materialsResponse.data && materialsResponse.data.records) {
                const sortedRecords = materialsResponse.data.records.sort((a, b) => {
                    const naamA = a.fields.ID || '';
                    const naamB = b.fields.ID || '';
                    return naamA.localeCompare(naamB);
                });
                
                const sortedIds = sortedRecords.map(record => record.id);
                setSelectedMaterials(sortedIds);
                setReservedMaterials(sortedRecords);
            }
    
        } catch (error) {
            console.error("Error fetching reserved materials. Probably there is no reservation yet.", error);
        }
    };
    

    const fetchAndSortMaterialen = async (currentStepCategorie) => {
        try {
            const categorieFilter = `Categorie="${CATEGORIES[currentStepCategorie]}"`;
            const statusFilter = 'NOT({Status} = "Verkocht/gearchiveerd")';
            const filterFormula = `AND(${categorieFilter}, ${statusFilter})`;
            const apiUrl = `${AIRTABLE_MATERIAL_ENDPOINT}?filterByFormula=${encodeURIComponent(filterFormula)}`;
                   

            const response = await axios.get(
                apiUrl,
                { headers: HEADERS }
            );     
            
            // Sorteer op aantalReserveringen
            const sortedRecords = response.data.records.sort((a, b) => {
                const reserveringenA = a.fields.aantalReserveringen || 0;
                const reserveringenB = b.fields.aantalReserveringen || 0;
                return reserveringenA - reserveringenB;
            });
    
            return sortedRecords;
        } catch (error) {
            console.error("Er ging iets mis met het ophalen van de materialen:", error);
            return []; // Terugkeer van een lege array bij een fout
        }
    };

   

    const fetchMaterialen = async (currentStepCategorie) => {
        try {
            const sortedMaterialen = await fetchAndSortMaterialen(currentStepCategorie);
            setMaterialen(sortedMaterialen);
        } catch (error) {
            console.error("Er ging iets mis tijdens het ophalen van materialen en reserveringen:", error);
        } finally {
            setLoading(false);
        }
    };
        
    // update de checkbox 'geen materiaal nodig' gebaseerd op waarde in airtable
    useEffect(() => {
        if (geselecteerdeDraaidag.fields && geselecteerdeDraaidag.fields['Geen Materiaal Nodig'] !== undefined) {
            setGeenMateriaalNodig(geselecteerdeDraaidag.fields['Geen Materiaal Nodig']);
        }
    }, [geselecteerdeDraaidag]);
    

    // haal materialen per CATEGORIE op van de wizard step en plaats de gereserveerde data in een array met materiaal ID als key   
    useEffect(() => {
        if (geselecteerdeDraaidag) {
            fetchMaterialen(currentStepCategorie);
        }
    }, [currentStepCategorie, geselecteerdeDraaidag]);

    useEffect(() => {
            //console.log("typeDraaidagSets33", typeDraaidagSets);	
            //const setTypeDraaidagSetId = currentStepSet; // Vervang met de werkelijke ID van de set
            console.log("currentStepSet", currentStepSet);
            fetchMaterialenVanSet(currentStepSet);
    }, [currentStepSet]);
  
    // haal alle materialen op en niet alleen in één categorie, voor zoekfunctie
    useEffect(() => {

        if (geselecteerdeDraaidag) {
            fetchAlleMaterialen();
            fetchReservedMaterials(geselecteerdeDraaidag.id);
        }

    }, [geselecteerdeDraaidag]);


    const formatDate = (datumString) => {
        const datum = new Date(datumString);
        return datum.toLocaleDateString('nl-NL', {
            weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'
        });
    };    

    const handleCloseWizard = () => {
        setOpenWizard(false);
        setCurrentStepCategorie(0);
        setCurrentStepSet(0);
    };

    // Functie om de dialog te openen met de gevonden reserveringen
    const handleDubbeleReserveringenDialog = async () => {
        const fetchedReserveringen = await fetchReserveringen(geselecteerdeDraaidag.id);
        if (fetchedReserveringen.length >= 2) {
            setDubbeleReserveringen(fetchedReserveringen);
            setDubbeleReserveringenDialogOpen(true);
        } 
    };

    const handleOpenPreviousReservationsDialog = () => {
        fetchPreviousReservations();
        setIsPreviousReservationsDialogOpen(true);
    };
      

    useEffect(() => {
        // Zorg ervoor dat de cameraSets geladen zijn en dat we op het tabblad 'Materiaalsets' zitten

        if (activeTab === 1 && cameraSets.length > 0) {
            // Stel de eerste cameraSet in als de standaardwaarde
            setCurrentStepSet(cameraSets[0].fields.ProjectType);
            fetchMaterialenVanSet(cameraSets[0].fields.ProjectType);
        } else
        if (activeTab === 0) {
            setCurrentStepCategorie(0);
            fetchMaterialen(0);
        }
    }, [activeTab, cameraSets]); // Voeg activeTab en cameraSets toe als dependencies
    

    const handleNextStep = () => {
        if (currentStepCategorie < CATEGORIES.length - 1) {
            setCurrentStepCategorie(currentStepCategorie + 1);
        } else {
        // Opslaan en wizard sluiten
        handleCloseWizard();
        }
    };

    const handlePreviousStep = () => {
        if (currentStepCategorie > 0) {
        setCurrentStepCategorie(currentStepCategorie - 1);
        }
    };

    const handleOpenWizard = async () => {       
        // initialiseer voor zekerheid nog even alle reserveringen zodat beschikbaarheid correct is
        await initReservations();
        setOpenWizard(true);
    }

    const handleGoBack = () => {
        setGeselecteerdeDraaidag(null); // Assuming you have a state update function like this
        setSelectedMaterials([]);  // Assuming you have a setSelectedMaterials function
        setReservedMaterials([]); // And a setReservedMaterials function
    };

    const handleOpenScanner = () => {
        setScanErrorMessage(""); // Reset error message
        setScanSuccessMessage(""); // Reset success message
        setOpenQRScanner(true); // Open the scanner
    };

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);  
    };

    const extraSmallButtonStyle = {
        padding: '2px 6px', // Reduce padding
        fontSize: '0.6rem', // Reduce font size
        minWidth: '30px', // Set a minimum width
        margin: '0 5px',  // Adjust spacing between buttons
    };

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const handleMaterialClickForOverlap = async (material) => {
        if (!material || !material.fields) {
            console.error("Invalid material data.");
            return;
        }
        console.log('material', material);
    
        try {
            if (!material.fields.ReserveringID || !Array.isArray(material.fields.ReserveringID)) {
                console.error("Missing or invalid reservations in the material.");
                return;
            }
    
            const selectedStartDate = currentReservation && currentReservation.fields && currentReservation.fields.Start
                ? new Date(currentReservation.fields.Start)
                : new Date(geselecteerdeDraaidag.fields.StartDatum);
    
            const selectedEndDate = currentReservation && currentReservation.fields && currentReservation.fields.Eind
                ? new Date(currentReservation.fields.Eind)
                : new Date(geselecteerdeDraaidag.fields.EindDatum);
    
            selectedStartDate.setHours(0, 0, 0, 0); // Set to start of the day
            selectedEndDate.setHours(23, 59, 59, 999); // Set to end of the day
    
            console.log("reservationsData", reservationsData);
            const validReservations = material.fields.ReserveringID     
            .map(reservationId => {
                // Vind de overeenkomstige reservering in de array `reservationsData`
                const reservation = reservationsData.find(r => r.id === reservationId);
                console.log("find", reservationId);
                console.log("reservationfind", reservation);
    
                // Als de reservering gevonden is, formatteer en retourneer de benodigde gegevens
                if (reservation) {
                    return {
                        startDatum: new Date(reservation.fields.Start), // Veronderstel dat het veld 'Start' de startdatum bevat
                        eindDatum: new Date(reservation.fields.Eind), // Veronderstel dat het veld 'Eind' de einddatum bevat
                        draaidagNaam: reservation.fields.DraaidagNaam // Veronderstel dat 'Draaidagnaam' de naam van de draaidagreservering bevat
                    };
                }
                return null; // Retourneer null voor niet-gevonden reserveringen (deze worden later gefilterd)
            })
            .filter(reservation => reservation !== null); // Verwijder null waarden uit de lijst
    
            console.log("Valid reservations:", validReservations);
            setOverlappingReservations(validReservations);
            setOpenReservationDialog(true);
        } catch (error) {
            console.error("Error processing reservation data:", error);
        }
    };
    
    
    

    const parseDateFromReservation = (reservationString) => {
        // Controleer of reservationString een string is en niet leeg
        if (typeof reservationString !== 'string' || reservationString.trim() === '') {
            return null;
        }
    
        const dateMatch = reservationString.match(/^(\d{2})\/(\d{2})\/(\d{2})/);
        if (!dateMatch) return null;
    
        const [_, day, month, year] = dateMatch.map(Number);
        const fullYear = 2000 + year; // Pas aan op basis van je jaarformaat
        return new Date(fullYear, month - 1, day);
    };
    

    useEffect(() => {
        // Deze code wordt uitgevoerd wanneer de component wordt gemount
        // en ook elke keer dat 'currentReservation' verandert.
        console.log('currentReservation log use-effect:', currentReservation);
    }, [currentReservation]);  // Zorg ervoor dat je 'currentReservation' als dependency toevoegt


  if (!geselecteerdeDraaidag) return <Typography>Selecteer eerst een draaidag</Typography>;
  if (loading) return <CircularProgress />;

  //console.log("typeDraaidagSets voor render materialenoverzicht:", typeDraaidagSets);
  //console.log("camaraSets[0].fields.ProjectType:", cameraSets[0].fields.ProjectType);


  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh', padding: '5px' }}>
      <Grid item xs={11} md={8} lg={6}>
        <Paper elevation={3} style={{ padding: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}>
                <Button
                    onClick={handleGoBack}
                    color="secondary"
                    variant="contained" 
                    size="small"
                    style={{ marginRight: '8px' }}

                >
                    <ArrowBackIcon />
                </Button>
                {!isXsDown && (
                    // Only show the draaidag name on larger screens
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle2" gutterBottom style={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {geselecteerdeDraaidag.fields?.Draaidag}
                            {
                                geselecteerdeDraaidag.fields?.StartDatum === geselecteerdeDraaidag.fields?.EindDatum ?
                                ` (${formatDate(geselecteerdeDraaidag.fields?.StartDatum)})` :
                                ` (${formatDate(geselecteerdeDraaidag.fields?.StartDatum)} - ${formatDate(geselecteerdeDraaidag.fields.EindDatum)})`
                            }
                        </Typography>
                        {selectedMaterials.length === 0 && ( 
                            // only show delete button when there are no selected materials
                            <>
                            <Button 
                                color="error" 
                                variant="outlined" 
                                style={extraSmallButtonStyle}
                                onClick={() => handleDeleteDraaidag(geselecteerdeDraaidag.id)}
                            >
                                Delete Draaidag
                            </Button>
                            {/*
                                <Button 
                                    variant="outlined" 
                                    style={extraSmallButtonStyle}
                                    onClick={() => handleOpenPreviousReservationsDialog()}
                                >
                                    Copy Reservation
                                </Button> 
                            </>
                            */}

                            </>                            
                        )}
                    </div>


                )}
            </div>

            
            <ReserveringsData
                geselecteerdeDraaidag={geselecteerdeDraaidag}
                currentReservation={currentReservation}
                updateReservering={updateReservering}
                getDatesInRange={getDatesInRange}
                reservations={reservations}
                postOrUpdateReservering={postOrUpdateReservering}
                notitie={notitie}
                setNotitie={setNotitie}
                reservedMaterials={reservedMaterials}
                handleMaterialClickForOverlap={handleMaterialClickForOverlap}
                overlappingReservations={overlappingReservations}
                openReservationDialog={openReservationDialog}
                setOpenReservationDialog={setOpenReservationDialog}
                selectedMaterials={selectedMaterials}
                setSelectedMaterials={setSelectedMaterials}                
            />
            




            {selectedMaterials.length > 0 && (       
                // show buttons only when there are selected materials                 
                <Grid container justifyContent="flex-end" style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleOpenWizard}
                        >
                            {isXsDown ? 'Wijzig' : 'Wijzig materiaal'}
                        </Button>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={handleOpenScanner}
                        >
                            <QrCode2Icon />
                        </Button>
                    </Grid>
                </Grid>
            )}

            <GereserveerdMateriaal 
                reservedMaterials={reservedMaterials}  
                currentStepCategorie={currentStepCategorie}
                setCurrentStepCategorie={setCurrentStepCategorie}
                setOpenWizard={setOpenWizard}
                handleOpenScanner={handleOpenScanner}
                updateJunctionStatus={updateJunctionStatus}
            />

            
            {selectedMaterials.length === 0 && (
                // show checkbox when there are no selected materials
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={geenMateriaalNodig}
                                onChange={handleGeenMateriaalNodigChange}
                                name="geenMateriaalNodig"
                            />
                        }
                        label="Geen materiaal nodig"
                    />
                </div>
            )}


            {selectedMaterials.length === 0 && (       
                // show buttons only when there none selected materials                 
                <Grid container justifyContent="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleOpenWizard}
                        >
                            {isXsDown ? 'Voeg materiaal toe' : 'Voeg materialen toe'}
                        </Button>
                    </Grid>
                </Grid>
            )}

            <MaterialWizard
                setSearchDialogOpen={setSearchDialogOpen} 
                setCurrentStepCategorie={setCurrentStepCategorie} 
                isMaterialAvailable={isMaterialAvailable} 
                currentReservation={currentReservation}
                theme={theme} 
                reservations={reservations}
                setSearchTerm={setSearchTerm}
                openWizard={openWizard}
                geselecteerdeDraaidag={geselecteerdeDraaidag}
                CATEGORIES={CATEGORIES}
                currentStepCategorie={currentStepCategorie}
                materialen={materialen}
                selectedMaterials={selectedMaterials}
                isXsDown={isXsDown}
                searchDialogOpen={searchDialogOpen}
                searchTerm={searchTerm}
                alleMaterialen={alleMaterialen}
                handleCloseWizard={handleCloseWizard}
                handleMaterialToggle={handleMaterialToggle}
                handlePreviousStep={handlePreviousStep}
                handleNextStep={handleNextStep}
                postOrUpdateReservering={postOrUpdateReservering}
                formatDate={formatDate}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleChangeTab={handleChangeTab}
                cameraSets={cameraSets}
                setCurrentStepSet={setCurrentStepSet}
                currentStepSet={currentStepSet}
                handleSelectAllAvailable={handleSelectAllAvailable}
                handleUnselectAll={handleUnselectAll}
                previousReservations={previousReservations}
                selectedPreviousReservation={selectedPreviousReservation}
                handleOpenPreviousReservationsDialog={handleOpenPreviousReservationsDialog}
                isPreviousReservationsDialogOpen={isPreviousReservationsDialogOpen}
                setIsPreviousReservationsDialogOpen={setIsPreviousReservationsDialogOpen}
                handleSelectPreviousReservation={handleSelectPreviousReservation}               
                materialsFromPreviousReservation={materialsFromPreviousReservation} 
                handleMaterialClickForOverlap={handleMaterialClickForOverlap}
                overlappingReservations={overlappingReservations}
                openReservationDialog={openReservationDialog}
                setOpenReservationDialog={setOpenReservationDialog}
            />
        </Paper>
      </Grid>
      <Dialog open={openQRScanner} onClose={() => setOpenQRScanner(false)}>
            <DialogTitle>Scan QR Codes</DialogTitle>

            <Snackbar 
                open={!!scanSuccessMessage} 
                autoHideDuration={6000} 
                onClose={() => setScanSuccessMessage("")}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // positions at the top-center
                style={{
                    top: '50%', // Vertically center
                    transform: 'translateY(-50%)',
                    marginLeft: '-200px'
                }}
            >
                <Alert 
                    onClose={() => setScanSuccessMessage("")} 
                    severity="success"
                >
                    {scanSuccessMessage}
                </Alert>
            </Snackbar>

                <Snackbar 
                    open={!!scanErrorMessage} 
                    autoHideDuration={6000} 
                    onClose={() => setScanErrorMessage("")}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // positions at the top-center
                    style={{
                        top: '50%', // Vertically center
                        transform: 'translateY(-50%)',
                        marginLeft: '-200px'
                    }}
                >
                    <Alert 
                        onClose={() => setScanErrorMessage("")} 
                        severity="error"
                    >
                        {scanErrorMessage}
                    </Alert>
                </Snackbar>

            <DialogContent>
            <div > {/* Adjust the size as needed */}
                <QrCodeScanner 
                    onScan={(scannedData) => {
                        handleScannedData(scannedData);
                    }}
                    //style={{ width: '100%', height: '100%' }}
                />
            </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenQRScanner(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>

        <DubbeleReserveringenDialog
            key={dubbeleReserveringenDialogOpen}
            open={dubbeleReserveringenDialogOpen}
            onClose={() => setDubbeleReserveringenDialogOpen(false)}
            reserveringen={dubbeleReserveringen}
            handleDeleteReservation={handleDeleteReservation}
        />

        {/*
        <PreviousReservationsDialog
            open={isPreviousReservationsDialogOpen}
            onClose={() => setIsPreviousReservationsDialogOpen(false)}
            onSelectReservation={handleSelectPreviousReservation}
            previousReservations={previousReservations}
        />
        */}

    </Grid>



  );

}