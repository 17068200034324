import {React} from 'react';
import { Chip, List, ListItem, ListItemText,  Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';  // For 'V' symbol, we use a check icon
import SettingsIcon from '@mui/icons-material/Settings';

// Create a separate MaterialItem component to reduce complexity and improve readability.

const MaterialItem = ({
    material,
    handleListItemClick,
    handleVerzameldChange,
    handleOpenStatusDialog,
    getStatusColor
  }) => {
    const renderStatusTags = (statuses) => {
      if (!Array.isArray(statuses)) {
        // Return null if statuses is not an array
        return null;
      }
  
      return statuses.map((status, index) => (
        <span
          key={index}
          style={{
            ...subtleTagStyle,
            backgroundColor: getStatusColor(status),
            marginRight: '5px',
            display: 'block', // Make each tag display as block to break line
          }}
          onClick={(e) => handleOpenStatusDialog(material, e)}
        >
          {status}
        </span>
      ));
    };
  
    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
          return text.substring(0, maxLength) + '..';
      }
      return text;
    };
    
    const hasJunctionStatus = material.fields.JunctionStatus && material.fields.JunctionStatus.length > 0;
  
    //console.log('status', material.fields.Status)

    return (
      <Grid item xs={12} sm={6}>
        <List style={listStyle}>
          <ListItem button style={{ ...listItemStyle, alignItems: 'flex-start' }}>
            <ListItemText
              primary={truncateText(material.fields.ID, 15)}
              primaryTypographyProps={textStyle}
              onClick={() => handleListItemClick(material)}
              style={{ marginRight: '2px' }}
            />
            <IconButton size="small" onClick={() => handleVerzameldChange(material, 'Gepakt')}>
              <CheckIcon fontSize="small" /> {/* 'V' symbol */}
            </IconButton>
            <IconButton size="small" onClick={(e) => handleOpenStatusDialog(material, e)}>
              <SettingsIcon fontSize="small" /> {/* '⚙' symbol for settings */}  
            </IconButton>
            {/* Status Tag like reparatie */}
            <div style={{ marginLeft: '8px', flexBasis: '100%' }}>
              {material.fields.Status && material.fields.Status !== 'In gebruik' && (
                  <Chip
                      label={material.fields.Status}
                      color="error" // Rode kleur
                      size="small"
                      style={{ marginLeft: '0px' }}
                  />
              )}
            </div>
            {/* Junction Status Tag like gepakt */}
            <div style={{ marginLeft: '8px', flexBasis: '100%' }}>
              {hasJunctionStatus && renderStatusTags(material.fields.JunctionStatus)}
            </div>
          </ListItem>
        </List>
      </Grid>
    );
  };
  
  const listStyle = {
    border: '1px solid #e0e0e0',
    marginBottom: '3px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '5px'
  };
  const listItemStyle = {
    paddingLeft: '15px',
    paddingTop: '0px',
    paddingBottom: '0px',
    display: 'flex', // Ensure flex layout
    flexWrap: 'wrap' // Allow items to wrap
  };
  const textStyle = {
    marginBottom: '3px',
    fontWeight: 'bold'
  };
  const subtleTagStyle = {
    backgroundColor: '#e8f5e9', // A light greenish background
    padding: '2px 8px', // Reduced padding
    borderRadius: '5px',
    display: 'inline-flex', // Keep inline-flex to align text properly
    alignItems: 'center',
    fontSize: '12px', // Reduced font size
    cursor: 'pointer', // Added this for clickability indication
    marginTop: '4px' // Added margin for spacing between the item name and tags
  };
  
export default MaterialItem;