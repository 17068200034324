import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import "../../../styles/UrenVerantwoording/App.css"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Airtabledata from '../../../utils/UrenVerantwoording/API/AirtableData.json'

/**
 * Dialog of all parts
 * @param {*} param0 
 * @returns 
 */
export default function ProjectPartDialog({open, onClose, submit, values=[],filterCheck, role,changeCheck, structure}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [checked, setChecked] = React.useState([0]);
  const [allParts, setAllParts] = React.useState([])
  const [specificParts, setSpecificParts] = React.useState([])
  const [valueToUse, setValueToUse] = React.useState([])
  const partData = Airtabledata.Operational.children.Onderdeel


  /**
   * Once values have loaded in place the correct group and sort the parts into two groups
   */
  React.useEffect(()=>{
    if(values[0] === 'temp') return
    if(values.length === 0) return 
    console.log(values)

    if(!(role instanceof Array)) return

    const allParts = []
    Object.keys(structure).forEach((key)=>{
      structure[key].forEach(part => {
        allParts.push(part)
      });
    })
    const filteredParts = []
    values.forEach(part => {
      if(!allParts.some(x => x.ID === part.fields[partData.children.autonumber]))filteredParts.push(part)
    });
    setAllParts(filteredParts)
    
    const functionParts = filteredParts.filter((part) => {
      const taaktype = part.fields[partData.children['Taaktype (from StandardOnderdeel)']];
      
      if (taaktype) {
        const isRoleMatch = role.includes(taaktype[0]);
        const isIntern = part.fields[partData.children['Projectnr_en_naam (from Projecten)']][0].includes("Full Frame");
    
        return isRoleMatch || isIntern;
      }
    
      return false;
    });

    setSpecificParts(functionParts)

  },[values,role])

  /**
   * Check which part group to use
   */
  React.useEffect(()=>{
    if(filterCheck){
      setValueToUse(specificParts)
    }else{
      setValueToUse(allParts)
    }

  },[specificParts,allParts, filterCheck])

  /**
   * add or removes part from the checked array
   * @param {*} value the part
   *  
   */
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  /**
   * Submit the checked parts and close dialog
   */
  function handleClick(){
    onClose()
    checked.shift()
    const removedCheckedValues = valueToUse
    checked.forEach((checkedPart)=>{
      const match = removedCheckedValues.findIndex(part=> checkedPart.id === part.id)
      if(match !== -1){
        removedCheckedValues.splice(match,1)
      }
    })
    setValueToUse(removedCheckedValues)
    submit(checked)
  }


  if(values){
    if(values[0] === "temp"){
      return
    }
  }


  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Kies een onderdeel om uren op te schrijven"}
        </DialogTitle>
        <DialogContent>          

        {values.length === 0 && (<div>Geen onderdelen gevonden bij dit project</div>)}
        {values.length > 0 && (
          <>
          <div className='checkbox-partDialog'>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={filterCheck}/>} label={"Alleen onderdelen laten zien die bij jouw functie horen"} onClick={changeCheck}/>
            </FormGroup>
          </div>
          {valueToUse.length === 0 && values.length !== 0 && (<div>Geen onderdelen gevonden dat bij jouw functie hoort</div>)}
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {valueToUse.map((value, index) => {
            const labelId = `checkbox-list-label-${value.id}`;

            return (
              <ListItem
                key={value.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.fields[partData.children.offerteregelOmschrijving]} ${value.fields[partData.children['OmschrijvingCategorie not empty']] !== "Empty"  ? value.fields[partData.children['OmschrijvingCategorie not empty']] : ""}`} secondary={`${value.fields[partData.children['Group not empty']] === "Empty"? "":value.fields[partData.children['Group not empty']]  }`}/>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        </>
        )}
        </DialogContent>
        <DialogActions>
            <div className='alertaction'>
                <Button onClick={handleClick} autoFocus>
                    Akkoord
                </Button>
                <Button onClick={onClose} autoFocus>
                    Sluiten
                </Button>
            </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
