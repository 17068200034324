import { useState } from "react"
import OutlookCalendarContainer from "../MiscComp/OutlookCalendarContainer"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

/**
 * Top layer of outlook calendar
 * @param {*} param0 
 * @returns 
 */
const OutlookDialog = ({token}) =>{
    const [display,setDisplay] = useState(false)
    /**
     * Decides to show dialog or not
     */
    const handleClick = () => {
        const newDisplay = !display
        setDisplay(newDisplay)
    }

    return(
        <div>
            <OutlookCalendarContainer token ={token} display={display} close={handleClick}/>
            <Button variant="outlined" onClick={handleClick}><Typography>Calendar</Typography></Button>
        </div>
    )
}

export default OutlookDialog