/**
 * Formats user info in ussable data structure
 * @param {*} data userInfo
 * @param {*} personenData Json data
 * @param {*} hoursParts All hours of the week
 * @param {*} urenLogData Json data
 * @returns formatted object of user info
 */
export default async function StructureData(data, personenData,hoursParts,urenLogData){
    if(data === undefined) return
    const Tasks = data.fields[personenData.children["Active Task With Part"]] ? data.fields[personenData.children["Active Task With Part"]] : []
    const AllParts = data.fields[personenData.children["OfferteRegels"]] ? data.fields[personenData.children["OfferteRegels"]] : []
    let ProjectLead = data.fields[personenData.children["Project Managment Part"]] ? data.fields[personenData.children["Project Managment Part"]] : []
    const standAloneProjects = data.fields[personenData.children["Standalone Project"]] ? [data.fields[personenData.children["Standalone Project"]],data.fields[personenData.children["Standalone Project Name"]]] : []

    const AllProjects = []
    AllProjects.push(standAloneProjects)

    const NeedHours = []
    const Ready = []

    const structuredParts = []
    const structuredTask = []
    const compiledStructuredTask =[]
    const structuredProjects = []
    let PLProjectsToAdd = ProjectLead.filter(part => !AllParts.includes(part));
    if(PLProjectsToAdd.length !== 0)return([[],[],[],PLProjectsToAdd])

    if (Tasks.length > 0){
        //task structure hier
        structuredTask.push(...GatherTasks(Tasks, data, personenData))
        compiledStructuredTask.push(...CompileTask(structuredTask))
    }
    if(AllParts.length > 0){
        //part structure hier
        structuredParts.push(...GatherParts(AllParts,data,personenData ))
    }
    const remainingHourParts= getRemainingHourPart(structuredParts,hoursParts,urenLogData.OnderdeelID, compiledStructuredTask, urenLogData["Geschreven uren"])
    // if(standAloneProjects.length > 0 || ProjectEditor.length > 0 || ProjectCamera.length > 0 || ProjectAnimator.length > 0 || ProjectLead.length > 0 || ProjectCreative.length > 0){
    //     structuredProjects.push(GatherProjects(AllProjects))
    // }
    if(remainingHourParts.length > 0){
        structuredParts.push(...GatherhourParts(remainingHourParts,personenData,data.id,urenLogData.OfferteRegels))
    }


    if(standAloneProjects.length > 0){
        structuredProjects.push(...GatherProjects(AllProjects))
    }

     if(compiledStructuredTask.length > 0){
        compiledStructuredTask.forEach(Task => {
             const potentialIndexPart = structuredParts.findIndex(part => part.ID === Task.ID)
             if(potentialIndexPart !== -1){
                 structuredParts.splice(potentialIndexPart, 1)
             }

             const potentialIndexProject = structuredProjects.findIndex(project=> project.Name === Task.projectName)
             if(potentialIndexProject !== -1){
                 structuredProjects.splice(potentialIndexProject, 1)
             }
         });
     }

    if(structuredParts.length > 0){
        structuredParts.forEach(Part =>{
           const potentialIndexProject = structuredProjects.findIndex(project => project.Name === Part.projectName)
            if(potentialIndexProject !== -1){
                structuredProjects.splice(potentialIndexProject)
            }
        })
    }
    NeedHours.push(compiledStructuredTask, structuredParts)
    Ready.push(structuredProjects)
    
    return [...NeedHours,...Ready, PLProjectsToAdd] 
}

/**
 * Formats parts in ussable data
 * @param {*} allParts 
 * @param {*} data 
 * @param {*} personenData 
 * @returns array of part objects
 */
function GatherParts(allParts, data, personenData){
    const partNames = []
    
    for (let index = 0; index < allParts.length; index++) {
        partNames.push({
            ID : data.fields[personenData.children["autonumber (from OfferteRegels)"]][index],
            UserID: data.id,
            onderdeelRecID: data.fields[personenData.children["OfferteRegels"]][index],
            Name: data.fields[personenData.children["offerteregelOmschrijving (from OfferteRegels)"]][index].split('(')[0],
            projectName : data.fields[personenData.children["Projectnr_en_naam (from Projecten) (from OfferteRegels)"]][index],
            Aantal: data.fields[personenData.children["Aantal van offerteregel"]][index],
            KorteOmschrijving : data.fields[personenData.children["OmschrijvingCategorie (from OfferteRegels)"]][index],
            LangeOmschrijving : data.fields[personenData.children["Omschrijving (from OfferteRegels)"]][index],
            Unit: data.fields[personenData.children["Unit"]][index],
            Group: data.fields[personenData.children["Group not empty (from OfferteRegels)"]][index],
            Pinned : true
        })
    }
    return partNames
}
/**
 * Formats tasks in ussable data
 * @param {*} allTasks 
 * @param {*} data 
 * @param {*} personenData 
 * @returns array of part objects with tasks added
 */
function GatherTasks(allTasks, data, personenData){ 
    const partNames = []
    for (let index = 0; index < allTasks.length; index++) {
        partNames.push({
            ID : data.fields[personenData.children["Active Task autonumber"]][index],
            UserID: data.id,
            Name: data.fields[personenData.children["Active Task part name"]][index].split('(')[0],
            projectName : data.fields[personenData.children["Active Task project name"]][index],
            Aantal: data.fields[personenData.children["Active task count"]][index],
            KorteOmschrijving : data.fields[personenData.children["Active Task short description"]][index],
            LangeOmschrijving : data.fields[personenData.children["Active Task long description"]][index],
            Unit: data.fields[personenData.children["Active Task part unit"]][index],
            Group: data.fields[personenData.children["Active Task onderdeel group"]][index],
            Pinned : true,
            task:{
                id : data.fields[personenData.children["Active task RecID"]][index],
                task : data.fields[personenData.children["Active Task With Part"]][index],
                type: data.fields[personenData.children["Active Task type"]][index],
                startDate: data.fields[personenData.children["Active Task start date"]][index],
                endDate: data.fields[personenData.children["Active Task end date"]][index],
                extraTask:[]
            }
        })
        
    }


    return partNames
}
/**
 * Format part where hours have been written on
 * @param {*} data 
 * @param {*} personenData 
 * @param {*} userID 
 * @param {*} OfferteFieldHourBase 
 * @returns array of part objects
 */
function GatherhourParts(data, personenData,userID,OfferteFieldHourBase){
    const partNames = []
    for (let index = 0; index < data.length; index++) {
        partNames.push({
            ID : data[index].fields[personenData.children["autonumber (from OfferteRegels)"]][0],
            UserID: userID,
            onderdeelRecID:data[index].fields[OfferteFieldHourBase][0],
            Name: data[index].fields[personenData.children["offerteregelOmschrijving (from OfferteRegels)"]][0].split('(')[0],
            projectName : data[index].fields[personenData.children["Projectnr_en_naam (from Projecten) (from OfferteRegels)"]][0],
            Aantal: data[index].fields[personenData.children["Aantal van offerteregel"]][0],
            KorteOmschrijving : data[index].fields[personenData.children["OmschrijvingCategorie (from OfferteRegels)"]][0],
            LangeOmschrijving : data[index].fields[personenData.children["Omschrijving (from OfferteRegels)"]][0],
            Unit: data[index].fields[personenData.children["Unit"]][0],
            Group: data[index].fields[personenData.children["Group not empty (from OfferteRegels)"]][0],
            Pinned : false
        })
    }
    return partNames
}

/**
 * standalone projects gets added to an array of objects 
 * @param {*} allProjects 
 * @returns array of project objects
 */
function GatherProjects(allProjects){
    const partNames = []
    allProjects.forEach(Projects => {
        if(Projects.length > 0){
            for (let index = 0; index < Projects[0].length; index++) {
                partNames.push({
                    ID: Projects[0][index],
                    Name : Projects[1][index]
                })
            };
        }
    });
    return partNames
}

/**
 * Multiple task on same part gets filtered here
 * @param {*} allTask 
 * @returns task data with only unique parts
 */
function CompileTask(allTask){
    const compiled = []
    const unique = new Set()
    allTask.forEach(task => {
        if(!unique.has(task.ID)){
            unique.add(task.ID)
            compiled.push(task)
        }else{
            const existingObject = compiled.find(uniqueObj => uniqueObj.ID === task.ID);
            existingObject.task.extraTask.push(task.task.task)
        }
    });
    return compiled
}

/**
 * Gets part where hour have been written on
 * @param {*} allParts 
 * @param {*} hourParts 
 * @param {*} NumberFieldHourBase 
 * @param {*} allTaskParts 
 * @param {*} sumHourField 
 * @returns 
 */
function getRemainingHourPart(allParts,hourParts,NumberFieldHourBase,allTaskParts, sumHourField){
    if((allParts.length === 0 && allTaskParts.length === 0) || hourParts.length === 0 ) return []
    let remainingHourParts = hourParts.filter(x=> x.fields[sumHourField] !== 0)
    remainingHourParts = remainingHourParts.filter(x=>!allParts.some(obj=> obj.ID === x.fields[NumberFieldHourBase][0]))
    remainingHourParts = remainingHourParts.filter(x=> !allTaskParts.some(obj=> Number(obj.ID) === x.fields[NumberFieldHourBase][0])) 

    return remainingHourParts
}