import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, List, ListItem, TextField, Grid, CardContent, Card
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NietBeschikbaarDialog from './NietBeschikbaarDialog';

const ReserveringPopup = ({     
    handleMaterialClickForOverlap,
    overlappingReservations,
    openReservationDialog,
    setOpenReservationDialog,
    selectedMaterials,
    setSelectedMaterials,
    postOrUpdateReservering,
    open, onClose, currentReservation, getDatesInRange, openMaterialDialog, handleCloseMaterialsDetails, reservedMaterials, updateReservation, checkMaterialAvailability, reservations }) => {
  const [startDate, setStartDate] = useState(new Date(currentReservation.fields.Start));
  const [endDate, setEndDate] = useState(new Date(currentReservation.fields.Eind));
  const [unavailableMaterials, setUnavailableMaterials] = useState([]);
  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // final check to delete unavailable materials
    // Sla de oorspronkelijke data op als referentie
    const [originalStartDate, setOriginalStartDate] = useState(new Date(currentReservation.fields.Start));
    const [originalEndDate, setOriginalEndDate] = useState(new Date(currentReservation.fields.Eind));

    useEffect(() => {
        if (open) {
            setStartDate(new Date(currentReservation.fields.Start));
            setEndDate(new Date(currentReservation.fields.Eind));
            setOriginalStartDate(new Date(currentReservation.fields.Start));
            setOriginalEndDate(new Date(currentReservation.fields.Eind));
            setUnavailableMaterials([]);
        }
    }, [open, currentReservation.fields.Start, currentReservation.fields.Eind]);


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleCheckAvailability = async () => {
    const { unavailableMaterials, availableMaterials } = await checkMaterialsAvailability(currentReservation.id, startDate, endDate);
    setUnavailableMaterials(unavailableMaterials);
    setAvailableMaterials(availableMaterials);
};



    const checkMaterialsAvailability = async (reservationId, newStartDatum, newEindDatum) => {
        const unavailableMaterials = [];
        const availableMaterials = [];
    
        // Convert dates to the appropriate format if necessary
        const startDatum = new Date(newStartDatum);
        const eindDatum = new Date(newEindDatum);
            
        const selectedRange = getDatesInRange(startDatum, eindDatum);
        const selectedRangeOriginal = getDatesInRange(originalStartDate, originalEndDate);
    
        // Check each material for availability
        for (const material of reservedMaterials) {
            const isAvailable = await isMaterialAvailable(material, selectedRange, selectedRangeOriginal, reservationId);
            if (!isAvailable) {
                unavailableMaterials.push(material);
            } else {
                availableMaterials.push(material);
            }
        }
        
        console.log("Unavailable materials:", unavailableMaterials);
        console.log("Available materials:", availableMaterials);
        
        return { unavailableMaterials, availableMaterials }; // Return both lists
    };
    

    const isMaterialAvailable = (material, selectedRange, selectedRangeOriginal, reservationId) => {
        const reservedDatesForMaterial = reservations[material.id];
        console.log("materialfunctie", material);
        console.log("reservedDatesForMaterialfunctie", reservedDatesForMaterial);
        if (!reservedDatesForMaterial) return true;
        
        console.log('selectedRange', selectedRange);
        console.log('selectedRangeOriginal', selectedRangeOriginal);

        // Zet de originele range om in een Set voor efficiënte lookup
        const originalDatesSet = new Set(selectedRangeOriginal);

        // Filter de gereserveerde datums voor dit materiaal om alleen de datums buiten de originele range te behouden
        const filteredReservedDates = reservedDatesForMaterial.filter(date => 
            !originalDatesSet.has(date) // Alleen datums die niet in de originele range zitten
        );
        console.log("filteredReservedDatesfunctie", filteredReservedDates);

        // Check if any date in the selectedRange is reserved.
        for (const date of selectedRange) {
            console.log("datefunctie", date);
          
            if (filteredReservedDates.includes(date)) {
                console.log("nietbeschikbaarfunctie", date);
                return false; // Materiaal is niet beschikbaar als een datum in het bereik gereserveerd is
            }
        }
        
        return true; // Materiaal is beschikbaar
    };

    const handleSaveReservation = async () => {
      if (unavailableMaterials.length > 0) {
          // Toon de bevestigingsdialoog als er niet-beschikbare materialen zijn
          setConfirmDialogOpen(true);
      } else {
          // Ga direct door met opslaan als alle materialen beschikbaar zijn
          await saveReservation();
      }
    };

    const saveReservation = async () => {
      setConfirmDialogOpen(false); // Sluit de bevestigingsdialoog
      onClose(); // Sluit de hoofddialoog
      setSelectedMaterials(availableMaterials);
      const uniqueAvailableMaterialIds = [...new Set(availableMaterials.map(materiaal => materiaal.id))];
      await postOrUpdateReservering(startDate, endDate, uniqueAvailableMaterialIds);

    };

    useEffect(() => {
        // Deze functie wordt aangeroepen wanneer 'startDate' of 'endDate' verandert
        const checkAvailabilityAutomatically = async () => {
            await handleCheckAvailability();
        };

        // Voer de controle uit als beide datums zijn ingesteld
        if (startDate && endDate) {
            if (startDate > endDate) {
                setEndDate(startDate)   // Toon een foutmelding
            }
            checkAvailabilityAutomatically();
        }
    }, [startDate, endDate]);  // Voeg 'startDate' en 'endDate' toe als dependencies


    // Als voorbeeld, binnen een event handler in je dialog component


  return (
    <div >
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" >
      <DialogContent>
            <Grid container spacing={2}>
                {/* Informatie sectie */}
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Reservering: {currentReservation.fields.reserveringNummer}</Typography>
                            <Typography variant="body2">DraaidagInfo: {currentReservation.fields.DraaidagnaamProjectNaam}</Typography>
                            <Typography variant="body2">Camera Operator: {currentReservation.fields.CameraMan}</Typography>
                            <Typography variant="body2">Aangemaakt door: {currentReservation.fields.SubmitterName} ({currentReservation.fields.CreatedTime})</Typography>
                            <Typography variant="body2">Laast gewijzigd door: {currentReservation.fields.ModifiedByName} ({currentReservation.fields.LastModifiedTime})</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Datumkiezers */}
                <Grid item xs={12} sm={6}>
                    <DatePicker 
                        selected={startDate} 
                        onChange={handleStartDateChange} 
                        dateFormat="dd/MM/yyyy" 
                        customInput={<TextField fullWidth label="Startdatum" />}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker 
                        selected={endDate} 
                        onChange={handleEndDateChange} 
                        dateFormat="dd/MM/yyyy" 
                        customInput={<TextField fullWidth label="Einddatum" />}
                    />
                </Grid>

                {/* Niet beschikbare materialen */}
                {unavailableMaterials.length > 0 && (
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="body2" color="error">
                                    Onderstaande materialen zijn niet beschikbaar voor de nieuwe data:
                                </Typography>
                                <List>
                                    {unavailableMaterials.map((material, index) => (
                                        <ListItem 
                                            key={index}
                                            button // Maak de ListItem klikbaar
                                            onClick={() => handleMaterialClickForOverlap(material)} // Open de NietBeschikbaarDialog
                                        >
                                            {material.fields.ID}
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {/* Beschikbare materialen */}
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="body2">
                                Beschikbaar materiaal voor nieuwe data:
                            </Typography>
                            <List>
                                {availableMaterials.map((material, index) => (
                                    <ListItem 
                                        key={index}
                                        button // Maak de ListItem klikbaar
                                        onClick={() => handleMaterialClickForOverlap(material)} // Open de NietBeschikbaarDialog
                                    >
                                        {material.fields.ID}
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuleren</Button>
        <Button  color="primary" onClick={handleSaveReservation }>
          Bevestig beschikbaar materiaal
        </Button>
      </DialogActions>
    </Dialog>

    <NietBeschikbaarDialog
        open={openReservationDialog}
        onClose={() => setOpenReservationDialog(false)}
        reservations={overlappingReservations}
    />

    <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Bevestig Wijziging</DialogTitle>
        <DialogContent>
            <Typography>
                De volgende materialen zijn niet beschikbaar voor de nieuwe data en worden verwijderd uit de reservering:
            </Typography>
            <List>
                {unavailableMaterials.map((material, index) => (
                    <ListItem key={index}>
                        {material.fields.ID}
                    </ListItem>
                ))}
            </List>
            <Typography>
                Wilt u doorgaan en de reservering bijwerken met alleen de beschikbare materialen?
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Annuleren</Button>
            <Button color="primary" onClick={saveReservation}>
                Bevestig
            </Button>
        </DialogActions>
    </Dialog>

    </div>

    

  );
};

export default ReserveringPopup;
