import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, useTheme } from '@mui/material/styles'
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Calendar from '../MiscComp/Calendar';
import { useRef, useEffect } from 'react';
import dayjs from 'dayjs';

/**
 * Show dialog with extra part options
 * @param {*} param0 
 * @returns 
 */
export default function TaskDetailDialog({open, onClose, weekAndHours, handleBlur,handleFocus,draaidag, handleChange,week,comment}) {
    const [showDatePicker, setShowDatePicker] = React.useState(false);                                                       
    const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date(week.weekRowDate[0])));                                                             
    const theme = useTheme();
    const valueRef = useRef()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    /**
     * Close datepicker and send selected date
     * @param {*} date date selected with the date picker
     */
    function handleClose(date){
        setShowDatePicker(false)
        onClose(date ,valueRef.current.value)
    }

    /**
     * Show date picker
     *      */
    const handleButtonChange = () => {
        setShowDatePicker(true);
    };

    /**
     * Change selected date of date picker
     * @param {*} date seleceted date
     */
    const handleCalendarDate = (date) => {
        setSelectedDate(date);
    };

    return (
    <div>
        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={()=>handleClose(false)}
        aria-labelledby="responsive-dialog-title"
        >
        <DialogTitle id="responsive-dialog-title">
            {"Extra details voor onderdeel"}
        </DialogTitle>
        <DialogContent>
            <div className='dialog-part-row'>
                <div className='dialog-dates'>
                    {[...week.weekRowtext].map((day,index)=>
                        <div className='week-day-dialog' key={index}>
                            {day} {week.weekRowNumber[index]}
                        </div>
                    )}
                </div>
                <div className='dialog-hours'>
                    {[...weekAndHours].map((hours, index) =>
                        <div className="inputrow dialog "key={index} >
                            <input id={index}  onBlur={handleBlur} step={0.25}onFocus={handleFocus} data-key={index} autoComplete="off" type="number" className={`hourinput dialog ${draaidag === index ? "yellow":"" } ${draaidag}`} value={weekAndHours[index]} onChange={handleChange} />
                        </div>
                    )}
                </div>

                <div>
                    <Button onClick={handleButtonChange}>breng uren mee </Button>
                </div>

                {showDatePicker && (
                    <div>
                        <Paper elevation={10}>
                            <Calendar calendarDate={selectedDate} handleCalendarDate={handleCalendarDate} disablepast={true}/>
                        </Paper>
                    </div>
                )}

                <div className='dialog-comment-container'>
                    <TextField
                        id="outlined-multiline-static"
                        label="Comment"
                        multiline
                        rows={4}
                        className='dialog-comment'
                        inputRef={valueRef}
                        defaultValue={comment}
                    />
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <div className='alertaction'>
                <Button onClick={()=> handleClose(selectedDate)} autoFocus>
                    Akkoord
                </Button>
                <Button onClick={()=>handleClose(false)} autoFocus>
                    Sluiten
                </Button>
            </div>
        </DialogActions>
        </Dialog>
    </div>
    );
}
