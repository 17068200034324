import React, { useState, useEffect } from 'react';
import {FormControl, FormHelperText, Tabs, Tab, Select, MenuItem, List, Dialog, DialogTitle, IconButton, Button, Grid, Typography, DialogContent, ListItem, ListItemIcon, Checkbox, ListItemText, DialogActions, TextField } from '@mui/material';
import Close from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import MaterialSearch from './MaterialSearch';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';

import AddIcon from '@mui/icons-material/Add';

import PreviousReservationsDialog from './PreviousReservationsDialog';
import NietBeschikbaarDialog from './NietBeschikbaarDialog';

const MaterialWizard = ({ 
    reservations,
    setSearchDialogOpen,
    setCurrentStepCategorie,
    isMaterialAvailable,
    theme,
    setSearchTerm,
    openWizard,
    geselecteerdeDraaidag,
    CATEGORIES,
    currentStepCategorie,
    materialen,
    selectedMaterials,
    isXsDown,
    searchDialogOpen,
    searchTerm,
    alleMaterialen,
    handleCloseWizard,
    handleMaterialToggle,
    handlePreviousStep,
    handleNextStep,
    formatDate,
    postOrUpdateReservering,
    activeTab,
    handleChangeTab,
    cameraSets,
    setCurrentStepSet,
    currentStepSet,
    handleSelectAllAvailable,
    handleUnselectAll,
    previousReservations,
    selectedPreviousReservation,
    handleSelectPreviousReservation,
    handleOpenPreviousReservationsDialog,
    isPreviousReservationsDialogOpen,
    setIsPreviousReservationsDialogOpen,
    materialsFromPreviousReservation,
    setSelectedReservation,
    handleMaterialClickForOverlap,
    overlappingReservations,
    openReservationDialog,
    setOpenReservationDialog,
    currentReservation }) => {

    const [sortMethod, setSortMethod] = useState('reservations'); // 'name' or 'reservations'        
    
    const openUrl = () => {
        window.open('https://airtable.com/apphMT5j6gtUnMY1p/pagPGJSbAbPe3CnlW/form', '_blank'); // URL voor toeveogen van materiaal
    };

    if (!openWizard || !geselecteerdeDraaidag) {
        return null;
    }


    const sortMaterials = (method) => {
        setSortMethod(method);
    };

    const handleSave = () => {
        const initialStartDate = currentReservation && currentReservation.fields && currentReservation.fields.Start
            ? new Date(currentReservation.fields.Start)
            : new Date(geselecteerdeDraaidag.fields.StartDatum);

        const initialEndDate = currentReservation && currentReservation.fields && currentReservation.fields.Eind
            ? new Date(currentReservation.fields.Eind)
            : new Date(geselecteerdeDraaidag.fields.EindDatum);

        postOrUpdateReservering(initialStartDate, initialEndDate, selectedMaterials);
        setSearchDialogOpen(false)
    };
    
    
    const sortedMaterials = [...materialen].sort((a, b) => {
        //console.log("materialen", materialen)
        if (sortMethod === 'reservations') {
            // Assuming 'aantalReserveringen' is a numeric field
            const resA = a.fields.aantalReserveringen || 0;
            const resB = b.fields.aantalReserveringen || 0;
            return resB - resA; // Sort descending
        } else {
            // Default to sorting by name
            const nameA = (a.fields.TypeText || '').toLowerCase();
            const nameB = (b.fields.TypeText || '').toLowerCase();
            return nameA.localeCompare(nameB);
        }
    });
    
    // Define custom styles for extra small buttons
    const extraSmallButtonStyle = {
        padding: '2px 6px', // Reduce padding
        fontSize: '0.7rem', // Reduce font size
        minWidth: '30px', // Set a minimum width
        margin: '0 5px',  // Adjust spacing between buttons
    };

    const formatReservationDates = () => {
        if (!currentReservation || !currentReservation.fields || !currentReservation.fields.Start || !currentReservation.fields.Eind) {
            // Retourneer een placeholder of niets als currentReservation niet correct is ingesteld
            return 'Laden...'; // of null, afhankelijk van wat je wilt tonen in de UI
        }
    
        const startDateFormatted = formatDate(currentReservation.fields.Start);
        const endDateFormatted = formatDate(currentReservation.fields.Eind);
    
        return startDateFormatted === endDateFormatted ? 
            (isXsDown ? `${startDateFormatted}` : `Reservering ${startDateFormatted}`) : 
            (isXsDown ? `${startDateFormatted} - ${endDateFormatted}` : `Reservering ${startDateFormatted} - ${endDateFormatted}`);
    };
    


    // Where you expect to call the function, e.g., in a button's onClick handler
    const onSelectAllClick = () => {
        console.log("Select All button clicked");
        handleSelectAllAvailable();
    };
   
    return (              
        <Dialog open={openWizard} onClose={handleCloseWizard} style={{ overflowX: 'hidden' }} maxWidth="md" fullWidth align="center">
            <DialogTitle style={{ textTransform: 'uppercase', position: 'relative', paddingRight: '40px' }}>
                {/* Reservation Text */}
                {formatReservationDates()}
                <IconButton
                    //edge="end"
                    color="inherit"
                    onClick={handleCloseWizard}
                    aria-label="close"
                    style={{ position: 'absolute', right: '8px', top: '8px' }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>



  
            {isXsDown && (
            //show only zoekknop and dropdown select on xs devices
            <Grid container justifyContent="center" spacing={1} style={{ padding: '10px' }}>
                <Grid item xs={12} sm={6} style={{ padding: '0 10px', marginBottom: '10px' }}> {/* Voeg hier extra marge toe onderaan */}
                    <Select
                        fullWidth
                        value={currentStepCategorie}
                        onChange={(event) => setCurrentStepCategorie(event.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Categorie selecteren' }}
                        size="small"
                    >
                        {CATEGORIES.map((category, index) => (
                            <MenuItem key={category} value={index}>
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: '0 10px', marginBottom: '10px' }}> {/* Voeg hier extra marge toe onderaan */}
                        <Select
                            fullWidth
                            value={currentStepSet || ""}
                            onChange={(event) => setCurrentStepSet(event.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Materiaalset selecteren' }}
                            size="small"
                        >
                            <MenuItem value="" disabled>Kies een materiaalset</MenuItem>

                            {cameraSets.map((set) => (
                                <MenuItem key={set.id} value={set.fields.ProjectType}>
                                    {set.fields.ProjectType}
                                </MenuItem>
                            ))}
                        </Select>
                </Grid>
                <Grid item xs={12} style={{ padding: '0 10px' }}>
                    <Button 
                        fullWidth
                        onClick={() => setSearchDialogOpen(true)}
                        variant="contained"
                        color="primary"
                        size="small"
                    >
                        Zoek
                    </Button>
                </Grid>
            </Grid>
        


            )}


            {!isXsDown && (
                <>
                {/* Category Navigation Bar - Hidden on xs devices */}
                    {/* Tab interface */}

                    <Grid container alignItems="center" justifyContent="center" sx={{ marginBottom: 2 }}>
                        <Grid item>
                            <Tabs value={activeTab} onChange={handleChangeTab} 
                                sx={{ 
                                    '.MuiTab-root': { 
                                        padding: '6px 12px', 
                                        fontSize: '0.875rem',
                                    }
                                }}>
                                <Tab label="Categorieën" />
                                <Tab label="Materiaalsets" />
                            </Tabs>
                        </Grid>
                        <Grid item sx={{ marginLeft: 4 }}>  {/* Voeg hier extra marge toe */}
                            <Button 
                                onClick={() => setSearchDialogOpen(true)}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                Zoek
                            </Button>
                        </Grid>
                        <Grid item sx={{ marginLeft: 4 }}>  {/* Voeg hier extra marge toe */}
                            {/*
                            <Button 
                                onClick={() => handleOpenPreviousReservationsDialog()}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                
                                Vorige Reservering
                                
                            </Button>
                            */}
                            <PreviousReservationsDialog 
                                open={isPreviousReservationsDialogOpen} 
                                onClose={() => setIsPreviousReservationsDialogOpen(false)} 
                                handleSelectPreviousReservation={handleSelectPreviousReservation}
                                reservations={previousReservations}
                                materialsFromPreviousReservation={materialsFromPreviousReservation} 
                            />
                        </Grid>

                    </Grid>
                    <Grid container justifyContent="center" spacing={1} style={{ padding: '2px 0' }}>

                        {activeTab === 0 && (
                            // Category Navigation Bar
                            // show only if not on xs devices
                            <>
                                {CATEGORIES.map((category, index) => (
                                    <Grid item key={category}>
                                        <Button
                                            variant={currentStepCategorie === index ? "outlined" : "text"}
                                            color="primary"
                                            onClick={() => setCurrentStepCategorie(index)}
                                            size="small"
                                        >
                                            {category}
                                        </Button>
                                    </Grid>
                                ))}
                            </>
                        )}
                        
                        {activeTab === 1 && cameraSets && (
                            // Toon materiaalsets
                            <>
                                {cameraSets.map((set) => {
                                    // Bepaal of de huidige set de geselecteerde set is
                                    const isSelected = currentStepSet === set.fields.ProjectType;

                                    return (
                                        <Button 
                                            key={set.id} 
                                            color="primary"
                                            variant={isSelected ? "outlined" : "text"} // Gebruik 'outlined' voor de geselecteerde set
                                            onClick={() => {
                                                setCurrentStepSet(set.fields.ProjectType);
                                            }}
                                            size="small"
                                        >
                                            {set.fields.ProjectType}
                                        </Button>
                                    );
                                })}
                            </>
                        )}

    
                    </Grid>
                </>
            )}

                
            {/* Material List */}
            <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                    <Button 
                        variant={sortMethod === 'name' ? 'text' : 'text'}
                        onClick={() => sortMaterials('name')}
                        style={extraSmallButtonStyle}
                    >
                        <ArrowDownwardIcon /> naam
                    </Button>
                    <Button 
                        variant={sortMethod === 'reservations' ? 'text' : 'text'}
                        onClick={() => sortMaterials('reservations')}
                        style={extraSmallButtonStyle}
                    >
                        <ArrowDownwardIcon /> meest gebruikt
                    </Button>

                    <Button 
                        onClick={onSelectAllClick}
                        style={extraSmallButtonStyle}
                    >
                        Select all
                    </Button>
                    <Button 
                        onClick={handleUnselectAll}
                        style={extraSmallButtonStyle}
                    >
                        Unselect all
                    </Button>


                </div>
                <Grid container spacing={0} style={{ width: '90%', maxWidth: '600px', overflow: 'auto', textAlign: 'left' }}>
                    {sortedMaterials.map(record => {
                        const isAvailable = isMaterialAvailable(record, reservations);

                        return (
                            // Use full width on xs devices, half width on others
                            <Grid item xs={12} sm={6} key={record.id} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <ListItem 
                                button
                                style={{ paddingTop: 0, paddingBottom: 0 }} 
                                onClick={() => {
                                    if (!isAvailable) handleMaterialClickForOverlap(record);
                                    else handleMaterialToggle(record);
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedMaterials.indexOf(record.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        disabled={!isAvailable}  // Checkbox is disabled when isAvailable is false
                                    />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={record.fields.Naam}
                                    secondary={`${record.fields.ID}`}
                                />
                                {record.fields.Status && record.fields.Status !== 'In gebruik' && (
                                    <Chip
                                        label={record.fields.Status}
                                        color="error" // Rode kleur
                                        size="small"
                                        style={{ marginLeft: '10px' }}
                                    />
                                )}
                            </ListItem>
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: 'center', padding: '10px 0' }}>
                {/* Navigation Buttons */}
                <Grid container alignItems="center" spacing={3}>
                    {activeTab === 0 && (
                        <>
                            <Grid item xs={4} sm={4}>
                                <Button onClick={handlePreviousStep} disabled={currentStepCategorie === 0}>
                                    <ArrowBackIcon fontSize="small" />
                                    <span style={{ display: 'none', [theme.breakpoints.up('md')]: { display: 'inline' } }}>
                                        Vorige
                                    </span>
                                </Button>
                            </Grid>
                            <Grid item xs={4} sm={4} style={{ textAlign: 'center' }}>
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    onClick={handleSave} 
                                    startIcon={<SaveIcon />}
                                >
                                    {isXsDown ? `(${selectedMaterials.length})` : `Save (${selectedMaterials.length})`}
                                </Button>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Button 
                                    variant="text" 
                                    color="primary" 
                                    onClick={handleNextStep}
                                    style={{ display: currentStepCategorie === CATEGORIES.length - 1 ? 'none' : 'inline-block' }}
                                >
                                    <ArrowForwardIcon fontSize="small" />
                                    <span style={{ display: 'none', [theme.breakpoints.up('md')]: { display: 'inline' } }}>
                                        Volgende
                                    </span>
                                </Button>
                            </Grid>
                        </>
                    )}
                    {/* Small screen, ommit volgende and vorige buttons */}
                    {activeTab !== 0 && (
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={handleSave} 
                                startIcon={<SaveIcon />}
                            >
                                {isXsDown ? `(${selectedMaterials.length})` : `Save (${selectedMaterials.length})`}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <MaterialSearch
                setSearchDialogOpen={setSearchDialogOpen}
                isMaterialAvailable={isMaterialAvailable}
                setSearchTerm={setSearchTerm}
                selectedMaterials={selectedMaterials}
                searchDialogOpen={searchDialogOpen}
                searchTerm={searchTerm}
                alleMaterialen={alleMaterialen}
                handleMaterialToggle={handleMaterialToggle}
                handleMaterialClickForOverlap={handleMaterialClickForOverlap}
                reservations={reservations}
            />

            <NietBeschikbaarDialog
                    open={openReservationDialog}
                    onClose={() => setOpenReservationDialog(false)}
                    reservations={overlappingReservations}
            />

   


        </Dialog>
    )    
}
    


export default MaterialWizard;
