import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { HomePortal } from "../ui-components/HomePortal";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export function Home() {
  const { instance } = useMsal();
  
  function handleLogin(){
        instance.loginRedirect({...loginRequest,
        redirectUri:process.env.REACT_APP_POPUP_REDIRECT_URI
        });
    }

  return (
      <>
          <AuthenticatedTemplate>
            <HomePortal/>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="login-text">
              <Button  onClick={handleLogin}  style={{fontSize:"2rem"}}>
                <center>Graag inloggen.</center>
                </Button>
            </div>

          </UnauthenticatedTemplate>
      </>
  );
}