import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../../styles/UrenVerantwoording/App.css"
import Loading from '../MiscComp/LoadingScreen';
import SearchBar from '../MiscComp/SearchBar';
import { AppBar,Tabs, Tab, Box, Typography, Checkbox } from '@mui/material';
import ProjectList from '../MiscComp/ProjectList';
import {sortProjectonPartType} from '../../../utils/UrenVerantwoording/ArraySort'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {CircularProgress} from "@mui/material"
import Airtabledata from "../../../utils/UrenVerantwoording/API/AirtableData.json"


/**
 * Dialog of projects 
 * @param {*} param0 
 * @returns 
 */
export default function PartDialogAllProjects({_Airtable, open, onClose, submit, role, email, projects, searchParts, olderProjects, filterCheck, structure, pastActive}) {   
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isLoading,setLoading]= React.useState(true)
    const [info,setInfo] = React.useState([])
    const [pastActiveInfo, setPastActiveInfo] = React.useState([])
    const [pastProjects, setPastProject] = React.useState([])

    const [searchValue, setSearchValue] = React.useState(""); 
    const [value, setValue] = React.useState(0);


    /**
     * 
     * Set value of use state value
     * @param {*} event Not used but asked to acces newValue easily
     * @param {*} newValue Tabindex value
     */
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    React.useEffect(()=>{
        const pastActiveObject = []

        for (let index = 0; index < pastActive[0].length; index++) {
            const number = pastActive[0][index];
            const name = pastActive[1][index]
            if(number === null || name === null){
                continue
            }

            pastActiveObject.push({
                fields:{
                    Projectnr_en_naam: name,
                    Projectnummer : number
                },
                id: index
            })
        }
        setPastActiveInfo(pastActiveObject.reverse())
    },[pastActive])
    /**
     * Loads in the promise of projects and resolves said promise
     */
    React.useEffect(()=>{
        if (projects instanceof Promise) {
            setLoading(true);
            projects.then((resolvedProjects)=>{ 
                if(resolvedProjects === undefined) return
                if(resolvedProjects.length === 0) return
                if(resolvedProjects[0] === "temp") return
                resolvedProjects.forEach(project => {
                    project['parts'] = []
                    const count = {}
                    project.fields[Airtabledata.Operational.children.Projecten.children['Taaktype offerteRegel']].forEach(function(x) {count[x] = (count[x] || 0 ) + 1})
                    project['roles'] = count 
                });
                try{
                    setInfo(sortProjectonPartType(resolvedProjects, role))
                }catch(err){
                    console.log(err)
                    setInfo(projects)

                }finally{
                    setLoading(false)
                }
            })
        }else{
            console.error('verkeerd gegaan met fetchen projecten')
        }
    },[projects, role])

    /**
     * Close the dialog and send checked parts further
     * @param {*} checkedParts 
     */
    function submitInfo(checkedParts){
        submit(checkedParts)
        setSearchValue('')
        onClose()
    }
    /**
     * Sets use State of search value
     * @param {*} value 
     */
    function handlesearch(value){
        setSearchValue(value)
    }
    /**
     * Sets all important variables to its origin and closes dialog
     */
    function leave(){
        setSearchValue('')
        onClose()
        setValue(0)
    }
    
    /**
     * Decides id and aria control of tab panel
     * @param {*} index 
     * @returns id and aria control
     */
    function a11yProps(index) {

        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }


    return (
        <div>
            
        <Dialog
            fullScreen={fullScreen}
            open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1026px",  // Set your width here
                  },
                },
              }}
            onClose={leave}
            aria-labelledby="responsive-dialog-title"
        >
                {isLoading && (
                    <div className='dialog'>  
                    </div>
                )}
            
            <DialogTitle id="responsive-dialog-title">
            {"Kies een project om daar onderdelen uit te kiezen"}
            </DialogTitle>
            { !isLoading && (
            <DialogContent>

                <div className='search'>
                <SearchBar onSearchChange={handlesearch}/>
                </div>
                <div className="filter">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={!filterCheck}/>} label={"Laat afgeronden projecten zien"} onClick={olderProjects}/>
                    </FormGroup>
                    
                </div>
                <AppBar position="static">
                    <Tabs
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    value={value}
                    onChange={handleChange}
                    >
                    <Tab label="Projecten" {...a11yProps(0)} />
                    <Tab label="Vorige projecten"{...a11yProps(1)}  />
                    </Tabs>
                </AppBar>

            </DialogContent>
            )}
            {isLoading && <center><CircularProgress/></center>}
            {!isLoading && value === 0 && info.length > 0 &&<ProjectList _Airtable={_Airtable} values={info}  searchPart={searchParts} searchTerm={searchValue} submit={submitInfo} role={role} structure={structure} key={info}/>}
            {!isLoading && value === 1 && pastActiveInfo.length > 0 &&<ProjectList _Airtable={_Airtable} values={pastActiveInfo}  searchPart={searchParts} searchTerm={searchValue} submit={submitInfo} role={role} structure={structure} key={info}/>}
            <DialogActions>
                <div className='alertaction'>
                    <Button onClick={onClose} autoFocus>
                        Sluiten
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
        </div>
    );
}
