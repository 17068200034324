import axios from 'axios';
const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
export async function patchPart(taskID, partID) {
    console.log(taskID)
    console.log(partID)
    const updateUrl = `https://api.airtable.com/v0/appairPVZTnXeJaJG/tbl8aeIkxyfpGegKs`;
  
    const updatedData = {
        records:[{
            fields: {
                OfferteRegels: [partID],
              },
              id:taskID
        }]
    };
  
    try {
      const updateResponse = await axios.patch(updateUrl, updatedData, {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(updateResponse)
      return updateResponse.data;
    } catch (error) {
      console.error('Error updating StandardTasks:', error);
      throw error;
    }
  }