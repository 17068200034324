import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
/**
 * element thats inside the snackbar
 */
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
/**
 * Snackbar which you can give if its open, the text and what kind of alert it is
 * @param {*} param0 
 * @returns 
 */
export default function GeneralSnackBar({Data, close}) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const [type, setType]= React.useState('')

    /**
     * use effect to set the prop data in useStates
     */
    React.useEffect(()=>{
        setOpen(Data.open)
        setText(Data.text)
        setType(Data.type)
    },[Data])

    /**
     * Close the snack bar exept by clicking away
     * @param {*} event 
     * @param {*} reason 
     * @returns 
     */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    close({open:false,text:'',type:''});
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"center" }}
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
      >        
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
            {text}
        </Alert>
    </Snackbar>
    </div>
  );
}