import React, { useState, useEffect, useRef} from "react";

const SpecialAbsence = ({originalStructureObject,setOriginalStructureObject, shown, userID, getSpecialAbsenceHours, week,TypeField}) => 
{    
    const [active,setActive] = useState(0)
    const [weekHours, setWeekHours] = useState([])
    const [structureObject, setStructureObject] = useState(null)
    const specialAbsenceStructure = () =>{
        return{
            "Incidenteel verlof":
                [parts("Afwezigheid - Buitegewoon verlof",userID),
                parts("Afwezigheid - Compensatie overuren",userID),
                parts("Afwezigheid - Docktor,tandarts",userID),
                parts("Afwezigheid - Vakantie / vrij",userID),
                parts("Afwezigheid - Ziekte",userID),
                parts("Overig - Extra vakantieuren",userID)]
        }
    }

    const getWeekHours= async()=>{
        return await getSpecialAbsenceHours(week)
    }


    const parts = (name,userID) =>{
        let hour = undefined
        if(weekHours.length !== 0){
            hour = weekHours.filter(x=> x.fields[TypeField] === name)
        }
        console.log(hour)

        return {
            "Aantal" : 0,
            "Group": "Empty",
            "ID": "verlof",
            "KorteOmschrijving" : "Empty",
            "LangeOmschrijving" : "Empty",
            "Name" : name,
            "Pinned" : true,
            "UserID" : userID,
            "onderdeelRecID": "verlof",
            "projectName":"Incidenteel verlof",
            "hours" : hour? hour[0]: undefined 
        }
    }
    
    useEffect(()=>{
        setActive(shown)
    },[shown])

    useEffect(()=>{
        if(active === true){
            setStructureObject(originalStructureObject)
            setOriginalStructureObject(specialAbsenceStructure)    
        } else if(active === false){
            if(structureObject === null) return
            setOriginalStructureObject(structureObject)
        }
    },[active])

    useEffect(()=>{
        getWeekHours().then((data)=>{
            setWeekHours(data)
        })
        setWeekHours(getWeekHours())
    },[week, active])

}

export default SpecialAbsence;
