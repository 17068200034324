// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* datepicker-custom.css */
.react-datepicker__overlay {
    background-color: rgba(255, 255, 255, 0.9) !important; 
}`, "",{"version":3,"sources":["webpack://./src/styles/datepicker-custom.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,qDAAqD;AACzD","sourcesContent":["/* datepicker-custom.css */\n.react-datepicker__overlay {\n    background-color: rgba(255, 255, 255, 0.9) !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
