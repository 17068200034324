import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const DubbeleReserveringenDialog = ({ open, onClose, reserveringen, handleDeleteReservation }) => {
    const verwijderReserveringEnSluit = async (reserveringId) => {
        await handleDeleteReservation(reserveringId);
        onClose(); // Sluit de dialog na het succesvol verwijderen van de reservering
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="reserveringen-dialog-title">
            <DialogTitle id="reserveringen-dialog-title" style={{ color: 'red' }}>Dubbele reserveringen gedetecteerd, verwijder er één.</DialogTitle>
            <DialogContent dividers>
                <List>
                    {reserveringen.map((reservering, index) => (
                        <ListItem key={index} secondaryAction={
                            <Button color="secondary" onClick={() => verwijderReserveringEnSluit(reservering.id)}>
                                Verwijder
                            </Button>
                        }>
                            <ListItemText
                                primary={`Reservering: ${reservering.fields.reserveringNummer}`}
                                secondary={`Aantal materialen: ${reservering.fields.Aantal} - Laatst gewijzigd: ${new Date(reservering.fields.LastModifiedTime).toLocaleDateString("nl-NL")}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Sluiten
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default DubbeleReserveringenDialog;
