import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
const HomeButton = () => 
{
    return (
        <div>
            <Link to="/" style={{ color: 'inherit' }} >
                <Button 
                color="inherit"
                variant="outlined"
                >
                    <Typography>Home</Typography>
                </Button>
            </Link>
        </div>
    )
}

export default HomeButton;
