import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import MaterialItem from './MaterialItem'; // Import your MaterialItem component

const PreviousReservationsDialog = ({ open, onClose, handleSelectPreviousReservation, reservations, materialsFromPreviousReservation, handleMaterialSelection, handleVerzameldChange, handleOpenStatusDialog, getStatusColor }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredReservations, setFilteredReservations] = useState([]);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [showReservationsList, setShowReservationsList] = useState(true);


    useEffect(() => {
        const filtered = reservations.filter(res => 
            res.naam && typeof res.naam === 'string' && res.naam.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredReservations(filtered);
    }, [searchTerm, reservations]);


    const onReservationClick = (reservation) => {
        console.log('Selected reservation:', reservation);
        handleSelectPreviousReservation(reservation);
        setSelectedReservation(reservation);
        setShowReservationsList(false); // Hide the list after selection
    };
    
    const toggleReservationsList = () => {
        setShowReservationsList(!showReservationsList);
    };
    

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Select Previous Reservation</DialogTitle>
            <DialogContent>
                <TextField 
                    fullWidth 
                    label="Search Reservations" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                />

                {showReservationsList && (
                    <List>
                        {filteredReservations.map((reservation) => (
                            <ListItem button key={reservation.id} onClick={() => onReservationClick(reservation)}>
                                <ListItemText primary={reservation.naam} secondary={`Periode: ${reservation.startDatum} tm ${reservation.eindDatum}`} />
                            </ListItem>
                        ))}
                    </List>
                )}

                <Button onClick={toggleReservationsList}>
                    {showReservationsList ? "Hide Reservations" : "Show Reservations"}
                </Button>

                {selectedReservation && (
                    <div>
                        <h3>Materials for Selected Reservation</h3>
                        {materialsFromPreviousReservation.length > 0 ? (
                            materialsFromPreviousReservation.map((material) => (
                                <MaterialItem
                                    key={material.id}
                                    material={material}
                                    handleListItemClick={() => handleMaterialSelection(material)}
                                    handleVerzameldChange={handleVerzameldChange}
                                    handleOpenStatusDialog={handleOpenStatusDialog}
                                    getStatusColor={getStatusColor}
                                />
                            ))
                        ) : (
                            <p>No materials found for this reservation.</p> // Display a message when no materials are found
                        )}
                    </div>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviousReservationsDialog;
