/**
 * Finds part in part structure.
 * @param {*} structure Object structure with all part info 
 * @param {*} part part to find
 * @param {*} pinStatus true or false
 * @returns structure with changed pin Status
 */
export default async function FindPart(structure,part, pinStatus){
    const keys = Object.keys(structure) 
    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        const newProject =  await ItterateKeyarr(structure[key],part,pinStatus)
        structure[key] = newProject
    }
    return structure
}

/**
 * Looks for part in project
 * @param {*} project 
 * @param {*} part 
 * @param {*} pinStatus 
 * @returns Returns project changed or unchanged
 */
async function ItterateKeyarr(project,part,pinStatus){
    for (let index = 0; index < project.length; index++) {
        let Projectpart = project[index];
        if(Projectpart.ID === part.ID){
            Projectpart.Pinned = pinStatus
            return project
        }
    }
    return project
}