import {React, useState, useEffect} from 'react';
import { groupBy } from 'lodash';
import { DialogContentText, Button, Dialog, DialogActions, DialogTitle, DialogContent, Checkbox, List, ListItem, ListItemText, Divider, Grid, Typography, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';

import MaterialDetails from './MaterialDetails'; // Pas het pad aan naar waar je MaterialDialog component is opgeslagen


import MaterialItem from './MaterialItem'; // Import the separated MaterialItem component

const CATEGORIES = [
    'Cameras', 'Drones', 'Lens', 'Monitors', 'Statief', 'Geluid', 'Grip', 'Licht',
    'Camera Accessoires', 'Special effects', 'Studio equipment', 'Vervoer en karren'
];

const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

const HEADERS = {
    Authorization: `Bearer ${API_KEY}`,
  'Content-Type': 'application/json'
};

//const AIRTABLE_MATERIAL_ENDPOINT = process.env.REACT_APP_AIRTABLE_MATERIAL_ENDPOINT;
const AIRTABLE_MATERIAL_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblu8xOJFShn7U4Lv'
const AIRTABLE_JUNCTION_ENDPOINT = 'https://api.airtable.com/v0/apphMT5j6gtUnMY1p/tblWxxnUVWgZsACXa'

const DEFAULT_STATUS_OPTIONS_WITH_COLORS = [
    { label: "Gepakt", color: '#4cce97' },
    { label: "Ingehuurd", color: '#e3b203' },
    { label: "Kaartjes nog pakken", color: '#faa53d' },
    { label: "Overnemen van andere draaidag", color: '#faa53d' },
    { label: "Accu's nog pakken / lader", color: '#faa53d' },
    { label: "Nog pakken", color: '#f87462' },
    { label: "Nog kaartje erbij doen", color: '#faa53d' },
    { label: "Cameraman zelf", color: '#faa53d' },
    { label: "Ligt in studio", color: '#faa53d' },
    { label: "Gereserveerd", color: '#faa53d' }
];

    const GereserveerdMateriaal = ({ reservedMaterials, setCurrentStepCategorie, currentStepCategorie, setOpenWizard, handleOpenScanner, updateJunctionStatus }) => {
    const [materials, setMaterials] = useState(reservedMaterials);
    const [openMaterialDialog, setOpenMaterialDialog] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);

    useEffect(() => {
        fetchStatusOptionsWithColors().then(data => {
            setStatusOptions(data);
        });
    }, []);

    useEffect(() => {
        setMaterials(reservedMaterials);
        const initialStatuses = reservedMaterials.flatMap(material => material.fields.JunctionStatus || []);
        setSelectedStatus(sortStatuses(initialStatuses));
    }, [reservedMaterials]);
    
    const groupedMaterials = groupBy(materials, (material) => material.fields.Categorie);

    const sortStatuses = (statuses) => {
        return statuses.sort((a, b) => {
            const indexA = DEFAULT_STATUS_OPTIONS_WITH_COLORS.findIndex(status => status.label === a);
            const indexB = DEFAULT_STATUS_OPTIONS_WITH_COLORS.findIndex(status => status.label === b);
            return indexA - indexB;
        });
    };    

    const fetchStatusOptionsWithColors = async () => {
    
        let statusOptions = [];
        statusOptions = DEFAULT_STATUS_OPTIONS_WITH_COLORS.map(option => option.label);
   
        const statusOptionsWithColors = statusOptions.map((option, index) => ({
            label: option,
            color: DEFAULT_STATUS_OPTIONS_WITH_COLORS[index]?.color || '#faa53d'  // Fallback to '#faa53d' if no color is found
        }));
    
        return statusOptionsWithColors;
    };


    const getStatusColor = (status) => {
        const matchedStatus = statusOptions.find(option => option.label === status);
        return matchedStatus ? matchedStatus.color : 'gray';
    };

    const toggleMaterialVerzameld = (materialId, value) => {
        setMaterials((prevMaterials) =>
            prevMaterials.map((material) =>
                material.id === materialId ? { ...material, fields: { ...material.fields, Verzameld: value } } : material
            )
        );
    };

    // Handlers for Dialogs
    const handleListItemClick = (material) => {
        console.log("List item clicked!"); 
        setSelectedMaterial(material);
        setOpenMaterialDialog(true);
    };

    const handleCloseMaterialsDetails = () => {
        setSelectedMaterial(null);  // Clear selected material
        setOpenMaterialDialog(false);  // Close the dialog
    };
    

    const handleCloseStatusDialog = async () => {

        setOpenStatusDialog(false);
    };

    const handleOpenStatusDialog = (material, e) => {
        // Prevent click event from bubbling up to the parent elements
        if (e) e.stopPropagation();
    
        // Set the selected material
        setSelectedMaterial(material);
    
        // Set the selected status as the current status of the material
        // Ensure that the status is an array since multiple selections are allowed
        const currentStatuses = material.fields.JunctionStatus || [];
        const sortedStatuses = sortStatuses(currentStatuses);
        setSelectedStatus(currentStatuses);
    
        // Open the status dialog
        setOpenStatusDialog(true);
    };

    // Separate out your Airtable fetch logic to make it more modular
    const updateAirtableField = async (recordId, isChecked) => {
        try {
            const materialsResponse = await fetch(`${AIRTABLE_MATERIAL_ENDPOINT}/${recordId}`, {
                method: 'PATCH',
                headers: HEADERS,
                body: JSON.stringify({
                    fields: {
                        Verzameld: isChecked,
                    },
                }),
            });

            if (materialsResponse.error) {
                throw new Error(materialsResponse.error.message);
            }

            return materialsResponse;
        } catch (error) {
            console.error('Error updating Airtable:', error);
        }
    };

    const handleVerzameldChange = async (materialSel) => {
        console.log("handleVerzameldChange: ", materialSel);
    
        // Check if "Gepakt" is already in the list of statuses
        const currentStatuses = materialSel.fields.JunctionStatus || [];
        const isGepaktIncluded = currentStatuses.includes("Gepakt");
        
        let updatedStatuses;
        if (!isGepaktIncluded) {
            updatedStatuses = sortStatuses([...currentStatuses, "Gepakt"]);
        } else {
            updatedStatuses = sortStatuses(currentStatuses);
        }
    
        const responseJunction = await updateJunctionStatus(materialSel.fields.JunctionID, updatedStatuses);
        if (responseJunction && responseJunction.ok) {
            // Update the local state with the new statuses
            setMaterials((prevMaterials) =>
                prevMaterials.map((material) =>
                    material.id === materialSel.id ? {
                        ...material,
                        fields: {
                            ...material.fields,
                            JunctionStatus: updatedStatuses
                        }
                    } : material
                )
            );
        } else {
            console.error('Failed to update status to "Gepakt".');
        }
    };
    

    const updateMaterialStatus = async (recordId, selectedStatus) => {
        try {
            const response = await fetch(`${AIRTABLE_MATERIAL_ENDPOINT}/${recordId}`, {
                method: 'PATCH',
                headers: HEADERS,
                body: JSON.stringify({
                    fields: {
                        "Status": selectedStatus
                    },
                }),
            });
    
            if (response.error) {
                throw new Error(response.error.message);
            }
    
            return response;
        } catch (error) {
            console.error('Error updating Airtable:', error);
        }
    };

    const handleOpenWizardToCategory = (category) => {
        const categoryIndex = CATEGORIES.indexOf(category);
        setOpenWizard(true); // function to set the state that triggers the MaterialWizard to open
        setCurrentStepCategorie(categoryIndex); // Set the index of the clicked category
    };    

    const handleStatusSelection = (status) => {
        // Toggle status in the selectedStatus array
        setSelectedStatus(prevSelectedStatuses => {
            let updatedStatuses;
            const isAlreadySelected = prevSelectedStatuses.includes(status);
            if (isAlreadySelected) {
                updatedStatuses = prevSelectedStatuses.filter(s => s !== status);
            } else {
                updatedStatuses = [...prevSelectedStatuses, status];
            }
            return sortStatuses(updatedStatuses);
        });
    
        // No need to call updateJunctionStatus or handleCloseStatusDialog here
        // as we only want to update the local UI state, not the backend.
        // The backend update should happen when the "Save" button is clicked.
    };

    const handleSaveStatuses = async () => {
        // Ensure there's a selectedMaterial before trying to save
        if (selectedMaterial) {
            try {
                // Call updateJunctionStatus to save the selectedStatus array to the backend/Airtable
                const sortedStatuses = sortStatuses(selectedStatus);
                const response = await updateJunctionStatus(selectedMaterial.fields.JunctionID, selectedStatus);
                if (response && response.ok) {
                    // If the backend update is successful, update the local state to reflect the changes
                    setMaterials(prevMaterials =>
                        prevMaterials.map(material =>
                            material.id === selectedMaterial.id
                                ? { ...material, fields: { ...material.fields, JunctionStatus: selectedStatus } }
                                : material
                        )
                    );
                    // Close the dialog after a successful save
                    handleCloseStatusDialog();
                } else {
                    // If the backend update fails, log an error or show an error message
                    console.error('Failed to save the selected statuses.');
                }
            } catch (error) {
                console.error('Error saving the selected statuses:', error);
            }
        }
    };
    
    

    return (
        <div style={{ marginTop: '10px' }}>

            {CATEGORIES.map((category) => {
                const materialsInCategory = groupedMaterials[category];
                if (!materialsInCategory || materialsInCategory.length === 0) return null;

                return (
                    <Paper key={category} elevation={3} style={paperStyle}>
                        <Typography variant="h6" gutterBottom style={typographyStyle}>
                            {category}
                            <IconButton 
                                edge="end"
                                onClick={() => handleOpenWizardToCategory(category)}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </Typography>

                        <Grid container spacing={3}>
                            {materialsInCategory.map((material) => (
                                <MaterialItem
                                    key={material.id}
                                    material={material}
                                    handleListItemClick={handleListItemClick}
                                    onVerzameldToggle={toggleMaterialVerzameld}
                                    onUpdateAirtableField={updateAirtableField}
                                    handleOpenStatusDialog={handleOpenStatusDialog}
                                    getStatusColor={getStatusColor}
                                    handleVerzameldChange={handleVerzameldChange}
                                    handleStatusSelection={handleStatusSelection}                                   
                                />
                            ))}
                        </Grid>
                    </Paper>
                );
            })}

            <Dialog open={openStatusDialog} onClose={handleCloseStatusDialog}>
                <DialogTitle>Verander status</DialogTitle>
                <DialogContent>
                    <DialogContentText>Verander de status voor:</DialogContentText>
                    {statusOptions.map(status => {
                        const isSelected = selectedStatus.includes(status.label);
                        return (
                            <ListItem 
                                button
                                key={status.label}
                                style={{ 
                                    backgroundColor: isSelected ? '#c8e6c9' : '#fff', // Highlight if selected
                                    color: isSelected ? 'green' : 'inherit'
                                }}
                                onClick={() => handleStatusSelection(status.label)}
                            >
                                {status.label}
                                {isSelected && <CheckIcon style={{ marginLeft: 'auto' }} />}  {/* Display check icon if selected */}
                            </ListItem>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseStatusDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSaveStatuses} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <MaterialDetails
                openMaterialDialog={openMaterialDialog} 
                handleCloseMaterialsDetails={handleCloseMaterialsDetails} 
                selectedMaterial={selectedMaterial} 
            />




        </div>
    );
};


const paperStyle = { padding: '15px', marginBottom: '20px' };
const typographyStyle = { textAlign: 'center', marginBottom: '5px', color: '#3f51b5' };



export default GereserveerdMateriaal;
