import React, {useDebugValue, useEffect} from "react";
import "../../../styles/UrenVerantwoording/App.css"
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "../Dialog/AlertDialog";
import TaskDetailDialog from "../Dialog/TaskDetailDialog";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';
import Chip from '@mui/material/Chip';
import ExtraInfo from "../MiscComp/ExtraInfo";
import { Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Airtabledata from '../../../utils/UrenVerantwoording/API/AirtableData.json'

/**
 * Displays all available information of a task and allows hours to be written on the task
 * @param {*} param0 
 * @returns 
 */
const TaskRow = ({onderdeel, week, UpdateHourLog, CreateHourLog ,irregular, handleInputRow, handleRemovePart,CreateHourLogWeek, createComment, mobileActive}) => 
{
    const [WeekandHour, setWeekandHour] = React.useState([0, 0, 0, 0, 0]);
    const [OldWeekandHour, setOldWeekandHour] = React.useState([0, 0, 0, 0, 0]);
    const [openRemoveDialog,setOpenRemoveDialog] = React.useState(false)
    const [comment, setComment] = React.useState('')
    const [openDetailDialog,setOpenDetailDialog] = React.useState(false)
    const [color,setColor] = React.useState(false)
    const [draaidag, setDraaidag] = React.useState([])
    const [hourInfo, setHourInfo] = React.useState(undefined)
    const [queue, setQueue] = React.useState([])
    const inputRef = React.useRef(null)
    const [loading ,setLoading] = React.useState(false)
    const[hoursLeft, setHoursLeft] = React.useState({value : '0/0', color : false})
    const [pinned,setPinned] = React.useState(false)
    const [sum ,setSum] = React.useState(0)

    const hourLogData = Airtabledata.Uren.children.UrenLog.children

    /**
     * Use effect to make sure the pinned status changes when the part changes 
     */
    useEffect(()=>{
        setPinned(onderdeel.Pinned)
    },[onderdeel.Pinned])

    /**
     * Use Effect to get the total sum of the part
     */
    useEffect(()=>{
        const totalsum = onderdeel.sumHours || 0;
        setSum(totalsum)
    },[onderdeel.sumHours])

    /**
     * Use Effect to get a value to display the sum hours and written hours
     */
    useEffect(()=>{
        const newHoursLeft = {value: `${Math.round(sum*100)/100}/${onderdeel.Aantal}` ,color:sum > onderdeel.Aantal? true : false }
        setHoursLeft(newHoursLeft)
    },[sum,onderdeel.Aantal])

    /**
     * Use Effect to make sure the hours gotten from the task are recieved
     */
    useEffect(()=>{
        if(onderdeel.hours !== undefined){
            setHourInfo(onderdeel.hours)
            setLoading(false)
        }
    },[onderdeel.hours])

    /**
     * Use effect That makes sure that the queu gets cleared when it's not busy loading
     */
    useEffect(()=>{ 
        if(loading === false){
            if(queue.length === 0) return
            for (let index = 0; index < queue.length; index++) {
                const element = queue[index];  
                UpdateHourLog(hourInfo.id,element.Day,element.value)
            }
        }
    },[loading])

    /**
     * Use effect that changes which input gets shown on mobile
     */
    useEffect(()=>{
        if(inputRef.current !== null){
            const currentActive = inputRef.current.querySelector(`.active-mobile`)
            if(currentActive !== null)       currentActive.className = currentActive.className.replace(/active-mobile/g,'')
            const nextActive = inputRef.current.querySelector(`.selector-${mobileActive}`)
            nextActive.className = nextActive.className + ' active-mobile'  
          }
    },[mobileActive,inputRef.current])
    /**
     * Use effect that gives color to the little circle before a task
     */
    useEffect(()=>{
        if(onderdeel.task === undefined) return
        switch(onderdeel.task.type){
            case "Draaidag" : 
                setColor("yellow")
                break
            case "Montage":
                setColor("purple")
                break
            case "Animatie":
                setColor("blue")
                break
            default:  
                setColor("")
                break
        }
    },[onderdeel,week])
    /**
     * If a comment is included set it in a use State to use later
     */
    useEffect(()=>{
        if(onderdeel.hours === undefined) return
        if(onderdeel.hours.fields === undefined) return

        const commentField = onderdeel.hours.fields[hourLogData.comment];
        if(commentField !== undefined) setComment( commentField)
    },[onderdeel,week])

    /**
     * Sets the range of days which are a Draaidag 
     */
    useEffect(() => {
        if(onderdeel.task === undefined) return
        if(onderdeel.task.type !== "Draaidag") return
        const indicesInRange = week.weekRowDate.reduce((acc, date, index) => {
            if (date >= onderdeel.task.startDate && date <= onderdeel.task.endDate) {
                acc.push(index);
            }
            return acc;
        }, []);
    
        if (indicesInRange.length === 0) {
            return;
        }

        setDraaidag(indicesInRange)
        },[onderdeel,week])

    /**
     * Activates the change of hours when the week changes
     */
    useEffect(() => {
        setWeek();
        }, [week]);

    /**
     * Recieves the new hour value and send it further
     * @param {object} event of the input
     */
    const handleChange = (event) => {
        const dataIndex = event.target.getAttribute("data-key");
        let newValue = event.target.value;
        const oldValue = WeekandHour[dataIndex]
        const delta = Number(oldValue) - Number(newValue)
        // Check if the input ends with a dot and doesn't have other characters after it
        if (/^\d+\.$/.test(newValue)) {
            // If so, remove the trailing dot
            newValue = newValue.slice(0, -1);
        }


        setSum((sum)=> {return (delta - sum) * -1})
        handleInputRow(dataIndex, delta)
        
        setWeekandHour((prevWeekandHour) => {
            const updatedWeekandHour = [...prevWeekandHour];
            updatedWeekandHour[dataIndex] = newValue;
            return updatedWeekandHour;
          });
        handleBlur(event)
        };

    
    /**
     * Changes the useState of the OpenremoveDialog
     */
    function HandleCloseRemoveDialog(){
        setOpenRemoveDialog(false)
    }

    /**
     * Closes the DetailDialog but first handle de data thast been given.
     * @param {Date} date if true create hour log week
     * @param {string} newComment if its different from the orriginal comment; save it.
     */
    function HandleCloseDetailDialog(date, newComment){ 
        if(!date === false){
            CreateHourLogWeek(date,week.weekRowDate[0],onderdeel.ID, WeekandHour)
        }
        if(newComment !== comment){
            setComment(newComment)
            handleAddComment(newComment)
        } 

        setOpenDetailDialog(false)
    }

    /**
     * When clicked on the input; select the value
     * @param {object} event of the input
     * @returns 
     */
    const handleFocus = (event) => event.target.select();

    /**
     * Change the hour in the input and sends it further to save it in AT
     * @param {object} event of the input
     */
    const handleBlur = async (event)=>{
        let UpdateOldWeekandHour = [...OldWeekandHour]
        const value = event.target.value
        const index = event.target.getAttribute("data-key")
        const Day  = week.weekRowtextFull[index]

        UpdateOldWeekandHour[index] = value
        // If we are loading set the change of hour in the queue, else change it
        if(loading !== true){
            //Create or update the hour
            if(hourInfo !== undefined ){
                const HourLogId = hourInfo.id
                await UpdateHourLog(HourLogId,Day,value,onderdeel.Name,onderdeel.ID)
                setOldWeekandHour(UpdateOldWeekandHour)
            }else{
                setLoading(true)
                await CreateHourLog(Day,value,week.weekRowDate[0],onderdeel.ID, onderdeel.Name).then((data)=>{
                    setHourInfo({id:data})
                    setOldWeekandHour(UpdateOldWeekandHour)
                    setLoading(false)
                })
            }
        }else{
            setQueue((queue)=>{
                const ele = {Day:Day , value:value}
                const arr = [...queue]
                arr.push(ele)
                return arr
            })
        }

    }

    /**
     * Depending on the status of the pin. Open the remove dialog or send it further
     */
    function changePinned(){
        if(onderdeel.task){
            setOpenRemoveDialog(true)
        } else{
            handleRemoveOrAdd()
        }
    }

    /**
     * sets the hours on the correct day, if a day does not have set hours. Give it zero
     */
  function setWeek() {
    let updatedWeekandHour =[]
    if(onderdeel.hours !== undefined ){
        updatedWeekandHour = [
            onderdeel.hours.fields.Maandag ? onderdeel.hours.fields.Maandag:0 , 
            onderdeel.hours.fields.Dinsdag ? onderdeel.hours.fields.Dinsdag:0 , 
            onderdeel.hours.fields.Woensdag ? onderdeel.hours.fields.Woensdag:0 ,  
            onderdeel.hours.fields.Donderdag ? onderdeel.hours.fields.Donderdag:0 , 
            onderdeel.hours.fields.Vrijdag ? onderdeel.hours.fields.Vrijdag:0  ];
    }else{
        updatedWeekandHour = [0,0,0,0,0]
    }
    setWeekandHour(updatedWeekandHour);
    setOldWeekandHour(updatedWeekandHour)
  }

  /**
   * Change the status of the pin and send it further
   */
  function handleRemoveOrAdd(){
    handleRemovePart(onderdeel)
    onderdeel.Pinned = !onderdeel.Pinned
    setPinned(!pinned)
  }

  /**
   * Create or update the task comment depending on if it already exist or not
   * @param {string} content Comment message
   */
  function handleAddComment(content){
    if(onderdeel.hours != null){
        const HourLogId = onderdeel.hours.id
        createComment(HourLogId, content, null)
    }else{
        createComment(null,content, onderdeel.ID)
    }
  }

  const formattedDate = (data) => {
    return data.map((item, index) => (
        <div key={index}>{item}</div>
    ));
};
    
    return (
        <>
        <div className={`rowproject ${irregular? "irregular" : ""} ${onderdeel.Group !=="Empty"? "big" : ""}`} ref={inputRef}>
            <div className="subtitleblock">
                <div className="task-row-name">{onderdeel.Name} {onderdeel.KorteOmschrijving === "Empty"? "" : onderdeel.KorteOmschrijving}<div className="remove-mobile extra-info"> {onderdeel.LangeOmschrijving !== "Empty" && <ExtraInfo Info={onderdeel.LangeOmschrijving}/>}</div></div> 
                <div className="subsubblock">   
                {onderdeel.task && 
                        (<div className={`taskindicator ${color}`}/>)}

                    {onderdeel.task && onderdeel.task?.extraTask?.length === 0 && 
                        ( `${onderdeel.task.task}`)
                    }

                    {onderdeel.task?.extraTask?.length > 0 &&
                        (<Tooltip title={formattedDate(onderdeel.task.extraTask)} >{onderdeel.task.task}</Tooltip>)
                    }

                        
                        {onderdeel.Group!== "Empty" && 
                            <div className="remove-mobile"> 
                             <Chip sx={{minWidth:"100px", maxHeight:"20px"}}label={`${onderdeel.Group}`} />
                            </div>
                        }
                        
                </div>
                </ div>
                <div></div>
            {[...WeekandHour].map((hours, index) =>
                <>
                    <div className={`selector-${index} sum-input`}  key={`${onderdeel.name} + ${index}`} >
                    <input
                        id={index}
                        onFocus={handleFocus}
                        data-key={index}
                        autoComplete="off"
                        type="number"
                        step="0.25"
                        className={`hourinput ${draaidag.includes(index) ? "yellow" : ""} ${draaidag}`}
                        maxLength="4"
                        value={WeekandHour[index]}
                        onChange={handleChange}
                        disabled={onderdeel.Name === "Afwezigheid - Vakantie / vrij"? true:false}
                        pattern="[0-9]*[.,]?[0-9]*"  // Allow both dot and comma as decimal separators
                    />
                    </div>

                </>
            )}
            {onderdeel.Unit === "uur"&& !onderdeel.projectName.includes("Full Frame - Algemene interne uren") &&// ugly bandaid
            <div className={`hours-left ${hoursLeft.color? 'red' : 'green'}`} >
                {hoursLeft.value}
            </div>}

            {onderdeel.Unit !== "uur"&&
            <div  ></div>}

            {onderdeel.projectName?.includes("Full Frame - Algemene interne uren") && onderdeel.Unit !== "uur"&&
                <></>
            }

            {onderdeel.projectName?.includes("Full Frame - Algemene interne uren") && onderdeel.Unit === "uur"&&
                <div></div>
            }

            <div style={{display:"flex"}}>
                <div className={`taskicon pin ${pinned? 'active': ''}`} onClick={changePinned}>
                    <PushPinIcon className="Registration"/>
                </div>
                <div className="more">
                    <Button onClick={()=> setOpenDetailDialog(true)}>
                        Meer...
                    </Button>
                </div>
            </div>

        </div>
        <AlertDialog open={openRemoveDialog} onClose={HandleCloseRemoveDialog} handleRemove={handleRemoveOrAdd} task={onderdeel.task}/>
        <TaskDetailDialog open={openDetailDialog} onClose={HandleCloseDetailDialog} weekAndHours={WeekandHour} draaidag={draaidag} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus} week={week} createComment={handleAddComment} comment={comment}/>
        </>
    )
}

export default TaskRow;
